import React from "react";


const ServicePlans = () => {
     return(
        <div>
            <div className="flex justify-center">
                <div className="mt-10 md:mx-80 shadow-xl bg-transparent md:p-5 p-2">
                    <h1 className="text-3xl font-bold ">Are you a school admin or department of education ?</h1>
                    {/* Empower your School Administration */}
                    <div className="mt-5">
                        <h1 className="text-sky-500 text-3xl">Empower your School Administration with:</h1>
                        <div className="ms-10">
                            <p className="md:text-xl"><b>●	Revitalized Learning Environment:</b> Gamizign injects excitement and engagement into the classroom, 
                            transforming schools from stagnant institutions into dynamic learning hubs.</p>
                            <p className="md:text-xl mt-3"><b>●	Improved Student Outcomes:</b> Modernized learning experiences lead to
                             improved student performance, higher graduation rates, and better preparation for future success.</p>
                            <p className="md:text-xl mt-3"><b>●	Attracting & Retaining Top Talent:</b> A forward-thinking learning environment attracts and
                             retains high-quality educators who are passionate about innovative teaching methods.</p>
                            <p className="md:text-xl mt-3"><b>●	Reduced Teacher Burnout:</b> Gamizign contributes to a more positive and
                             sustainable work environment for teachers, reducing burnout and improving retention rates.</p>
                            <p className="md:text-xl mt-3"><b>●	Enhanced Teacher Effectiveness:</b> Streamlined workflows and data-driven 
                            insights empower teachers to become more effective educators, leading to improved student outcomes.</p>
                        </div>
                    </div>
                    {/* Empower your teachers  */}
                    <div className="mt-5">
                        <h1 className="text-sky-500 text-3xl">Empower your teachers with:</h1>
                        <div className="ms-10">
                            <p className="md:text-xl"><b>●	Reduced Transition Time:</b> Dedicated onboarding support and intuitive 
                            interface minimize the time required to learn and adapt to the platform.</p>
                            <p className="md:text-xl mt-3"><b>●	Increased Student Participation:</b> Gamified elements and interactive activities encourage active participation from students,
                             leading to a more dynamic and engaging classroom.</p>
                            <p className="md:text-xl mt-3"><b>●	Improved Motivation & Effort:</b>A sense of fun and healthy competition fosters increased student motivation and effort,
                             leading to deeper learning and improved academic performance.</p>
                            <p className="md:text-xl mt-3"><b>Performance Tracking:</b>Gamizign provides instructors with data on student progress and engagement.
                             This allows for timely interventions and adjustments to teaching strategies.</p>
                        </div>
                    </div>
                    <h1 className="mt-5 font-bold text-xl">Unleash the Power of Play! Gamizign Supercharges Learning with AI-Powered Gamification</h1>
                    <p>(<span className="text-sky-500">Buy Now & Transform Your Institution Today</span>!)</p>
                </div>
            </div>
        </div>
     )
};
export default ServicePlans;