import React, { useEffect, useRef, useState } from "react";
import * as d3 from 'd3';
import Learneres from '../../../../../../assets/icons/ActivityContentIcons/The-Gamizign-World/Users icon off.png'
import getAvtar from "./GetAvtar";

const QuadrantChart = ({ students, analyticsData, setStudentId }) => {
    const chartRef = useRef();

    useEffect(() => {
        const svg = d3.select(chartRef.current);
        const width = 600;
        const height = 600;
        const margin = 50;
        const innerWidth = width - 2 * margin;
        const innerHeight = height - 2 * margin;

        svg.selectAll('*').remove(); // Clear the SVG

        const chartGroup = svg.append('g')
            .attr('transform', `translate(${margin}, ${margin})`);

        const xScale = d3.scaleLinear().domain([0, 1]).range([0, innerWidth]);
        const yScale = d3.scaleLinear().domain([0, 1]).range([innerHeight, 0]);

        // Draw the quadrants with labels
        const quadrants = [
            { x: xScale(0.25), y: yScale(1.05), label: 'under-Engagement Risk', align: 'middle' },
            { x: xScale(0.75), y: yScale(1.05), label: 'On-Track / Not At Risk', align: 'middle' },
            { x: xScale(0.25), y: yScale(-0.05), label: 'Withdraw Risk', align: 'middle' },
            { x: xScale(0.75), y: yScale(-0.05), label: 'Academic Performance Risk', align: 'middle' },
        ];

        quadrants.forEach(({ x, y, label, align }) => {
            chartGroup.append('text')
                .attr('x', x)
                .attr('y', y)
                .attr('text-anchor', align)
                .text(label);
        });

        // Draw quadrant borders
        svg.append('g')
            .attr('transform', `translate(${margin}, ${margin})`)
            .selectAll('line')
            .data([0.5])
            .enter()
            .append('line')
            .attr('x1', d => xScale(d))
            .attr('x2', d => xScale(d))
            .attr('y1', 0)
            .attr('y2', innerHeight)
            .attr('stroke', 'black')
            .style('stroke-dasharray', '5,5'); // Dotted border

        svg.append('g')
            .attr('transform', `translate(${margin}, ${margin})`)
            .selectAll('line')
            .data([0.5])
            .enter()
            .append('line')
            .attr('x1', 0)
            .attr('x2', innerWidth)
            .attr('y1', d => yScale(d))
            .attr('y2', d => yScale(d))
            .attr('stroke', 'black')
            .style('stroke-dasharray', '5,5'); // Dotted border

        // Create a tooltip
        const tooltip = chartGroup.append('text')
            .style('opacity', 0)
            .style('font-size', '12px')
            .style('font-weight', 'bold')
            .style('border', '1px solid black')
            .style('border-radius', '5px')
            .style('background-color', 'white')
            .style('z-index', '10')
            .attr('fill', 'black');

        // Draw the students
        students.forEach((student, studentIndex) => {
            const analytics = analyticsData[studentIndex];
            const accuracy = analytics?.correctAnswers > 0 ? ((analytics.correctAnswers / (analytics.correctAnswers + analytics.incorrectAnswers)) * 100).toFixed(2) : 0;
            const engagement = analytics?.gamesWon > 0 ? ((analytics.gamesWon / analytics.sessions) * 100).toFixed(2) : 0;
            const gameWon = analytics?.gamesWon > 0 ? (analytics.gamesWon) : 0;

            const accuracyValue = (accuracy / 100);
            const engagementValue = (engagement / 100);

            const sumValues = Number(accuracy) + Number(engagement);
            let color = '#f9cccc';
            if (accuracy >= 50 && engagement >= 50) color = '#E5F2E3';
            else if (sumValues >= 50) color = '#DBE6F5';
            const avtar = getAvtar(student.avatar_id)

            chartGroup.append('circle')
                .attr('cx', xScale(accuracyValue))
                .attr('cy', yScale(engagementValue))
                .attr('r', 15) // Increase the radius of circles
                .attr('fill', color)
                .attr('cursor', 'pointer')
                .on('click', () => setStudentId(student.student_id))
                .on('mouseenter', function () {
                    tooltip.transition().duration(200)
                        .style('opacity', 1)
                    // .style('fill', color);
                    tooltip.text(`${student.firstName} Accuracy: ${accuracy}%  Games Won:${gameWon} `)
                        .attr('x', xScale(accuracyValue) + (accuracy >= 30 ? -160 : 0)) // Adjust tooltip position
                        .attr('y', yScale(engagementValue) - 28);
                    tooltip.raise();
                })
                .on('mouseleave', function () {
                    tooltip.transition().duration(500)
                        .style('opacity', 0);
                });

            // Add image on top of the circle
            chartGroup.append('image')
                .attr('x', xScale(accuracyValue) - 12) // Adjust to center the image on the circle
                .attr('y', yScale(engagementValue) - 12) // Adjust to place the image above the circle
                .attr('width', 25)
                .attr('height', 25)
                .attr('href', avtar) // Replace with your image path or URL
                .attr('cursor', 'pointer')
                .on('mouseover', function () {
                    d3.select(this).transition().duration(200)
                        .attr('width', 35)  // Increase width on hover
                        .attr('height', 35) // Increase height on hover
                        .attr('x', xScale(accuracyValue) - 17) // Adjust to center the image on the circle
                        .attr('y', yScale(engagementValue) - 17); // Adjust to place the image above the circle
                })
                .on('mouseout', function () {
                    d3.select(this).transition().duration(200)
                        .attr('width', 25)   // Revert back to original width
                        .attr('height', 25)  // Revert back to original height
                        .attr('x', xScale(accuracyValue) - 12) // Adjust to center the image on the circle
                        .attr('y', yScale(engagementValue) - 12); // Adjust to place the image above the circle
                })
                .on('click', () => setStudentId(student.student_id));


            const randomOffsetX = (Math.random() - 0.5) * 30;
            const randomOffsetY = (Math.random() - 0.5) * 30;

            chartGroup.append('text')
                .attr('x', xScale(accuracyValue) + 18)
                .attr('y', yScale(engagementValue) + 4 + randomOffsetY)
                .text(student.firstName)
                .attr('font-size', '12px')
                .attr('fill', 'black')
                .attr('stroke', 'none')
                .attr('cursor', 'pointer')
                .on('click', () => setStudentId(student.student_id))
                .on('mouseenter', function () {
                    tooltip.transition().duration(200)
                        .style('opacity', 1);
                    tooltip.text(`${student.firstName} Accuracy: ${accuracy}%  Games Won:${gameWon} `)
                        .attr('x', xScale(accuracyValue) + (accuracy >= 30 ? -160 : 0)) // Adjust tooltip position
                        .attr('y', yScale(engagementValue) - 28);
                })
                .on('mouseleave', function () {
                    tooltip.transition().duration(500)
                        .style('opacity', 0);
                });
        });

    }, [students, analyticsData]);

    return (
        <div className="overflow-x-auto max-w-80 sm:max-w-full">
            <svg ref={chartRef} width="600" height="600"></svg>
        </div>
    );
};
export default QuadrantChart;