import React, { useEffect, useState } from "react";
import getAvtar from "./GetAvtar";


const AnalyticsTable = ({mode, currentStudents, studentId, sortOrder, sortMergedArray }) => {

  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${String(hours).padStart(2, '0')}h:${String(minutes).padStart(2, '0')}m:${String(
      Math.floor(remainingSeconds)
    ).padStart(2, '0')}s`;
  }


  return (
    <div className="relative mt-5 px-3">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className={`uppercase dark:bg-gray-700 dark:text-gray-400 ${mode === 'light' ? 'text-gray-700' :''}`}>
          <tr>
            <th onClick={() => sortMergedArray('firstName')} scope="col" className={`px-6 py-3 cursor-pointer ${sortOrder.field === 'firstName' ? 'bg-red-200' : 'hover:bg-sky-200'}`}>Name</th>
            <th scope="col" className="py-3 ps-3">Avatar</th>
            <th scope="col" className="px-6 py-3">E-mail Address</th>
            <th onClick={() => sortMergedArray('score')} scope="col" className={`px-6 py-3 cursor-pointer ${sortOrder.field === 'score' ? 'bg-red-200' : 'hover:bg-sky-200'}`}>Score</th>
            <th onClick={() => sortMergedArray('accuracy')} scope="col" className={`px-6 py-3 cursor-pointer ${sortOrder.field === 'accuracy' ? 'bg-red-200' : 'hover:bg-sky-200'}`}>Accuracy</th>
            <th onClick={() => sortMergedArray('sessions')} scope="col" className={`px-6 py-3 cursor-pointer ${sortOrder.field === 'sessions' ? 'bg-red-200' : 'hover:bg-sky-200'}`}>Sessions</th>
            <th onClick={() => sortMergedArray('Avg.time')} scope="col" className={`px-6 py-3 cursor-pointer ${sortOrder.field === 'Avg.time' ? 'bg-red-200' : 'hover:bg-sky-200'}`}>Average Time </th>
          </tr>
        </thead>
        <tbody>
          {currentStudents && currentStudents.length > 0 ? (
            currentStudents.map((student, studentIndex) => {
              return (
                <tr key={studentIndex} className={` ${studentId === student.student_id ? 'bg-red-200' : mode === 'light' ? 'even:bg-white odd:bg-gray-50' :'even:gray-900 odd:gray-800'} odd:dark:bg-gray-800 border-b dark:border-gray-700`}>
                  <td className={`px-6 py-4 font-medium  whitespace-nowrap dark:text-white ${mode === 'light' ? 'text-gray-900' :''}`}>
                    {student.firstName} {student.lastName}
                  </td>
                  <td className="py-2"><img className="h-16" src={getAvtar(student.avatar_id)} alt="avatar" /></td>
                  <td className="px-6 py-4">{student.email}</td>
                  <td className="px-6 py-4">
                    {student ? (
                      student.score.toFixed(0)
                    ) : (0)}
                  </td>
                  <td className="px-6 py-4">
                    {student?.correctAnswers > 0 ? (
                      <div>
                        {(
                          (student.correctAnswers /
                            (student.correctAnswers + student.incorrectAnswers)) * 100
                        ).toFixed(2)}%
                      </div>
                    ) : (<>0.00%</>)}
                  </td>
                  <td className="px-6 py-4">
                    {student ? (
                      student.sessions
                    ) : (0)}
                  </td>
                  <td className="px-6 py-4">
                    {student?.totalTime > 0 ?

                      formatTime(
                        student.totalTime /
                        student.sessions
                      )

                      : (<>00h:00m:00s</>)}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="6" className="px-6 py-4 text-center">No students in this course with "<b></b>"</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
export default AnalyticsTable;