import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import QListDashboardIcon from '../../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/Question Lists dashboard icon.png'
import AddNewActivity from '../../../../../../assets/icons//ActivityContentIcons/QuestionListsPatch5/Add New Manual Question icon.png'
import BackBtn from '../../../../../../assets/icons//ActivityContentIcons/QuestionListsPatch5/back iconB.png'
import SaveBtn from '../../../../../../assets/icons//ActivityContentIcons/QuestionListsPatch5/save icon.png'
import Image from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/image icon _ multi activity.png'
import Text from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/assay icon _ multi activity.png'
import RankOrProccess from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/rank or process icon _ multi activity.png'
import MCQ from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/MSQ.png'

import LoginHeading from "../../../../../LoginHeading/LoginHeading";
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import * as mke from 'mathkeyboardengine';
import QuestionComponent from "../ReuseableQuestionCard";
import { Alert, Button, Dialog, DialogContent, FormControl, InputLabel, MenuItem, Select, Skeleton, TextField, Tooltip } from "@mui/material";
import EditQuestionListSkeleton from "./EditQuestionListSkeleton";
import MetaTags from "../../../../../GlobalComponents/MetaData";
import Joyride from "react-joyride";
import axiosInstance from "../../../../../../axios";




const QuestionListEdit = () => {
    const [selectedQuestions, setSelectedQuestions] = useState(null);
    const [AddNewQuestion, setAddNewQuestion] = useState(false);
    const [emptyValues, setEmptyValues] = useState(false);
    const LicenceType = parseInt(localStorage.getItem("plan"));
    

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const questionList_id = queryParams.get('questionList_id') !== "" ? queryParams.get('questionList_id') : "";
    const [progress, setIsProgress] = useState(false);
    const [modalMessage, setModalMessage] = useState('The last question does not have all answers filled!');

    const navigate = useNavigate();
    const k = new mke.KeyboardMemory();


    const {Qlist, courses, courseType, course, stageIndex, courseFormatDate, stratingDate, finishingDate } = useLocation().state || "";
    const [SelecteQuestionType, SetSelecteQuestionType] = useState(Qlist === 1 ?  true : false);

    // validation for add , coopy or save question
    const validation = (lastQuestion) => {
        if (lastQuestion.questionType === 'essay'
            ? (lastQuestion.question !== '' || lastQuestion.questionImage) && (lastQuestion.answer1 !== '' || lastQuestion.answer1Image)
            : (lastQuestion.question !== '' || lastQuestion.questionImage) &&
            (lastQuestion.answer1 !== '' || lastQuestion.answer1Image) &&
            (lastQuestion.answer2 !== '' || lastQuestion.answer2Image) &&
            (lastQuestion.answer3 !== '' || lastQuestion.answer3Image) &&
            (lastQuestion.answer4 !== '' || lastQuestion.answer4Image)) {
            return true;
        } else { return false };

    }

    const [updatedQuestions, setUpdatedQuestions] = useState({ name: "Manual questions" });
    const [IsquestionType, setIsquestionType] = useState('');
    const initialNewQuestion =
        IsquestionType === 'essay' ? {
            question: "",
            answer1: "",
            answer2: "",
            answer3: "",
            answer4: "",
            answer_exact: false,
            answer1Image: null,
            answer2Image: null,
            answer3Image: null,
            answer4Image: null,
            questionImage: null,
            rank: false,
            bloom_level: "Remembering",
            slide_no: "manual",
            difficulty: "easy",
            questionType: ""
        } : {
            question: "",
            answer1: "",
            answer2: "",
            answer3: "",
            answer4: "",
            answer_exact: false,
            answer1Image: null,
            answer2Image: null,
            answer3Image: null,
            answer4Image: null,
            questionImage: null,
            rank: IsquestionType === 'reorder' ? true : false,
            bloom_level: "Remembering",
            slide_no: "manual",
            difficulty: "easy",
            questionType: ""
        };
    const [newQuestion, setNewQuestion] = useState(
        {
            question: "",
            answer1: "",
            answer2: "",
            answer3: "",
            answer4: "",
            answer_exact: false,
            answer1Image: null,
            answer2Image: null,
            answer3Image: null,
            answer4Image: null,
            questionImage: null,
            rank: false,
            bloom_level: "Remembering",
            slide_no: "manual",
            difficulty: "easy",
            questionType: 'mcqs',
            questionList_id: questionList_id // You need to implement this function
        });
    const handleAddNew = (questionType) => {
       let error = false;
        if (questionType === 'image' || questionType === 'essay' || questionType === 'reorder') {
            if (LicenceType === 0 || LicenceType === 1) {
                error = true;
                setModalMessage(`Your current plan doesn't have the ability to add ${questionType} type upgrade to add ${questionType} type question.`)
                setCoursebotmodalIsOpen(true);
                setIsquestionType('');
            }
        }
        const updatedQuestionsCopy = { ...updatedQuestions };
        setNewQuestion(initialNewQuestion);
        newQuestion.questionType = questionType;
        if (questionType === 'reorder') {
            newQuestion.rank = true;
        } else {
            newQuestion.rank = false;
        }
        const questions = updatedQuestionsCopy.questions;
        if (!error) {
            if (questions.length > 0) {
                const lastQuestion = questions[questions.length - 1];
                // Ensure lastQuestion has all answer properties that are non-empty strings
                if (validation(lastQuestion)) {
                    // All answers are non-empty strings, push the new question
                    updatedQuestionsCopy.questions.push(newQuestion);
                } else {
                    setModalMessage("Kindly fill all the fields of the question or select image for all fields")
                    setCoursebotmodalIsOpen(true);
                    setIsquestionType('');
                }
                setUpdatedQuestions(updatedQuestionsCopy);
            } else {
                updatedQuestions.name = "My Manual Questionlist";
                updatedQuestionsCopy.questions.push(newQuestion);
                setIsquestionType('');
            }
        }
        SetSelecteQuestionType(null)
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (questionList_id !== null) {
                    const response = await axiosInstance.get(`${process.env.REACT_APP_ENDPOINT}/question-lists/${questionList_id}`);
                    setUpdatedQuestions(response.data);
                }
                else {
                    setUpdatedQuestions({ name: "My Manual Questionlist", questions: [], dateCreated: "" });
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const handleDelete = (questionIndex) => {
        // Create a shallow copy of the updatedQuestions object
        const updatedQuestionsCopy = { ...updatedQuestions };

        // Ensure that the questions array exists and is an array
        if (!updatedQuestionsCopy.questions || !Array.isArray(updatedQuestionsCopy.questions)) {
            console.error("questions array is not properly defined.");
            return;
        }

        // Remove the question at the specified index from the copied array
        updatedQuestionsCopy.questions.splice(questionIndex, 1);

        // Update the state with the edited object
        setUpdatedQuestions(updatedQuestionsCopy);
        setAddNewQuestion(false);
    };



    const checkEmptyValues = (obj) => {
        // Iterate over the keys of the object
        const allowedKeys = ['answer1Image', 'answer2Image', 'answer3Image', 'answer4Image', 'questionImage', 'questionList_id', 'rank'];
        for (const key in obj) {
            // Check if the value of the key is empty
            if (!allowedKeys.includes(key) && !obj[key]) {
                setEmptyValues(true);
                return false; // If empty value found, return false
            }
        }
        setEmptyValues(false);
        return true; // If no empty values found, return true
    }
    const copyQuestion = (questionIndex) => {
        const updatedQuestionsCopy = { ...updatedQuestions };
        const selectedQuestionCopy = { ...updatedQuestionsCopy.questions[questionIndex] };

        if (validation(selectedQuestionCopy)) {
            // Remove the question_id parameter from the copied question object
            delete selectedQuestionCopy.question_id;

            updatedQuestionsCopy.questions.push(selectedQuestionCopy);

            setUpdatedQuestions(updatedQuestionsCopy);
        } else {
            setModalMessage("Couldn't copy empty question");
            setCoursebotmodalIsOpen(true);
        }
    }
    var instructor_id = parseInt(localStorage.getItem("instructor_id"));
    const today = new Date();
    const dd = today.getDate().toString().padStart(2, '0');
    const mm = (today.getMonth() + 1).toString().padStart(2, '0');
    const yyyy = today.getFullYear().toString();
    const hh = today.getHours().toString().padStart(2, '0');
    const min = today.getMinutes().toString().padStart(2, '0');
    const ss = today.getSeconds().toString().padStart(2, '0');
    const formattedToday = dd + '/' + mm + '/' + yyyy + "  " + hh + ":" + min + ":" + ss;

    // Handler Update data on server
    const handleSave = async (questionListIndex) => {
        setCoursebotmodalIsOpen(false);
        var selQuest = updatedQuestions;
        selQuest.lastModified = formattedToday;
        selQuest.mainTopic = updatedQuestions.mainTopic;
        try {
            if (selQuest.questions.length >= 1) {
                if (questionList_id !== null) {
                    // Check if there are new questions
                    const isNewQuestionsAdded = selQuest.questions.some(question => !question.question_id);
                    // check empty question field          
                    const selectedQuestionCopy = { ...selQuest.questions[selQuest.questions.length - 1] };
                    if (validation(selectedQuestionCopy)) {
                        setIsProgress(true);
                        if (isNewQuestionsAdded) {
                            // Create new questions one by one
                            for (let i = 0; i < selQuest.questions.length; i++) {
                                if (!selQuest.questions[i].question_id) {
                                    const createQuestionResponse = await axiosInstance.post(`${process.env.REACT_APP_ENDPOINT}/instructors/${instructor_id}/questionlist/${selQuest.questionList_id}/questions`, JSON.stringify(selQuest.questions[i]));
    
                                    if (createQuestionResponse.status === 200) {
                                        const createdQuestion = await createQuestionResponse.data;
                                        selQuest.questions[i] = createdQuestion; // Update the original question with the created one
                                    } else {
                                        console.error("Failed to create a new question");
                                        setIsProgress(false);
                                        setModalMessage("An error occurred during saving question list. Please try again.");
                                        setCoursebotmodalIsOpen(true);
                                    }
                                }
                            }
                        }
    
                        // Continue with your existing PUT request code
                        const putOpt = {
                            method: "PUT",
                            redirect: "follow",
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(selQuest),
                        };
    
                        try {
                            const response = await axiosInstance.put(`${process.env.REACT_APP_ENDPOINT}/instructors/${instructor_id}/questionlist/${selQuest.questionList_id}`, selQuest);
                            if (response.status === 204) {
                                setIsProgress(false);
                                // navigate(`/Dashboard/questionList?questionList_id=${questionList_id}`);
                                window.location.href = `/Dashboard/EditQuestionsList?questionList_id=${questionList_id}`;
                            } else {
                                setIsProgress(false);
                                setCoursebotmodalIsOpen(true);
                                setModalMessage("An error occurred during saving question list. Please try again.");
    
                            }
                        } catch (err) {
                            console.log(err);
                        }
                    } else {
                        setModalMessage("Please fill question before saving");
                        setCoursebotmodalIsOpen(true);
                    }
                } else {
                    const selectedQuestionCopy = { ...selQuest.questions[selQuest.questions.length - 1] };
                    if (validation(selectedQuestionCopy)) {
                        setIsProgress(true);
                        var inst_name = localStorage.getItem("name");
                        var req = {
                            "name": updatedQuestions.name,
                            "dateCreated": formattedToday.split(' ')[0],
                            "createdBy": inst_name,
                            "lastModified": formattedToday.split(' ')[0],
                            "mainTopic": course.mainTopic,
                            "questions": updatedQuestions.questions,
                            "instructor_id": instructor_id, 
                        }                        
                        var activity = {
                            "name": "Activity" + course.stages[0].activities.length + 1,
                            "activityType": "game",
                            "scoreVolume": 50,
                            "instructor_id": instructor_id,
                            "actionType": 0,
                            "dateCreated": formattedToday.split(' ')[0],
                            "createdBy": inst_name,
                        }
    
                        const response = await axiosInstance.post(`${process.env.REACT_APP_ENDPOINT}/instructors/${instructor_id}/questions/`, req);
                        if (response.data) {
                            const getQlists = await axiosInstance.get(`${process.env.REACT_APP_ENDPOINT}/instructors/${instructor_id}/question-lists`);
                            if (getQlists.data) {
                                localStorage.setItem("qestionListsLength", getQlists.data.length);
                            }
                            setIsProgress(false);
                            activity.questionList_id = response.data.questionList_id;
                                navigate('/Dashboard/chooseGame', { state: {courses:courses, courseType: courseType, course: course, activity: activity, stageIndex: stageIndex, courseFormatDate: courseFormatDate, stratingDate: stratingDate, finishingDate: finishingDate  } });
    
                        } else {
                            setIsProgress(false);
                            setIsProgress(false);
                            setModalMessage("An error occurred during saving question list. Please try again.");
                            setCoursebotmodalIsOpen(true);
                        }
                    } else {
                        setIsProgress(false);
                        setModalMessage("Please fill question before saving");
                        setCoursebotmodalIsOpen(true);
                    }
                }
            } else {
                setModalMessage('The questionlist must have at least one question');
                setCoursebotmodalIsOpen(true);
            }
        }catch (error){
            console.log(error);
            console.log('An error occured saving quston list.');
            setModalMessage("An error occurred during saving question list. Please try again.");
            setCoursebotmodalIsOpen(true);
            
        }finally{
            setIsProgress(false);
        }
      
    };


    useEffect(() => {

        const fetchData = async () => {
            try {
                if (questionList_id !== null) {
                    const response = await axiosInstance.get(`${process.env.REACT_APP_ENDPOINT}/question-lists/${questionList_id}`);
                    // const data = await response.json();

                    setSelectedQuestions(response.data);
                }
                else {
                    setSelectedQuestions({ name: "My Manual Questionlist", questions: [], dateCreated: "" });
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [questionList_id]);
    // Question fields pop up
    const [CoursebotmodalIsOpen, setCoursebotmodalIsOpen] = useState(false);
    const EmptyQuestionBotcloseModal = () => {
        setCoursebotmodalIsOpen(false);
    };
    // OnBoarding
    const [Onboarding, setOnboarding] = useState(parseInt(localStorage.getItem("accountActive")));
    const [{ run, steps }, setState] = useState({
        run: true,
        steps: [
            {
                content: <h2>Upload image for any question.</h2>,
                placement: 'right',
                target: '#Uploadimages',
            },
            {
                content: <h2>question list name , you can change it from here.</h2>,
                placement: 'top',
                target: '#QLName',
            },
            {
                content: <h2>Main Topic of question list you can change it click drop down then select.</h2>,
                placement: 'top',
                target: '#MainTopic',
            },
            {
                content: <h2>Created Date of question list on which date this question list has been created.</h2>,
                placement: 'top',
                target: '#QLDate',
            },
            {
                content: <h2>Upload image for any question.</h2>,
                placement: 'top',
                target: '#Uploadimage',
            },
            {
                content: <h2>Here you can customize question , change question or any answer of any question.</h2>,
                placement: 'top',
                target: '#EditQuestion',
            },
            {
                content: <h2>You can change question difficullty Level .</h2>,
                placement: 'top',
                target: '#QDifficulltyLevel',
                title: 'Difficullty Level'
            },
            {
                content: <h2>You can change question bloom Level .</h2>,
                placement: 'top',
                target: '#QBloomLevel',
                title: 'Bloom Level'
            },
            {
                content: <h2>Make a copy of question  .</h2>,
                placement: 'top',
                target: '#QCopy',
            },
            {
                content: <h2>Delete question.</h2>,
                placement: 'top',
                target: '#QDelete',
            },
            {
                content: <h2>Add new question, click here then select question type which type of question you want to add.</h2>,
                placement: 'right',
                target: '#QAdd',
            },
            {
                content: <h2>MCQs type question.</h2>,
                placement: 'top',
                target: '#QMCQs',
            },
            {
                content: <h2>Image type question.</h2>,
                placement: 'top',
                target: '#QImage',
            },
            {
                content: <h2>Essay type question.</h2>,
                placement: 'top',
                target: '#QEssay',
            },
            {
                content: <h2>Reorder type question.</h2>,
                placement: 'top',
                target: '#QReorder',
            },
            {
                content: <h2>Click save button to update your question list and wait till updation proccess complete.</h2>,
                placement: 'right',
                target: '#save',
            },

        ]
    })
    if (!selectedQuestions) {
        return <div>
            <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'>
                <h3 className="text-xl">Question Lists</h3>
                <LoginHeading />
            </div>
            <div className="sm:m-7 my-7 bg-white lg:px-20">
                <div className='flex justify-center static'><img className='absolute top-6' src={QListDashboardIcon} alt="" /></div>
                <div className="py-10 xl:px-20">
                    <div className="grid sm:grid-cols-2 my-5 mx-2 sm:mx-5">
                        <label className="flex" htmlFor="Name of Course"><span><b>Title:</b></span>
                            <Skeleton animation="wave" variant="rounded" width={100} height={30} />                            </label>
                        <label className="flex justify-end" htmlFor="Starting Date"> <span><b>Date Created: </b></span>
                            <Skeleton animation="wave" variant="rounded" width={100} height={30} />                            </label>
                    </div>
                    <div>
                        {/* Question card/row */}
                        <EditQuestionListSkeleton />
                        {/*  Add new Question Handler */}
                        <div className="mt-5 cursor-pointer">
                            <div className="flex justify-center "><Skeleton animation="wave" variant="circular" width={50} height={50} /></div>
                            <p className="flex justify-center mt-2"><Skeleton animation="wave" variant="rounded" width={100} height={30} /></p>
                        </div>
                        <div className="flex justify-center gap-5 py-10">
                            <Skeleton animation="wave" variant="rounded" width={70} height={30} />
                            <Skeleton animation="wave" variant="rounded" width={70} height={30} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    return (
        <div>
            <div className='w-full'>
                <MetaTags title={questionList_id ? `Edit Question list ${selectedQuestions?.name}` : `Create Manual Question list` } description='Edit Questions Lists page--' />
                <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'>
                    <h3 className="text-xl">Question Lists</h3>
                    <LoginHeading objects={setOnboarding} />
                </div>
                <div className="sm:m-7 my-7 bg-white lg:px-20" disabled={progress}>
                {selectedQuestions && Onboarding === 1  &&<Joyride callback={() => { }} continuous showProgress
                    run={run} steps={steps} hideCloseButton scrollToFirstStep showSkipButton
                    styles={{
                        buttonNext: { backgroundColor: '#E5F2E3', borderRadius: '5px',color:'black', },
                        buttonBack: { backgroundColor: '#E5F2E3',  borderRadius: '5px', color:'black', }
                      }} />}
                    {progress && (
                        <div className="lg:ms-56 absolute inset-0 flex justify-center items-center bg-white bg-opacity-75 z-50">
                            <CircularProgress className="text-blue-800" />
                        </div>
                    )}
                    {/* PopUp Fill all field Of question */}
                    <Dialog className="lg:ms-56" open={CoursebotmodalIsOpen} onClose={EmptyQuestionBotcloseModal}>
                        <DialogContent>
                            <Alert severity='error'>{modalMessage}</Alert>
                        </DialogContent>
                        {modalMessage === 'An error occurred during saving question list. Please try again.' ?
                            <div className='flex justify-center'>
                                <Button onClick={() => { handleSave() }} color="primary">
                                    Try Again
                                </Button>
                                <Button onClick={() => { EmptyQuestionBotcloseModal() }} color="primary">
                                    Cancel
                                </Button>

                            </div> :
                            <div className='flex justify-center'>
                                <Button onClick={() => { EmptyQuestionBotcloseModal() }} color="primary">
                                    Okay
                                </Button>

                            </div>}
                    </Dialog>
                    <div className='flex justify-center static'><img className='absolute top-6' src={QListDashboardIcon} alt="" /></div>
                    {(questionList_id) && updatedQuestions.instructor_id !== parseInt(localStorage.getItem("instructor_id")) ? (
                 // Disallow to see and edit other INC question lists
                        <div className="py-10 xl:px-20">
                            <Dialog className="lg:ms-56" open>
                                <DialogContent>
                                <Alert severity="error">
                            <p className="text-center text-2xl font-bold text-red-500">Sory you can't see or edit the another Instructor's question list.</p>
                                </Alert>
                            <div className="flex justify-center">
                                <button className='BackBtn flex p-2 px-7 hover:bg-blue-200 mt-4' onClick={(e) => { questionList_id !== null ? navigate('/Dashboard/questionList') : navigate(-1) }} >
                                    <b>Back to your questions lits</b>
                                    <img className='mt-1 ms-2' src={BackBtn} alt="Back" />
                                </button></div>
                                </DialogContent>
                            </Dialog>
                        </div>    
                ):(
                    <div className="py-10 xl:px-20">
                        <div className="sm:flex relative my-5 mx-2 sm:mx-5 gap-3">
                            <TextField margin="dense" id="QLName"
                                    label="Title"
                                    name="Title"
                                    type="text"
                                    autoComplete='off'
                                    defaultValue={updatedQuestions !== "" ? updatedQuestions.name : "My Manual Questionlist"}
                                    onChange={(e) => { updatedQuestions.name = e.target.value }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                border: '2px solid #F3F6F6',
                                            },
                                            '&.Mui-focused fieldset': {
                                                border: '2px solid #DBE6F5',
                                            },
                                            '&:hover fieldset': {
                                                border: '2px solid #DBE6F5',
                                            },
                                        },
                                    }}
                                    InputProps={{
                                        style: {
                                            borderRadius: "5px",color: '#2E4EB5',
                                            background: `${updatedQuestions.name ? '#F3F6F6' : 'white'}`,
                                        }
                                    }}
                                />
                            {questionList_id !== null &&<FormControl margin="dense"
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    border: '2px solid #F3F6F6',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    border: '2px solid #DBE6F5',
                                                },
                                                '&:hover fieldset': {
                                                    border: '2px solid #DBE6F5',
                                                },
                                            },
                                        }}>
                                        <InputLabel>Main Topic</InputLabel>
                                        <Select
                                            labelId="MainTopic" id='MainTopic'
                                            name="Main Topic"
                                            label="Main Topic"
                                            defaultValue={updatedQuestions.mainTopic}
                                            onChange={(e)=> updatedQuestions.mainTopic = e.target.value}
                                            style={{ borderRadius: "5px", color: '#2E4EB5',width:'150px', background: `${updatedQuestions.mainTopic ? '#F3F6F6' : 'white'}` }}
                                        >
                                            <MenuItem value='Language Arts'>Language Arts</MenuItem>
                                            <MenuItem value='Math' >Math</MenuItem>
                                            <MenuItem value='Science'>Science</MenuItem>
                                            <MenuItem value='Social'>Social</MenuItem>
                                        </Select>
                                    </FormControl>}
                            <label id="QLDate" className="flex-1 text-right mt-5" htmlFor="Starting Date"> <b>Date Created: </b>
                                <span className="text-blue-600">{selectedQuestions.dateCreated !== "" ? selectedQuestions.dateCreated : formattedToday.split(' ')[0]}</span>
                            </label>
                        </div>
                        <div>
                            {updatedQuestions.questions && updatedQuestions.questions.map((question, questionIndex) => (
                                <div key={Math.random()} id={Math.random()}>
                                    <QuestionComponent
                                        question={question}
                                        questionIndex={questionIndex}
                                        onDelete={() => handleDelete(questionIndex)}
                                        copyQuestion={() => copyQuestion(questionIndex)}
                                    />
                                </div>
                            ))}
                            {/* Here is New blank Question */}
                            <div>
                                {AddNewQuestion && (
                                    <QuestionComponent
                                        question={newQuestion}
                                        questionIndex={updatedQuestions.questions.length}
                                        // questionListIndex={questionListIndex}
                                        onDelete={() => handleDelete(updatedQuestions.questions.length)}
                                        copyQuestion={() => copyQuestion(updatedQuestions.questions.length)}
                                        onChange={() => checkEmptyValues(newQuestion)}
                                    />
                                )}
                            </div>

                            {/*  Add new Question Handler */}
                            {SelecteQuestionType &&
                                <div className="mt-5 justify-center flex gap-3">
                                    <Tooltip title='MCQs Type'><img id="QMCQs" className="transition-transform transform hover:scale-110 cursor-pointer" onClick={() => { setIsquestionType('mcqs'); handleAddNew('mcqs') }} src={MCQ} alt="" /></Tooltip>
                                    <Tooltip title='Image Type'><img id="QImage" className="transition-transform transform hover:scale-110 cursor-pointer" onClick={() => { setIsquestionType('image'); handleAddNew('image'); }} src={Image} alt="" /></Tooltip>
                                    <Tooltip title='Essay Type'><img id="QEssay" className="transition-transform transform hover:scale-110 cursor-pointer" onClick={() => { setIsquestionType('essay'); handleAddNew('essay') }} src={Text} alt="" /></Tooltip>
                                    <Tooltip title='Rank Type'><img id="QReorder" className="transition-transform transform hover:scale-110 cursor-pointer" onClick={() => { setIsquestionType('reorder'); handleAddNew('reorder') }} src={RankOrProccess} alt="" /></Tooltip>
                                </div>}
                            <div id="QAdd" className="mt-5 cursor-pointer" onClick={() => { SetSelecteQuestionType(true) }}>
                                <Tooltip title='Add New Question'>
                                    <div className="flex justify-center "><img src={AddNewActivity} alt="" /></div>
                                    <p className="flex justify-center mt-2">Add New Question</p>
                                </Tooltip>
                            </div>

                            <div className="flex justify-center gap-5 py-10">
                                <button type='submit' className='BackBtn flex p-2 px-7 hover:bg-blue-200' onClick={(e) => { questionList_id !== null ? navigate('/Dashboard/questionList') : navigate(-1) }} >
                                    <b>Back </b>
                                    <img className='mt-1 ms-2' src={BackBtn} alt="Back" />
                                </button>
                                <button id="save" type='submit' className='BackBtn flex p-2 px-7 hover:bg-blue-200'
                                    onClick={() => handleSave()}>
                                    <img className='mt-1 mr-2' src={SaveBtn} alt="Save" />
                                    <b> Save</b></button>
                                {/* </Link> */}
                            </div>
                        </div>
                    </div> 
                    )}
                </div>
            </div>
        </div>

    )
};

export default QuestionListEdit;    