import './App.css';
import './index.css';

import Dashboard from './components/pages/sidebar/sidebarMenu';
import SignInPage from './components/pages/signin/signin';
import { RouterProvider, createBrowserRouter, useNavigate } from 'react-router-dom';
import NewCourse from './components/pages/sidebar/sidbarOptionsContent/Activity/Newcourse/newCourse';
import Activity from './components/pages/sidebar/sidbarOptionsContent/Activity/activity';
import Adding from './components/pages/sidebar/sidbarOptionsContent/Activity/Newcourse/adding';
import QuestionList from './components/pages/sidebar/sidbarOptionsContent/QuestionList/questionList';
import Gamizign from './components/pages/sidebar/sidbarOptionsContent/Gamizign/gamizign';
import Insights from './components/pages/sidebar/sidbarOptionsContent/Insights/insights';
import Instructors from './components/pages/sidebar/sidbarOptionsContent/Instructors/instructors';
import LearnerList from './components/pages/sidebar/sidbarOptionsContent/LearnerList/learnerList';
import Users from './components/pages/sidebar/sidbarOptionsContent/Users/users';
import Rewards from './components/pages/sidebar/sidbarOptionsContent/Rewards/rewards';
import Settings from './components/pages/sidebar/sidbarOptionsContent/Settings/settings';
import Help from './components/pages/sidebar/sidbarOptionsContent/Help/help';
import CreatingPptx from './components/pages/sidebar/sidbarOptionsContent/Activity/Newcourse/CreatingPptx';
import CreatingPdf from './components/pages/sidebar/sidbarOptionsContent/Activity/Newcourse/CreatingPdf';
import Customizing from './components/pages/sidebar/sidbarOptionsContent/Activity/Newcourse/customizing';
import SavingQuestions from './components/pages/sidebar/sidbarOptionsContent/Activity/Newcourse/Saving/savingQuestion';
import ChooseGame from './components/pages/sidebar/sidbarOptionsContent/Activity/Newcourse/Saving/chooseGame';
import GameDone from './components/pages/sidebar/sidbarOptionsContent/Activity/Newcourse/Saving/gameDone';
// Patch 3
import AddingActivityType from './components/pages/sidebar/sidbarOptionsContent/Activity/JourneyActivity/activityType';
import MultiActivy from './components/pages/sidebar/sidbarOptionsContent/Activity/JourneyActivity/MultiActivity/mutiActivity';
import QuestionListEdit from './components/pages/sidebar/sidbarOptionsContent/QuestionList/components/EditQuestionList';
import EditReferencedQList from './components/pages/sidebar/sidbarOptionsContent/QuestionList/editReferencedQList';
import PdfDownloadQuestionList from './components/pages/sidebar/sidbarOptionsContent/QuestionList/components/PdfDownloadQuestionList';
import CourseBar from './components/pages/sidebar/sidbarOptionsContent/Gamizign/Components/CourseBar';
import CourseAnalytics from './components/pages/sidebar/sidbarOptionsContent/Gamizign/Components/Analytics';
import HomeWork from './components/pages/sidebar/sidbarOptionsContent/Gamizign/Components/HomeWork/HomeWork';
import CourseSetting from './components/pages/sidebar/sidbarOptionsContent/Gamizign/Components/CourseSetting';
import ViewCourse from './components/pages/sidebar/sidbarOptionsContent/Gamizign/Components/ViewCourse';
import PublishCourse from './components/pages/sidebar/sidbarOptionsContent/Gamizign/Components/PublishCourse';
import GamizignWorld from './components/pages/sidebar/sidbarOptionsContent/The Gamizign World/gamizignWorld';
import ActivityEvaluation from './components/pages/sidebar/sidbarOptionsContent/Gamizign/Components/HomeWork/ActivityEva';
import LearnerEva from './components/pages/sidebar/sidbarOptionsContent/Gamizign/Components/HomeWork/LearnerEva';
// import Admin from './components/Admin';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material/styles'; 
import RouteProtection from './components/RouteProtection/RouteProtection';
import AdminRouteProtection from './components/RouteProtection/AdminRouteProtection';
import { HelmetProvider } from 'react-helmet-async';
import { useState } from 'react';
import SignUp from './components/pages/SignUp/Signup';
import ForgotPassword  from './components/pages/signin/ForgotPassword';
import PaymentForm from './components/payment';
import { clarity } from 'react-microsoft-clarity';
import ProtectPlanRoutes from './components/RouteProtection/PlanProtectRoute';
import { lazy, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import LoadingComponent from './components/GlobalComponents/GlobalLoading';
import EditQuestionListSkeleton from './components/pages/sidebar/sidbarOptionsContent/QuestionList/components/EditQuestionListSkeleton';
import CoursesSkeleton from './components/pages/sidebar/sidbarOptionsContent/Gamizign/Components/CoursesSkeleton';
const Admin = lazy(()=>import('./components/Admin'));

function App() {
  const LicenceType = parseInt(localStorage.getItem("plan"));
  const LearnersList = localStorage.getItem("LearnersList");
  // const navigate = useNavigate();
  const getInitialElement = () => {
    const user_type = localStorage.getItem("user_type");
    const id = localStorage.getItem("instructor_id");
    const token = sessionStorage.getItem('token');
    return( token && user_type === 'Instructor' ? <div className='flex'><Dashboard />{LicenceType === 3 ?<GamizignWorld /> :<Insights/> }</div>
       : token && user_type === 'Admin' ? <Admin /> :<SignInPage setMode={setMode} mode={mode} showLoginFields={true} />);
    // return id === null ? <SignInPage /> : <Dashboard />;
  };
  const [mode, setMode] = useState('light');
  const theme = mode === 'light' ? createTheme({
    typography: {
      allVariants: {
        fontFamily: 'Calibri',
        textTransform: 'none',
        fontSize: 16,
      },
    },
  }):createTheme({
    typography: {
      allVariants: {
        fontFamily: 'Calibri',
        textTransform: 'none',
        fontSize: 16,
      },
    },
    palette: {
      mode: 'dark',
      primary: {
        main: '#ff5252',
      },
    },
  });
  
  let router = createBrowserRouter([
    {
      path: "/",
      element: getInitialElement()
    },
    {
      path: "/signup",
      element: <ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><SignUp/></Suspense></ErrorBoundary>
    },
    {
      path: "/reset-password",
      element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><ForgotPassword/></Suspense></ErrorBoundary>
    },
    {
      path:"/payment",
      element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><PaymentForm/></Suspense></ErrorBoundary>,
    },
    {
      path: "/Dashboard",
      element: <RouteProtection />,
      children: [
        {
          path: "/Dashboard/",
          element: <Dashboard setMode={setMode} mode={mode}/>,
          children: [
            {path: "/Dashboard/", element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}>{LicenceType === 3 ?<GamizignWorld setMode={setMode} mode={mode} /> : <Insights setMode={setMode} mode={mode} />}</Suspense></ErrorBoundary>},
            {path: "/Dashboard/CreateCourse", element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><Activity setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>},
            // Tabs routes here
            {path: "/Dashboard/questionList", element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><QuestionList setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>},
            {path: "/Dashboard/refQuestionList", element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><EditReferencedQList setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>},
            {path: "/Dashboard/Courses", element: <ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<CoursesSkeleton/>}><Gamizign setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>},
            {path: "/Dashboard/insights", element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><Insights setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>},
            // {path: "/Dashboard/instructors", element: <Instructors />},
            {path: "/Dashboard/learnerList", element: LearnersList === 'show' ? <LearnerList /> : <></>},
            // {path: "/Dashboard/users", element: <Users />},
            // {path: "/Dashboard/rewards", element: <Rewards />},
            {path: "/Dashboard/settings", element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><Settings /></Suspense></ErrorBoundary>},
            // {path: "/Dashboard/help", element: <Help />},
            // Create New Activity 
            // {path: "/Dashboard/newcourse", element: <NewCourse setMode={setMode} mode={mode} />},
            {path: "/Dashboard/newcourse", element: <ProtectPlanRoutes/>, children:[{path: "/Dashboard/newcourse", element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><NewCourse setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>}]},
            {path: "/Dashboard/adding", element: <ProtectPlanRoutes/>, children:[{path: "/Dashboard/adding", element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><Adding setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>}]},
            {path: "/Dashboard/creatingPptx", element: <ProtectPlanRoutes/>, children:[{path: "/Dashboard/creatingPptx", element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><CreatingPptx setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>}]},
            {path: "/Dashboard/creatingPdf", element: <ProtectPlanRoutes/>, children:[{path: "/Dashboard/creatingPdf", element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><CreatingPdf setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>}]},
            {path: "/Dashboard/customizing", element: <ProtectPlanRoutes/>, children:[{path: "/Dashboard/customizing", element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><Customizing setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>}]},
            {path: "/Dashboard/saveQuestions", element: <ProtectPlanRoutes/>, children:[{path: "/Dashboard/saveQuestions", element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><SavingQuestions setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>}]},
            {path: "/Dashboard/chooseGame", element: <ProtectPlanRoutes/>, children:[{path: "/Dashboard/chooseGame", element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><ChooseGame setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>}]},
            {path: "/Dashboard/gameDone", element: <ProtectPlanRoutes/>, children:[{path: "/Dashboard/gameDone", element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><GameDone setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>}]},
            // Patch 3 Journey course
            {path: "/Dashboard/activityType", element: <ProtectPlanRoutes/>, children:[{path: "/Dashboard/activityType", element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><AddingActivityType setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>}]},
            // Multi Activity Journey course
            {path: "/Dashboard/multiActivities", element: <ProtectPlanRoutes/>, children:[{path: "/Dashboard/multiActivities", element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><MultiActivy setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>}]},
            // Question List 
            {path: "/Dashboard/DownloadQuestionsList", element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><PdfDownloadQuestionList setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>},
            {path: "/Dashboard/EditQuestionsList", element: <ProtectPlanRoutes/>, children:[{path: "/Dashboard/EditQuestionsList", element:<ErrorBoundary FallbackComponent={EditQuestionListSkeleton}><Suspense fallback={<EditQuestionListSkeleton/>}><QuestionListEdit setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>}]},    
          ]
        },
      ]},

    
    // Gamizign your courses
    {
      path: "/Course",
      element: <RouteProtection />, // Wrap the parent route with RouteProtection
      children: [
        {
          path: "/Course/",
          element: <ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><CourseBar setMode={setMode} mode={mode}/></Suspense></ErrorBoundary>,
          children: [
            {path: "/Course/", element: <ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><CourseAnalytics setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>,},
            {path: "/Course/HomeWork", element: <ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><HomeWork setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>,},
            // {path: "/Course/CourseSetting", element: <ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><CourseSetting setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>,},
            {path: "/Course/ViewCourse", element: <ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><ViewCourse setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>,},
            // {path: "/Course/PublishCourse", element: <ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><PublishCourse /></Suspense></ErrorBoundary>,},
            {path: "/Course/ActivityEvaluation", element: <ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><ActivityEvaluation setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>,},
            {path: "/Course/LearnerEvaluation", element: <ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><LearnerEva setMode={setMode} mode={mode} /></Suspense></ErrorBoundary>,},
          ]
        },
      ]},
  
    // Admin Client routing
    {
      path: "/Admin",
      element:<AdminRouteProtection/>,
      children:[
        {
          path: "/Admin",
          element:<ErrorBoundary FallbackComponent={LoadingComponent}><Suspense fallback={<LoadingComponent/>}><Admin setMode={setMode} mode={mode}/></Suspense></ErrorBoundary>
        },
      ]
    },
    
  ])
  
  clarity.init('l2n6imfomk');

  return (
    
    <ThemeProvider theme={theme}>
      <div>
      <HelmetProvider>
        <RouterProvider router={router} />
        </HelmetProvider>
        {/* <SignInPage/>  */}
      </div>
    </ThemeProvider>
  );
}

export default App;
