import { useEffect, useState } from 'react';
import './PaymentForm.css'; // Assuming you use this for custom CSS

function PaymentForm() {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        // Load PayTabs script dynamically
        const script = document.createElement('script');
        script.src = "https://secure-jordan.paytabs.com/payment/js/paylib.js";
        script.async = true;

        script.onload = () => {
            setLoading(false);
        };

        script.onerror = () => {
            setLoading(false);
            setErrorMessage('Failed to load payment service.');
            console.error("Failed to load PayTabs script.");
        };

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        // Ensure PayTabs script has loaded and paylib is available on the window object
        if (window.paylib) {
            const myform = document.getElementById('payform');
            window.paylib.inlineForm({  
                'key': 'CNK2G6-VT9P6K-2NKRTM-7GVV96',
                'form': myform,
                'autoSubmit': true,
                'callback': function(response) {
                    document.getElementById('paymentErrors').innerHTML = '';
                    if (response.error) {
                        setLoading(false);
                        window.paylib.handleError(document.getElementById('paymentErrors'), response);
                    }
                }
            });
        } else {
            setLoading(false);
            setErrorMessage('Payment service not available.');
        }
    };

    return (
        <div className="payment-container">
            <h2>Secure Payment</h2>
            <p className="subtext">Enter your details below to complete your purchase.</p>
            <form action="/payment" id="payform" method="post" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="cardNumber">Card Number</label>
                    <div className="input-container">
                        <i className="icon fa fa-credit-card"></i>
                        <input type="text" id="cardNumber" data-paylib="number" placeholder="•••• •••• •••• ••••" />
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="expMonth">Expiry Date</label>
                        <div className="input-container">
                            <i className="icon fa fa-calendar"></i>
                            <input type="text" id="expMonth" data-paylib="expmonth" placeholder="MM" maxLength="2" />
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="expYear">&nbsp;</label>
                        <div className="input-container">
                            <input type="text" id="expYear" data-paylib="expyear" placeholder="YYYY" maxLength="4" />
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="cvv">Security Code (CVV)</label>
                    <div className="input-container">
                        <i className="icon fa fa-lock"></i>
                        <input type="text" id="cvv" data-paylib="cvv" placeholder="•••" maxLength="4" />
                    </div>
                </div>

                {errorMessage && <span className="error">{errorMessage}</span>}
                <span id="paymentErrors" className="error"></span>

                <button type="submit" className="submit-btn" disabled={loading}>
                    {loading ? 'Processing...' : 'Place Order'}
                </button>
            </form>
        </div>
    );
}

export default PaymentForm;
