import React, { useState } from "react";
import './saving.css'
import { Link, useLocation, useNavigate } from 'react-router-dom';

import CloseIcon from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/GameDone/close ìcon.png'
import AddNewActivity from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/GameDone/Add New Manual Question icon.png'
import SelectIcon from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/GameDone/select icon for poster.png'
import PdfImage from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/PDF icon hover off color.png'
import VideoImage from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/video icon hover off color.png'
import AudioImage from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/Audio icon hover off color.png'
import ImageImage from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/image icon hover off color.png'
import UrlImage from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/URL icon hover off color.png'
import MultiActivityImage from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch3/Activity-Type/multiactivity  icon hover off.png'
// Game Posters
import Squirrlo from '../../../../../../../assets/images/chooseGame/Squirrlo poster.png'
import DinerDish from '../../../../../../../assets/images/chooseGame/DinerDash poster.png'
import Deepmarine from '../../../../../../../assets/images/chooseGame//Deepmarine poster.png'
import SpaceX from '../../../../../../../assets/images/chooseGame/SpaceoX poster.png'
import Loopylizer from '../../../../../../../assets/images/chooseGame/Loopylizer poster.png'
import Freesign from '../../../../../../../assets/images/chooseGame/Freesign poster.png'
import Jupitario from '../../../../../../../assets/images/chooseGame/Jupitario poster.png'
import SugarSmash from '../../../../../../../assets/images/chooseGame/SugarSmash poster.png'
import Trainaid from '../../../../../../../assets/images/chooseGame/Trainaid poster.png'
import KanwaKango from '../../../../../../../assets/images/chooseGame/Kanwa _ Kango poster.png'
import Lexileague from '../../../../../../../assets/images/chooseGame/Laxileague poster.png'
import StreetSprint from '../../../../../../../assets/images/chooseGame/Street Sprint dashboard poster.png'
import CanalTour from '../../../../../../../assets/images/chooseGame/CanalTour dashboard poster.png'
import AntBattel from '../../../../../../../assets/images/chooseGame/Antbattle War poster Student client.png'
import BeastBasket from '../../../../../../../assets/images/chooseGame/BeastBasket.png'
import HexMatch from '../../../../../../../assets/images/chooseGame/HexMatch.png'
import ActBig from '../../../../../../../assets/images/chooseGame/ActBig poster for instructor client.png'
import SolarizCity from '../../../../../../../assets/images/chooseGame/SolarizCity poster for instructor client.png'
import BehiveLab from '../../../../../../../assets/images/chooseGame/BehiveLab.png'

import axios from "axios";
import LoginHeading from "../../../../../../LoginHeading/LoginHeading";
import { Alert, Button, CircularProgress, Dialog, DialogContent } from "@mui/material";
import MetaTags from "../../../../../../GlobalComponents/MetaData";
import Joyride from "react-joyride";
import axiosInstance from "../../../../../../../axios";
import ChangCourseInfo from "./ChangeCourseInfo";

const GameDone = () => {
    const {courses, courseType, course, activity, selectedgame, stageIndex, courseFormatDate, finishingDate, stratingDate, copyCourse } = useLocation().state;
    const navigate = useNavigate();
    var instructor_id = parseInt(localStorage.getItem("instructor_id"));
    var organization = parseInt(localStorage.getItem('organization'));
    const [IsError, setIsError] = useState(false);
    const [progress, setIsProgress] = useState(false);
    const [respMessage, setRespMessage] = useState('');
    const [UpdatedCourse, setUpdatedCourse] = useState(course);    

// Convert to Date object for copy course object and add an activity 
const [day1, month1, year1] = course.stages[course.stages.length - 1].startingDate.split('/'); 
const stratingDateDateConverted = new Date(year1, month1 - 1, day1); 
const [day2, month2, year2] = course.stages[course.stages.length - 1].finishingDate.split('/'); 
const finishingDateConverted = new Date(year2, month2 - 1, day2);

const [datePart, timePart] = UpdatedCourse.dateCreated  ? UpdatedCourse.dateCreated.split(' ') : UpdatedCourse.startingDate.split(' ')
const [day3, month3, year3] = datePart.split('/');
const hours = timePart ? parseInt(timePart.split(':')[0]) : 0;
const minutes = timePart ? parseInt(timePart.split(':')[1]) : 0;
const seconds = timePart ? parseInt(timePart.split(':')[2]) : 0; 
const CourseDateConverted = new Date(year3, month3 - 1, day3, hours, minutes, seconds);
// change course basic info
const [changCourseInfo, setChangCourseInfo] = useState(copyCourse ? copyCourse : false)
       
    const DialogCourseInfo = () => {
        setChangCourseInfo(false);
        navigate('/Dashboard/CreateCourse');
    }
    const closeDialog = () => {
        setIsError(false);
        navigate('/Dashboard/CreateCourse');
    }
    const ExtractGamePoster = (type) =>{
        switch (type) {
            case 0:
              return Squirrlo;
            case 1:
              return Jupitario;
            case 2:
              return Deepmarine;
            case 3:
              return SpaceX;
            case 4:
              return Freesign;
            case 5:
              return KanwaKango;
            case 6:
              return Lexileague;
            case 7:
              return Trainaid;
            case 8:
              return Loopylizer;
            case 9:
              return DinerDish;
            case 10:
              return SugarSmash;
            case 11:
              return StreetSprint;
            case 12:
              return CanalTour;
            case 13:
              return AntBattel;
            case 14:
              return HexMatch;
            case 15:
              return BeastBasket;
            case 16:
              return ActBig;
            case 17:
              return SolarizCity;
            case 18:
              return BehiveLab;
            default:
              return BeastBasket;
          }
    }
    const imageMap = {
        pdf: PdfImage,
        video: VideoImage,
        audio: AudioImage,
        image: ImageImage,
        url: UrlImage,
        game: ExtractGamePoster(activity.gameType),
        multiActivity: MultiActivityImage,
    };
    const getImageSrc = () => {
        return imageMap[activity.activityType];
    };




    const handleAddNewActivity = () => {
        if (courseType === 'single') {
            if (activity.activity_id) {
                navigate('/Dashboard/adding', { state: { courseType: courseType, course: course } });
            } else {
                course.stages[course.stages.length - 1].activities.push(activity);
                navigate('/Dashboard/adding', { state: {courses:courses, courseType: courseType, course: course } });
            }
        } else {
            if (activity.activity_id) {
                navigate('/Dashboard/activityType', { state: {courses:courses, courseType: courseType, course: course, courseFormatDate: CourseDateConverted, finishingDate: finishingDateConverted, stratingDate: stratingDateDateConverted, } });
            } else {
                course.stages[stageIndex].activities.push(activity);
                navigate('/Dashboard/activityType', { state: {courses:courses, courseType: courseType, course: course, courseFormatDate: courseFormatDate, finishingDate: finishingDate, stratingDate: stratingDate, } });
            }
        }
    }    
    const submitCourse = async () => {
        setIsError(false);
        setIsProgress(true);
        var course_id;
        if (courseType === 'single') { course.stages[course.stages.length - 1].activities.push(activity); } else { course.stages[stageIndex].activities.push(activity); };
        let class_id = course.grade;

        let tempCourse = {
            "name": course.name,
            "courseType": course.courseType,
            "background": course.background,
            "grade": String(course.grade),
            "mainTopic": course.mainTopic,
            "subTopics": course.subTopics,
            "startingDate": course.startingDate,
            "instructor_id": instructor_id
        }        
        try {
            const createCourse = await axiosInstance.post(`${process.env.REACT_APP_ENDPOINT}/courses/${instructor_id}`, tempCourse)
                .then(async (response) => {
                    if (response.data) {
                        course_id = response.data.course_id;
                        const stageCourses = await axiosInstance.post(`${process.env.REACT_APP_ENDPOINT}/courses/${response.data.course_id}/stages`, course.stages);
                        const GetCourses= await axiosInstance.get(`${process.env.REACT_APP_ENDPOINT}/instructors/${instructor_id}/courses`);
                        localStorage.setItem("coursesLength", GetCourses.data.length);
                        return stageCourses;
                    } else {
                        console.log("An error occurred during creating course. Please try again.");
                        return Promise.reject("Error creating course");
                    }
                })
                .then(stageReq => {
                    if (stageReq.data) {
                        let savedCourse = stageReq.data;
                        localStorage.removeItem("courseName");
                        // navigate(`/Dashboard`, { state: { course: savedCourse } });
                    } else {
                        console.log("An error occurred during creating course stages. Please try again.");
                    }
                }).then(async () => {
                    axiosInstance.post(`${process.env.REACT_APP_ENDPOINT}/organization/${organization}/class/${class_id}/courses/${course_id}/students/`).then(response => {
                        if (response.status === 200) {
                            setIsProgress(false);
                            setIsError(true)
                            setRespMessage(`The  "${UpdatedCourse.name}"  course has been created successfully.`);
                        } else {
                            console.log('An error occurred while registering students. Please try again later.');
                        }
                    })
                })
                .catch(error => {
                    if (error) {
                        setRespMessage('An error occurred during saving course. Please try again.');
                        setIsProgress(false);
                        setIsError(true)
                    }
                })
        } catch (error) {
            console.log("An error occurred during creating course. Please try again.");
        }

    }
    // Onboarding
    var first_name = localStorage.getItem("name");
    var last_name = localStorage.getItem("lastname");
    // const [Onboarding, setOnboarding] = useState(courses.length);
    const [Onboarding, setOnboarding] = useState(parseInt(localStorage.getItem("accountActive")));
    const steps = Onboarding === 0  ?    [
        {
            content: <h2>Upload image for any question.</h2>,
            placement: 'right',
            target: '#Uploadimages',
        },
        {
            content: <h2>Congratulation! <b> {first_name} {last_name} </b> finally course creation completed.</h2>,
            placement: 'center',
            target: 'body',
        },
        {
            content: <h2>Save course in you account.</h2>,
            placement: 'left',
            target: '#CourseSave',
        },

    ] :  [
        {
            content: <h2>Upload image for any question.</h2>,
            placement: 'right',
            target: '#Uploadimages',
        },
        {
            content: <h2>Save course in you account.</h2>,
            placement: 'left',
            target: '#CourseSave',
        },
        {
            content: <h2>OR add another activity in this course.</h2>,
            placement: 'left',
            target: '#AddActivity',
        },

    ]
    const [{ run }, setState] = useState({ run: true, });

    return (

        <div className='w-full'>
            <MetaTags title='Save Course' description='Gamizign Save Course page--' />
            <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'>
                <h3 className="text-xl">Create New Activity</h3>
                <LoginHeading objects={setOnboarding} />
            </div>
            <div className="m-7 bg-white contentAreaGameDone relative" disabled={progress}>
                {/* For update basic info of course */}
                {copyCourse && <button onClick={()=>{setChangCourseInfo(true)}} className="px-10 py-2 rounded bg-gray-200 hover:bg-gray-300 absolute top-10 right-5">Change</button>}
                {/* Change info dialog */}
                <Dialog className="lg:ms-56" disabled={progress} open={changCourseInfo} onClose={DialogCourseInfo}>
                    {progress && (
                        <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75 z-50">
                            <CircularProgress className="text-blue-800" />
                        </div>
                    )}
                    <DialogContent>
                        <ChangCourseInfo UpdatedCourse={UpdatedCourse} setUpdatedCourse={setUpdatedCourse} DialogCourseInfo={DialogCourseInfo}
                        submitCourse={submitCourse} CourseDateConverted={CourseDateConverted} courseName={course.name} copyCourse={copyCourse} />
                    </DialogContent>
                </Dialog>
                <div></div>
                {Onboarding <= 1  && <Joyride callback={() => { }} continuous showProgress
                    run={run} steps={steps} hideCloseButton scrollToFirstStep showSkipButton 
                    styles={{
                        buttonNext: { backgroundColor: '#E5F2E3', borderRadius: '5px',color:'black', },
                        buttonBack: { backgroundColor: '#E5F2E3',  borderRadius: '5px', color:'black', }
                      }}/>}
                {progress && (
                    <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75 z-50">
                        <CircularProgress className="text-blue-800" />
                    </div>
                )}
                {/* Errot saving course dialog */}
                <Dialog className="lg:ms-56" open={IsError} onClose={closeDialog}>
                    <DialogContent>
                        <Alert severity={respMessage === 'An error occurred during saving course. Please try again.' ? 'error' : 'success'}>{respMessage}</Alert>
                    </DialogContent>

                    {respMessage === 'An error occurred during saving course. Please try again.' ?
                        <div className='flex justify-center'>
                            <Button onClick={() => { submitCourse() }} color="primary">
                                Try Again
                            </Button>
                            <Button onClick={() => { closeDialog() }} color="primary">
                                Cancel
                            </Button>
                        </div> :
                        <div className='flex justify-center'>
                            <Button onClick={() => { closeDialog() }} color="primary">
                                Okay
                            </Button>
                        </div>}
                </Dialog>
                {/* progress line */}
                <div>
                    <ol className="flex items-center pt-5 px-2 sm:px-10 w-full text-sm font-medium text-center text-gray-500 dark:text-gray-400 sm:text-base">
                        <li className="flex w-full items-center text-green-600 dark:text-blue-500 sm:after:content-[''] after:w-full after:border-green-200 after:border sm:after:inline-block   dark:after:border-gray-700">
                            <span className="flex items-center after:text-gray-200 dark:after:text-gray-500">
                                <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                <b>Adding</b>
                            </span>
                        </li>
                        <li className="flex w-full items-center text-green-600 after:content-[''] after:w-full after:border-green-200 after:border sm:after:inline-block   dark:after:border-gray-700">
                            <span className="flex items-center after:text-gray-200 dark:after:text-gray-500">
                                <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                <b>Creating</b>
                            </span>
                        </li>
                        <li className="flex w-full items-center text-green-600 after:content-[''] after:w-full after:border-green-200 after:border sm:after:inline-block   dark:after:border-gray-700">
                            <span className="flex items-center after:text-gray-200 dark:after:text-gray-500">
                                <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                <b>Customizing</b>
                            </span>
                        </li>
                        <li className="flex items-center text-green-600">
                            <span className="flex items-center">
                                <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                <b>Saving</b>
                            </span>
                        </li>
                    </ol>
                </div>
                <div>
                    <div className="mt-10">
                        <h1 className="text-2xl font-bold flex justify-center">Game is Done</h1>
                        <p className="text-blue-800  flex justify-center">in your course {UpdatedCourse.name}</p>
                        <div className="flex justify-center mt-3 relative">
                            <img className="" src={getImageSrc()} alt="activityIMG" />
                            <span className="absolute bottom-10"><img src={SelectIcon} alt="" /></span>
                        </div>
                    </div>
                </div>
                <div className='flex text-center mt-5 mb-5 items-center justify-center gap-3'>
                    <button id="CourseSave" type='submit' onClick={submitCourse} className='BackBtn flex p-2 px-7 hover:bg-blue-200' ><img className='mr-5 mt-1' src={CloseIcon} alt="Next" /><b> Close</b></button>
                </div>
                <div className="mt-10 ">
                    <div className="flex justify-center"><img id="AddActivity" className="cursor-pointer" src={AddNewActivity} alt="" onClick={handleAddNewActivity} /></div>
                    <p className="flex justify-center mt-2">Add New Activity</p>
                </div>
            </div>
        </div>
    )
}

export default GameDone;