import React from "react";
import PdfImage from '../../assets/icons/ActivityContentIcons//Gamizign_Courses/PDF icon hover off color.png'
import GameImage from '../../assets/icons/ActivityContentIcons//Gamizign_Courses/game icon hover off color.png'
import VideoImage from '../../assets/icons/ActivityContentIcons//Gamizign_Courses/video icon hover off color.png'
import ImageImage from '../../assets/icons/ActivityContentIcons//Gamizign_Courses/image icon hover off color.png'
import AudioImage from '../../assets/icons/ActivityContentIcons//Gamizign_Courses/Audio icon hover off color.png'
import urlImage from '../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/ViewCourse/URL icon hover off color.png'
import MultiAcrivitylImage from '../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/ViewCourse/multiactivity  icon hover off.png'

const GetActivityIcon = (type) => {
    switch (type.toLowerCase()) {
      case 'pdf':
        return PdfImage;
      case 'game':
        return GameImage;
      case 'image':
        return ImageImage;
      case 'audio':
        return AudioImage;
      case 'video':
        return VideoImage;
      case 'url':
        return urlImage;
      case 'multiActivity':
        return MultiAcrivitylImage;
      default:
        return PdfImage;
    }
  };
 export default GetActivityIcon;