import React, { useRef, useState } from "react";
import { Button, CircularProgress, Dialog, DialogActions, DialogTitle, FormControlLabel, Switch, Tooltip } from "@mui/material";
import axios from "axios";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// Bloom level images
import remembering from '../../../../../assets/icons/ActivityContentIcons/NewCourse/QuestionListCard/bloom 1st level 36-36.png'
import understanding from '../../../../../assets/icons/ActivityContentIcons/NewCourse/QuestionListCard/bloom 2nd level 36-36.png'
import applying from '../../../../../assets/icons/ActivityContentIcons/NewCourse/QuestionListCard/bloom 3rd level 36-36.png'
import analyzing from '../../../../../assets/icons/ActivityContentIcons/NewCourse/QuestionListCard/bloom 4th level 36-36.png'
import evaluating from '../../../../../assets/icons/ActivityContentIcons/NewCourse/QuestionListCard/bloom 5th level 36-36.png'
import creating from '../../../../../assets/icons/ActivityContentIcons/NewCourse/QuestionListCard/bloom 6th level 36-36.png'
import MathIcon from '../../../../../assets/icons/ActivityContentIcons/NewCourse/QuestionListCard/math symbole icon 28-28.png'
import MCQsRight from '../../../../../assets/icons//ActivityContentIcons/QuestionListsPatch5/MCQ right answer.png'
import DeletQuestoin from '../../../../../assets/icons//ActivityContentIcons/QuestionListsPatch5/delete icon.png'
import DeletQuestion from '../../../../../assets/icons//ActivityContentIcons/QuestionListsPatch5/delete icon.png'
import DuplicateQuestoin from '../../../../../assets/icons//ActivityContentIcons/QuestionListsPatch5/Duplicate question icon.png'
import PotoIcon from '../../../../../assets/icons//ActivityContentIcons/QuestionListsPatch5/photo icon 28-28.png'
import Image from '../../../../../assets/icons//ActivityContentIcons/QuestionListsPatch5/image icon _ multi activity.png'
import questionImg from '../../../../../assets/icons//ActivityContentIcons/QuestionListsPatch5/image icon _ multi activity.png'
import RadioOnIcon from '../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/radio button on.png'
import RadioOffIcon from '../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/radio button off.png'
import Video from '../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/video icon _ multi activity.png'
import Text from '../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/assay icon _ multi activity.png'
import RankOrProccess from '../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/rank or process icon _ multi activity.png'
import MCQ from '../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/MSQ.png'
import Equation from "../Activity/JourneyActivity/MultiActivity/equation";
import katex from 'katex';
import axiosInstance from "../../../../../axios";

const QuestionComponent = ({ question, questionIndex, onDelete, copyQuestion, newQuestion, onChange }) => {
    const [loading, setLoading] = useState(false);
    const [questImgHover, setQuestImgHover] = useState(false);
    const [answer1ImgHover, setanswer1ImgHover] = useState(false);
    const [answer2ImgHover, setanswer2ImgHover] = useState(false);
    const [answer3ImgHover, setanswer3ImgHover] = useState(false);
    const [answer4ImgHover, setanswer4ImgHover] = useState(false);
    const [questEquationHover, setQuestEquationHover] = useState(false);
    const [answer1EquationHover, setanswer1EquationHover] = useState(false);
    const [answer2EquationHover, setanswer2EquationHover] = useState(false);
    const [answer3EquationHover, setanswer3EquationHover] = useState(false);
    const [answer4EquationHover, setanswer4EquationHover] = useState(false);
    const fileInputRef = useRef(null);
    const url = `${process.env.REACT_APP_ENDPOINT}/uploads/questions/`;

    const [questimgSrc, setQuestImgSrc] = useState(`${url}${question.questionImage}`);
    const [answer1ImgSrc, setanswer1ImgSrc] = useState(`${url}${question.answer1Image}`);
    const [answer2ImgSrc, setanswer2ImgSrc] = useState(`${url}${question.answer2Image}`);
    const [answer3ImgSrc, setanswer3ImgSrc] = useState(`${url}${question.answer3Image}`);
    const [answer4ImgSrc, setanswer4ImgSrc] = useState(`${url}${question.answer4Image}`);

    const handleClose = () => {
        if (!loading) {
            setQuestImgHover(false);
            setanswer1ImgHover(false);
            setanswer2ImgHover(false);
            setanswer3ImgHover(false);
            setanswer4ImgHover(false);
        }
        setQuestEquationHover(false);
        setanswer1EquationHover(false);
        setanswer2EquationHover(false);
        setanswer3EquationHover(false);
        setanswer4EquationHover(false);
    };

    const onImgError = (img) => {
        // eslint-disable-next-line default-case
        switch (img) {
            case 'questionEssay':
                setQuestImgSrc(Text);
                break;
            case 'questionReorder':
                setQuestImgSrc(RankOrProccess);
                break;
            case 'questionImage':
                setQuestImgSrc(Image);
                break;
            case 'questionMcqs':
                setQuestImgSrc(MCQ);
                break;
            case 'answer1Image':
                setanswer1ImgSrc(PotoIcon);
                break;
            case 'answer2Image':
                setanswer2ImgSrc(PotoIcon);
                break;
            case 'answer3Image':
                setanswer3ImgSrc(PotoIcon);
                break;
            case 'answer4Image':
                setanswer4ImgSrc(PotoIcon);
                break;
        }
    }
    const handleClick = () => {
        fileInputRef.current.click();
    };
    const handleImageReplace = async (event, obj) => {
        const file = event.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            setLoading(true);
            try {
                const response = await axiosInstance.postForm(`${process.env.REACT_APP_ENDPOINT}/attachment?type=question`, formData);
                const fileName = response.data;
                if (response.status === 200) {
                    // Update the obj's property directly
                    // eslint-disable-next-line default-case
                    switch (obj) {
                        case 'question':
                            question.questionImage = fileName.message;
                            setQuestImgSrc(`${url}${question.questionImage}`);
                            break;
                        case 'answer1Image':
                            question.answer1Image = fileName.message;
                            setanswer1ImgSrc(`${url}${question.answer1Image}`);
                            break;
                        case 'answer2Image':
                            question.answer2Image = fileName.message;
                            setanswer2ImgSrc(`${url}${question.answer2Image}`);
                            break;
                        case 'answer3Image':
                            question.answer3Image = fileName.message;
                            setanswer3ImgSrc(`${url}${question.answer3Image}`);
                            break;
                        case 'answer4Image':
                            question.answer4Image = fileName.message;
                            setanswer4ImgSrc(`${url}${question.answer4Image}`);
                            break;
                    }
                    setLoading(false);
                    return fileName;
                } else {
                    setLoading(false);
                    console.log('An error occurred while registering students. Please try again later.');
                }


            } catch (error) {
                setLoading(false);
                console.error('Error uploading file:', error);
                return null;
            }
        }
    };

    const handleMouseEnter = () => {
        if (question.questionImage !== " " || question.questionImage !== null) {
            setQuestImgHover(true);
        }
    };

    const handleMouseLeave = () => {
        if (!loading)
            setQuestImgHover(false);
    };
    // Question Difficulty Level
    const QuestionLevel = (difficulty) => {
        switch (difficulty.toLowerCase()) {
            case 'easy':
                return 1;
            case 'medium':
                return 2;
            case 'hard':
                return 3;
            default:
                return 1;
        }
    };

    const handleDifficulty = (value) => {
        let level;
        // eslint-disable-next-line default-case
        switch (parseInt(value)) {
            case 1:
                level = 'easy';
                break;
            case 2:
                level = 'medium';
                break;
            case 3:
                level = 'hard';
                break;
        }
        question.difficulty = level;
    }
    // Question Bloom leve
    const [changeBloomLevel, setChangeBloomLevel] = useState(false)
    const getBloomLevel = (bloom_level) => {
        if (bloom_level === null) {
            switch (bloom_level === null) {
                case null:
                    return remembering;
                default:
                    return remembering;
            }
        } else {

            switch (bloom_level.toLowerCase()) {
                case null:
                    return remembering;
                case 'remembering':
                    return remembering;
                case 'understanding':
                    return understanding;
                case 'applying':
                    return applying;
                case 'analyzing':
                    return analyzing;
                case 'evaluating':
                    return evaluating;
                case 'creating':
                    return creating;
                default:
                    return remembering;
            }
        }
    };
    // Remove image from Question/ any answer
    const RemoveImage = (obj) => {
        // eslint-disable-next-line default-case
        switch (obj) {
            case 'question':
                question.questionImage = null;
                setQuestImgSrc('');
                break;
            case 'answer1Image':
                question.answer1Image = null;
                setanswer1ImgSrc('');
                break;
            case 'answer2Image':
                question.answer2Image = null;
                setanswer2ImgSrc('');
                break;
            case 'answer3Image':
                question.answer3Image = null;
                setanswer3ImgSrc('');
                break;
            case 'answer4Image':
                question.answer4Image = null;
                setanswer4ImgSrc('');
                break;
        }

        // if (!loading) {
        //     setQuestImgHover(false);
        //     setanswer1ImgHover(false);
        //     setanswer2ImgHover(false);
        //     setanswer3ImgHover(false);
        //     setanswer4ImgHover(false);
        // }
    }
    // color palette
    // const QuestionsColors = ['#ACCCE5', '#BCE2BB', '#DDE6C0', '#FFF3C6', '#FEE7C3', '#FCDCBD', '#F6C1C1', '#DFBFDD'];
    const QuestionsColors = ['#DBE6F5', '#E5F2E3', '#F5F9E6', '#FEFAE2', '#FEF7E2', '#FEF1DD', '#FDE4DC', '#EFE1EE'];
    // change the value of input in Equation form
    const SetEquationValue = (value, obj) => {
        try {
            // eslint-disable-next-line default-case
            switch (obj) {
                case 'question':
                    question.question = value;
                    break;
                case 'answer1':
                    question.answer1 = value;
                    break;
                case 'answer2':
                    question.answer2 = value;
                    break;
                case 'answer3':
                    question.answer3 = value;
                    break;
                case 'answer4':
                    question.answer4 = value;
                    break;
            }
    
        } catch (error) {
            console.error('Replacing object error:', error);
        }
    };
    
    // essay Question Is Exactily and Contains Options
    const [selectedOptionEssay, setSelectedOptionEssay] = useState(false);
    const handleOptionChangeEssay = (value, question) => {
        setSelectedOptionEssay(value);
        question.answer_exact = value;
    };
    // Bloom level change 
    const [showDropdown, setShowDropdown] = useState(false);
    const [EmptyQuestion, setEmptyQuestion] = useState(false);
    var Latex = require('react-latex');


    return (
        <div>
            <div className="mt-7 flex justify-end sm:pe-20 pe-2 gap-2">
                <div id="QDifficulltyLevel">
                    <div className="flex justify-end gap-2">
                        <div className={`border rounded px-6 `} style={{ backgroundColor: "#FFF3C6", }}><b>E</b></div>
                        <div className={`border rounded px-6 `} style={{ backgroundColor: "#BCE2BB", }}><b>M</b></div>
                        <div className={`border rounded px-6 `} style={{ backgroundColor: "#EDAEC3", }}><b>H</b></div>
                    </div>
                    <div className="flex justify-end">
                        <div>
                            <input
                                id="steps-range"
                                type="range"
                                min="1"
                                max="3"
                                defaultValue={question.difficulty ? QuestionLevel(question.difficulty) : 1}
                                step="1"
                                onChange={(e) => handleDifficulty(e.target.value, question)}
                                className="h-2 px-5 bg-gray-200 rounded-lg appearance-none cursor-pointer bg-transparent"
                                style={{
                                    '--thumb-color': 'gray', // Change thumb color as desired
                                    '--thumb-width': '15px', // Change thumb width as desired
                                    '--thumb-height': '15px', // Change thumb height as desired
                                    '--thumb-radius': '0', // Set thumb radius to 0 to make it square
                                    'WebkitAppearance': 'none', // Remove default appearance
                                    'appearance': 'none', // Remove default appearance
                                    'cursor': 'pointer', // Add cursor pointer
                                    width: '200px',
                                }}
                            />
                            <style >{`
                            #steps-range::-webkit-slider-thumb {
                            WebkitAppearance: none;
                            appearance: none;
                            width: var(--thumb-width);
                            height: var(--thumb-height);
                            border: none;
                            background-color: var(--thumb-color);
                            clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
                            border-radius: var(--thumb-radius);
                            cursor: pointer;
                            }

                            #steps-range::-moz-range-thumb {
                            width: var(--thumb-width);
                            height: var(--thumb-height);
                            border: none;
                            background-color: var(#b6dff7);
                            clip-path: polygon(#DBE6F5;
                            border-radius: var(--thumb-radius);
                            cursor: pointer;
                            }
                        `}</style>
                        </div>
                    </div>
                </div>
                {/* bloom level */}
                <div id="QBloomLevel" className="">
                    <div className="relative">
                        {/* Button with dropdown toggle */}
                        <button
                            className="flex items-center justify-center w-10 h-10 -mt-1 hover:bg-sky-200 rounded focus:outline-none"
                            onMouseEnter={() => setShowDropdown(true)}
                            onMouseLeave={() => setShowDropdown(false)}
                        >
                            <img src={getBloomLevel(question.bloom_level)} alt="bloom_level" />
                        </button>

                        {/* Dropdown menu */}
                        {showDropdown && (
                            <div className="absolute text-black right-0 z-10 w-40 py-2 bg-white border border-gray-200 rounded shadow-lg top-full" onMouseEnter={() => setShowDropdown(true)} onMouseLeave={() => setShowDropdown(false)}>                               
                                 {/* Option 1 */}
                                 <button className={`flex items-center w-full px-4 py-2 focus:outline-none ${question.bloom_level.toLowerCase() === 'creating' ? 'bg-sky-200' : 'hover:bg-gray-100'}`}
                                    onClick={() => { question.bloom_level = 'creating'; setShowDropdown(false) }}>
                                    <img src={creating} alt="" /> Creating
                                </button>
                                 {/* Option 2 */}
                                 <button className={`flex items-center w-full px-4 py-2  focus:outline-none ${question.bloom_level.toLowerCase() === 'evaluating' ? 'bg-sky-200' : 'hover:bg-gray-100'}`}
                                    onClick={() => { question.bloom_level = 'evaluating'; setShowDropdown(false) }}>
                                    <img src={evaluating} alt="" /> Evaluating
                                </button>
                                 {/* Option 3 */}
                                 <button className={`flex items-center w-full px-4 py-2 focus:outline-none ${question.bloom_level.toLowerCase() === 'analyzing' ? 'bg-sky-200' : 'hover:bg-gray-100'}`}
                                    onClick={() => { question.bloom_level = 'analyzing'; setShowDropdown(false) }}>
                                    <img src={analyzing} alt="" /> Analyzing
                                </button>
                                 {/* Option 4 */}
                                 <button className={`flex items-center w-full px-4 py-2 focus:outline-none ${question.bloom_level.toLowerCase() === 'applying' ? 'bg-sky-200' : 'hover:bg-gray-100'}`}
                                    onClick={() => { question.bloom_level = 'applying'; setShowDropdown(false) }}>
                                    <img src={applying} alt="" /> Applying
                                </button>
                                 {/* Option 5 */}
                                 <button className={`flex items-center w-full px-4 py-2 focus:outline-none ${question.bloom_level.toLowerCase() === 'understanding' ? 'bg-sky-200' : 'hover:bg-gray-100'}`}
                                    onClick={() => { question.bloom_level = 'understanding'; setShowDropdown(false) }}>
                                    <img src={understanding} alt="" /> Understanding
                                </button>
                                 {/* Option 6 */}
                                 <button className={`flex items-center w-full px-4 py-2 focus:outline-none ${question.bloom_level.toLowerCase() === 'remembering' ? 'bg-sky-200' : 'hover:bg-gray-100'}`}
                                    onClick={() => { question.bloom_level = 'remembering'; setShowDropdown(false) }}>
                                    <img src={remembering} alt="" /> Remembering
                                </button>
                            </div>
                        )}
                    </div>
                    {/* <img src={getBloomLevel(question.bloom_level)} alt="" /> */}
                </div>
                <div className="flex gap-5 sm:hidden">
                <Tooltip title="Duplicate"><img className="flex-none mt-4  cursor-pointer w-6 h-6" onClick={() => copyQuestion(questionIndex)} src={DuplicateQuestoin} alt="duplicate" /></Tooltip>
                    <Tooltip title="Delete"><img className="flex-none mt-4  cursor-pointer w-6 h-6" onClick={() => onDelete(questionIndex)} src={DeletQuestoin} alt="delete" /></Tooltip>
                </div>
            </div>
            {/* Easy type question */}
            {question.questionType === 'essay' &&
                <div key={question.id} className="sm:ms-7 flex gap-3">
                    <div className="flex">
                        <b className="mt-5">{questionIndex + 1}. </b>
                        {/* {question.questionImage !== "" ? ( */}
                        {questImgHover ? (
                            <Dialog open={questImgHover} onClose={handleMouseLeave} >
                                <DialogTitle> Question {questionIndex + 1} image <Tooltip title="Only Image"><span className="w-10 -mt-1.5 ms-5"><FormControlLabel control={<Switch checked={EmptyQuestion} onChange={() => {question.question = ''; setEmptyQuestion(prev => !prev);}} />} /></span></Tooltip></DialogTitle>
                                {loading ? (
                                    <CircularProgress className="text-blue-800 cursor-progress m-auto my-10 " />
                                ) :
                                    (<img src={`${questimgSrc}`} onError={(e) => onImgError(`question`)} placeholder={PotoIcon} style={{ margin: 'auto', maxWidth: "auto", height: 'auto' }} alt="Question"></img>)
                                }
                                <DialogActions>
                                    <Button onClick={handleClose}>Close</Button>
                                    <Button onClick={handleClick}>Replace</Button>
                                    <Button onClick={() => { RemoveImage("question") }}>Remove</Button>
                                    <input
                                        type="file"
                                        id="fileInput"
                                        ref={fileInputRef}
                                        className="absolute h-0 w-0 overflow-hidden"
                                        accept="image/*"
                                        onChange={(event) => { handleImageReplace(event, `question`) }}
                                    />
                                </DialogActions>
                            </Dialog>
                        ) : (<Tooltip title={question.questionImage === null || question.questionImage === "" ? "Upload Image" : 'View Image' }>
                            <img id="Uploadimage" className="m-auto cursor-pointer" style={{ width: question.questionImage && '50px', height: question.questionImage && '55px' }} src={`${questimgSrc}`}
                                onError={(e) => onImgError(`questionEssay`)} placeholder={Text} alt="Question"
                                onMouseDown={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            /></Tooltip>
                        )}
                        {/* ) : null} */}
                    </div>
                    <Accordion className="grow">
                        <div id="EditQuestion" style={{ backgroundColor: '#F6C1C1' }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                            >
                                <Typography>

                                </Typography>
                                <div className="QUESTION flex w-full gap-2 rounded sm:p-2 py-2 mx-0">
                                    <input onChange={(e) => question.question = e.target.value} Value={question.question} className="flex-1 py-1 sm:mx-5 ps-2 sm:ps-5" placeholder="Your question" type="text" name="" id="" />
                                    {questEquationHover ? (
                                        <Equation 
                                            Equation={question.question}
                                            saveHandle={handleClose}
                                            EquationHover={questEquationHover} 
                                            text={'Question'}
                                            questionIndex={questionIndex} 
                                            SetEquationValue={SetEquationValue} 
                                            type={'question'} 
                                        />
                                    ) : (
                                        <Tooltip title='Equation'><img className="m-auto cursor-pointer hover:scale-110" src={MathIcon} alt="" onMouseDown={() => setQuestEquationHover(true)} onMouseLeave={() => setQuestEquationHover(false)} /></Tooltip>
                                    )}
                                </div>
                            </AccordionSummary>
                        </div>

                        <AccordionDetails>
                            <Typography>
                                <div className="pt-1">
                                    <div className="flex w-full gap-2 sm:px-16">
                                        <p className="mt-3">Type answer</p>
                                        <input onChange={(e) => question.answer1 = e.target.value} Value={question.answer1} className="w-full border my-1 p-1 ps-5" placeholder="Text" type="text" style={{ height: '100px', }} />
                                        <div className="flex mt-3 gap-1">
                                            {/* {question.answer1Image !== "" ? */}
                                            {answer1ImgHover ? (
                                                <Dialog open={answer1ImgHover} onClose={handleClose} >
                                                    <DialogTitle>1st answer of question {questionIndex + 1} image</DialogTitle>
                                                    {loading ? (
                                                        <CircularProgress className="text-blue-800 cursor-progress m-auto my-10" />
                                                    ) :
                                                        (<img src={answer1ImgSrc} onError={(e) => onImgError(`answer1Image`)} style={{ margin: 'auto', maxWidth: "auto", height: 'auto' }} alt="Answer1"></img>)
                                                    }
                                                    <DialogActions>
                                                        <Button onClick={handleClose}>Close</Button>
                                                        <Button onClick={handleClick}>Replace</Button>
                                                        <Button onClick={() => { RemoveImage("answer1Image") }}>Remove</Button>
                                                        <input
                                                            type="file"
                                                            id="fileInput"
                                                            ref={fileInputRef}
                                                            className="absolute h-0 w-0 overflow-hidden"
                                                            accept="image/*"
                                                            onChange={(event) => { handleImageReplace(event, `answer1Image`) }}
                                                        />
                                                    </DialogActions>
                                                </Dialog>
                                            )
                                                :
                                                (
                                                    <Tooltip title={question.answer1Image === null || question.answer1Image === "" ? "Upload Image" : 'View Image' }><img className={`m-auto cursor-pointer hover:scale-110  ${!question.answer1Image ? '' : 'border px-0'}`} style={{ width: '28px', height: '28px' }} src={`${answer1ImgSrc}`}
                                                        onError={(e) => onImgError(`answer1Image`)} placeholder={PotoIcon} alt="answer1Image"
                                                        onMouseDown={() => setanswer1ImgHover(true)}
                                                        onMouseLeave={() => setanswer1ImgHover(false)}
                                                    /></Tooltip>
                                                )}
                                            {/* : (<></>)} */}
                                            {answer1EquationHover ? (
                                                <Equation Equation={question.answer1} saveHandle={handleClose}
                                                    EquationHover={answer1EquationHover} text={'1st answer of question'}
                                                    questionIndex={questionIndex} SetEquationValue={SetEquationValue} type={'answer1'} />
                                            ) : (
                                                <Tooltip title='Equation'><img className="m-auto cursor-pointer hover:scale-110" src={MathIcon} alt="" onMouseDown={() => setanswer1EquationHover(true)} onMouseLeave={() => setanswer1EquationHover(false)} /></Tooltip>
                                            )}
                                            {/* <img onClick={() => { get_activity_type('eqution') }} className="m-auto cursor-pointer hover:scale-110" src={MathIcon} alt="" /> */}
                                        </div>
                                    </div>
                                    <div className="flex justify-center">
                                        <div className="QUESTIONS-COUNT flex-initial w-100 py-2 md:flex">
                                            <div className="image-radio flex m-2 hover:cursor-pointer" onClick={() => handleOptionChangeEssay(true, question)}>
                                                <span>
                                                    <img
                                                        src={question.answer_exact === true ? RadioOnIcon : RadioOffIcon}
                                                        alt="Task"
                                                        className="radio-image mt-1"
                                                    />
                                                </span>
                                                <span className={`ps-2 ${question.answer_exact === true ? 'font-bold' : 'font-light'}`}>is exactly</span>
                                            </div>
                                            <div className="image-radio flex m-2 hover:cursor-pointer" onClick={() => handleOptionChangeEssay(false, question)}>
                                                <span>
                                                    <img
                                                        src={question.answer_exact === false ? RadioOnIcon : RadioOffIcon}
                                                        alt="Project"
                                                        className="radio-image mt-1"
                                                    />
                                                </span>
                                                <span className={`ps-2 ${question.answer_exact === false ? 'font-bold' : 'font-light'}`}>contain</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Tooltip title="Duplicate"><img id="QCopy" className="flex-none mt-5 cursor-pointer hidden sm:block w-6 h-6" src={DuplicateQuestoin} alt="duplicate" onClick={() => copyQuestion(questionIndex)} /></Tooltip>
                    <Tooltip title="Delete"><img id="QDelete" className="flex-none mt-5 cursor-pointer hidden sm:block w-6 h-6" src={DeletQuestoin} alt="delete" onClick={() => onDelete(questionIndex)} /></Tooltip>

                </div>
            }
            {/* Reorder type question */}
            {question.questionType === 'reorder' &&
                <div key={question.id} className="sm:ms-7 flex gap-3">
                    <div className="flex">
                        <b className="mt-5">{questionIndex + 1}. </b>
                        {/* {question.questionImage !== "" ? ( */}
                        {questImgHover ? (
                            <Dialog open={questImgHover} onClose={handleMouseLeave} >
                                <DialogTitle> Question {questionIndex + 1} image <Tooltip title="Only Image"><span className="w-10 -mt-1.5 ms-5"><FormControlLabel control={<Switch checked={EmptyQuestion} onChange={() => {question.question = ''; setEmptyQuestion(prev => !prev);}} />} /></span></Tooltip></DialogTitle>
                                {loading ? (
                                    <CircularProgress className="text-blue-800 cursor-progress m-auto my-10 " />
                                ) :
                                    (<img src={`${questimgSrc}`} onError={(e) => onImgError(`question`)} placeholder={PotoIcon} style={{ margin: 'auto', maxWidth: "auto", height: 'auto' }} alt="Question"></img>)
                                }
                                <DialogActions>
                                    <Button onClick={handleClose}>Close</Button>
                                    <Button onClick={handleClick}>Replace</Button>
                                    <Button onClick={() => { RemoveImage("question") }}>Remove</Button>
                                    <input
                                        type="file"
                                        id="fileInput"
                                        ref={fileInputRef}
                                        className="absolute h-0 w-0 overflow-hidden"
                                        accept="image/*"
                                        onChange={(event) => { handleImageReplace(event, `question`) }}
                                    />
                                </DialogActions>
                            </Dialog>
                        ) : (<Tooltip title={question.questionImage === null || question.questionImage === "" ? "Upload Image" : 'View Image' }>
                            <img id="Uploadimage" className="m-auto cursor-pointer" style={{ width: question.questionImage && '50px', height: question.questionImage && '55px' }} src={`${questimgSrc}`}
                                onError={(e) => onImgError(`questionReorder`)} placeholder={RankOrProccess} alt="Question"
                                onMouseDown={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            /></Tooltip>
                        )}
                        {/* ) : null} */}
                    </div>
                    <Accordion className="grow">
                        <div id="EditQuestion" style={{ backgroundColor: '#DFBFDD' }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                            >
                                <Typography>

                                </Typography>
                                <div className="QUESTION flex w-full gap-2 rounded sm:p-2 py-2 mx-0">
                                    <input onChange={(e) => question.question = e.target.value} Value={question.question} className="flex-1 py-1 ps-2 sm:mx-5 sm:ps-5" placeholder="Your question" type="text" name="" id="" />
                                    {questEquationHover ? (
                                        <Equation Equation={question.question} saveHandle={handleClose}
                                            EquationHover={questEquationHover} text={'Question'}
                                            questionIndex={questionIndex} SetEquationValue={SetEquationValue} type={'question'} />
                                    ) : (
                                        <Tooltip title='Equation'><img className="m-auto cursor-pointer hover:scale-110" src={MathIcon} alt="" onMouseDown={() => setQuestEquationHover(true)} onMouseLeave={() => setQuestEquationHover(false)} /></Tooltip>
                                    )}
                                </div>
                            </AccordionSummary>
                        </div>
                        <AccordionDetails>
                            <Typography>
                                <div className="w-full">
                                    <div className="flex w-full gap-2">
                                        <span className=" pt-1.5 px-3 bg-green-50 border my-1">1</span>
                                        <input onChange={(e) => question.answer1 = e.target.value} Value={question.answer1} className="w-full border my-1 p-1 ps-5" placeholder="Text" type="text" />
                                        {/* {question.answer1Image !== "" ? */}
                                        {answer1ImgHover ? (
                                            <Dialog open={answer1ImgHover} onClose={handleClose} >
                                                <DialogTitle>1st answer of question {questionIndex + 1} image</DialogTitle>
                                                {loading ? (
                                                    <CircularProgress className="text-blue-800 cursor-progress m-auto my-10" />
                                                ) :
                                                    (<img src={answer1ImgSrc} onError={(e) => onImgError(`answer1Image`)} style={{ margin: 'auto', maxWidth: "auto", height: 'auto' }} alt="Answer1"></img>)
                                                }
                                                <DialogActions>
                                                    <Button onClick={handleClose}>Close</Button>
                                                    <Button onClick={handleClick}>Replace</Button>
                                                    <Button onClick={() => { RemoveImage("answer1Image") }}>Remove</Button>
                                                    <input
                                                        type="file"
                                                        id="fileInput"
                                                        ref={fileInputRef}
                                                        className="absolute h-0 w-0 overflow-hidden"
                                                        accept="image/*"
                                                        onChange={(event) => { handleImageReplace(event, `answer1Image`) }}
                                                    />
                                                </DialogActions>
                                            </Dialog>
                                        )
                                            :
                                            (
                                                <Tooltip title={question.answer1Image === null || question.answer1Image === "" ? "Upload Image" : 'View Image' }><img className={`m-auto cursor-pointer hover:scale-110  ${!question.answer1Image ? '' : 'border px-0'}`} style={{ width: '28px', height: '28px' }} src={`${answer1ImgSrc}`}
                                                    onError={(e) => onImgError(`answer1Image`)} placeholder={PotoIcon} alt="answer1Image"
                                                    onMouseDown={() => setanswer1ImgHover(true)}
                                                    onMouseLeave={() => setanswer1ImgHover(false)}
                                                /></Tooltip>)}
                                        {/* : (<></>)} */}
                                        {answer1EquationHover ? (
                                            <Equation Equation={question.answer1} saveHandle={handleClose}
                                                EquationHover={answer1EquationHover} text={'1st answer of question'}
                                                questionIndex={questionIndex} SetEquationValue={SetEquationValue} type={'answer1'} />
                                        ) : (
                                            <Tooltip title='Equation'><img className="m-auto cursor-pointer hover:scale-110" src={MathIcon} alt="" onMouseDown={() => setanswer1EquationHover(true)} onMouseLeave={() => setanswer1EquationHover(false)} /></Tooltip>
                                        )}
                                    </div>
                                    <div className="flex w-full gap-2">
                                        <span className=" pt-1.5 px-3 bg-orange-50 border my-1">2</span>
                                        <input onChange={(e) => question.answer2 = e.target.value} Value={question.answer2} className="w-full border my-1 p-1 ps-5" placeholder="Text" type="text" />
                                        {/* {question.answer2Image !== "" ? */}
                                        {answer2ImgHover ? (
                                            <Dialog open={answer2ImgHover} onClose={handleClose} >
                                                <DialogTitle>2nd answer of question {questionIndex + 1} image</DialogTitle>
                                                {loading ? (
                                                    <CircularProgress className="text-blue-800 cursor-progress m-auto my-10" />
                                                ) :
                                                    (<img src={answer2ImgSrc} onError={(e) => onImgError(`answer2Image`)} style={{ margin: 'auto', maxWidth: "auto", height: 'auto' }} alt="Answer2"></img>)
                                                }
                                                <DialogActions>
                                                    <Button onClick={handleClose}>Close</Button>
                                                    <Button onClick={handleClick}>Replace</Button>
                                                    <Button onClick={() => { RemoveImage("answer2Image") }}>Remove</Button>
                                                    <input
                                                        type="file"
                                                        id="fileInput"
                                                        ref={fileInputRef}
                                                        className="absolute h-0 w-0 overflow-hidden"
                                                        accept="image/*"
                                                        onChange={(event) => { handleImageReplace(event, `answer2Image`) }}
                                                    />
                                                </DialogActions>
                                            </Dialog>
                                        )
                                            :
                                            (
                                                <Tooltip title={question.answer2Image === null || question.answer2Image === "" ? "Upload Image" : 'View Image' }><img className={`m-auto cursor-pointer hover:scale-110  ${!question.answer2Image ? '' : 'border px-0'}`} style={{ width: '28px', height: '28px' }} src={`${answer2ImgSrc}`}
                                                    onError={(e) => onImgError(`answer2Image`)} placeholder={PotoIcon} alt="answer2Image"
                                                    onMouseDown={() => setanswer2ImgHover(true)}
                                                    onMouseLeave={() => setanswer2ImgHover(false)}
                                                /></Tooltip>
                                            )}
                                        {/* : (<></>)} */}
                                        {answer2EquationHover ? (
                                            <Equation Equation={question.answer2} saveHandle={handleClose}
                                                EquationHover={answer2EquationHover} text={'2nd answer of question'}
                                                questionIndex={questionIndex} SetEquationValue={SetEquationValue} type={'answer2'} />
                                        ) : (
                                            <Tooltip title='Equation'><img className="m-auto cursor-pointer hover:scale-110" src={MathIcon} alt="" onMouseDown={() => setanswer2EquationHover(true)} onMouseLeave={() => setanswer2EquationHover(false)} /></Tooltip>
                                        )}
                                    </div>
                                    <div className="flex w-full gap-2">
                                        <span className=" pt-1.5 px-3 bg-orange-100 border my-1">3</span>
                                        <input onChange={(e) => question.answer3 = e.target.value} Value={question.answer3} className="w-full border my-1 p-1 ps-5" placeholder="Text" type="text" />
                                        {/* {question.answer3Image !== "" ? */}
                                        {answer3ImgHover ? (
                                            <Dialog open={answer3ImgHover} onClose={handleClose} >
                                                <DialogTitle>3rd answer of question {questionIndex + 1} image</DialogTitle>
                                                {loading ? (
                                                    <CircularProgress className="text-blue-800 cursor-progress m-auto my-10" />
                                                ) :
                                                    (<img src={answer3ImgSrc} onError={(e) => onImgError(`answer3Image`)} style={{ margin: 'auto', maxWidth: "auto", height: 'auto' }} alt="Answer3"></img>)
                                                }
                                                <DialogActions>
                                                    <Button onClick={handleClose}>Close</Button>
                                                    <Button onClick={handleClick}>Replace</Button>
                                                    <Button onClick={() => { RemoveImage("answer3Image") }}>Remove</Button>
                                                    <input
                                                        type="file"
                                                        id="fileInput"
                                                        ref={fileInputRef}
                                                        className="absolute h-0 w-0 overflow-hidden"
                                                        accept="image/*"
                                                        onChange={(event) => { handleImageReplace(event, `answer3Image`) }}
                                                    />
                                                </DialogActions>
                                            </Dialog>
                                        )
                                            :
                                            (
                                                <Tooltip title={question.answer3Image === null || question.answer3Image === "" ? "Upload Image" : 'View Image' }><img className={`m-auto cursor-pointer hover:scale-110  ${!question.answer3Image ? '' : 'border px-0'}`} style={{ width: '28px', height: '28px' }} src={`${answer3ImgSrc}`}
                                                    onError={(e) => onImgError(`answer3Image`)} placeholder={PotoIcon} alt="answer3Image"
                                                    onMouseDown={() => setanswer3ImgHover(true)}
                                                    onMouseLeave={() => setanswer3ImgHover(false)}
                                                /></Tooltip>
                                            )}
                                        {/* : (<></>)} */}
                                        {answer3EquationHover ? (
                                            <Equation Equation={question.answer3} saveHandle={handleClose}
                                                EquationHover={answer3EquationHover} text={'3rd answer of question'}
                                                questionIndex={questionIndex} SetEquationValue={SetEquationValue} type={'answer3'} />
                                        ) : (
                                            <Tooltip title='Equation'><img className="m-auto cursor-pointer hover:scale-110" src={MathIcon} alt="" onMouseDown={() => setanswer3EquationHover(true)} onMouseLeave={() => setanswer3EquationHover(false)} /></Tooltip>
                                        )}
                                    </div>
                                    <div className="flex w-full gap-2">
                                        <span className=" pt-1.5 px-3 bg-orange-200 border my-1">4</span>
                                        <input onChange={(e) => question.answer4 = e.target.value} Value={question.answer4} className="w-full border my-1 p-1 ps-5" placeholder="Text" type="text" />
                                        {/* {question.answer4Image !== "" ? */}
                                        {answer4ImgHover ? (
                                            <Dialog open={answer4ImgHover} onClose={handleClose} >
                                                <DialogTitle>4th answer of question {questionIndex + 1} image</DialogTitle>
                                                {loading ? (
                                                    <CircularProgress className="text-blue-800 cursor-progress m-auto my-10" />
                                                ) :
                                                    (<img src={answer4ImgSrc} onError={(e) => onImgError(`answer4Image`)} style={{ margin: 'auto', maxWidth: "auto", height: 'auto' }} alt="Answer4"></img>)
                                                }
                                                <DialogActions>
                                                    <Button onClick={handleClose}>Close</Button>
                                                    <Button onClick={handleClick}>Replace</Button>
                                                    <Button onClick={() => { RemoveImage("answer4Image") }}>Remove</Button>
                                                    <input
                                                        type="file"
                                                        id="fileInput"
                                                        ref={fileInputRef}
                                                        className="absolute h-0 w-0 overflow-hidden"
                                                        accept="image/*"
                                                        onChange={(event) => { handleImageReplace(event, `answer4Image`) }}
                                                    />
                                                </DialogActions>
                                            </Dialog>
                                        )
                                            :
                                            (
                                                <Tooltip title={question.answer4Image === null || question.answer4Image === "" ? "Upload Image" : 'View Image' }><img className={`m-auto cursor-pointer hover:scale-110  ${!question.answer4Image ? '' : 'border px-0'}`} style={{ width: '28px', height: '28px' }} src={`${answer4ImgSrc}`}
                                                    onError={(e) => onImgError(`answer4Image`)} placeholder={PotoIcon} alt="answer4Image"
                                                    onMouseDown={() => setanswer4ImgHover(true)}
                                                    onMouseLeave={() => setanswer4ImgHover(false)}
                                                /></Tooltip>
                                                )}
                                        {/* : (<></>)} */}
                                        {answer4EquationHover ? (
                                            <Equation Equation={question.answer4} saveHandle={handleClose}
                                                EquationHover={answer4EquationHover} text={'4th answer of question'}
                                                questionIndex={questionIndex} SetEquationValue={SetEquationValue} type={'answer4'} />
                                        ) : (
                                            <Tooltip title='Equation'><img className="m-auto cursor-pointer hover:scale-110" src={MathIcon} alt="" onMouseDown={() => setanswer4EquationHover(true)} onMouseLeave={() => setanswer4EquationHover(false)} /></Tooltip>
                                        )}
                                    </div>
                                </div>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Tooltip title="Duplicate"><img id="QCopy" className="flex-none mt-5 cursor-pointer hidden sm:block w-6 h-6" src={DuplicateQuestoin} alt="duplicate" onClick={() => copyQuestion(questionIndex)} /></Tooltip>
                    <Tooltip title="Delete"><img id="QDelete" className="flex-none mt-5 cursor-pointer hidden sm:block w-6 h-6" src={DeletQuestoin} alt="delete" onClick={() => onDelete(questionIndex)} /></Tooltip>
                </div>
            }
            {/* Image , video , audio and MCQs type question */}
            {(question.questionType === 'audio' || question.questionType === 'image' || question.questionType === 'video' || question.questionType === 'mcqs' || question.questionType === null) &&
                <div key={question} className="sm:ms-7 flex gap-3">
                    <div className="flex">
                        <b className="mt-5">{questionIndex + 1}. </b>
                        {/* {question.questionImage !== "" ? ( */}
                        {questImgHover ? (
                            <Dialog open={questImgHover} onClose={handleMouseLeave} >
                                <DialogTitle> Question {questionIndex + 1} image <Tooltip title="Only Image"><span className="w-10 -mt-1.5 ms-5"><FormControlLabel control={<Switch checked={EmptyQuestion} onChange={() => {question.question = ''; setEmptyQuestion(prev => !prev);}} />} /></span></Tooltip></DialogTitle>
                                {loading ? (
                                    <CircularProgress className="text-blue-800 cursor-progress m-auto my-10 " />
                                ) :
                                    (<img src={`${questimgSrc}`} onError={(e) => onImgError(`questionImage`)} placeholder={PotoIcon} style={{ margin: 'auto', maxWidth: "auto", height: 'auto' }} alt="Question"></img>)
                                }
                                <DialogActions>
                                    <Button onClick={handleClose}>Close</Button>
                                    <Button onClick={handleClick}>Replace</Button>
                                    <Button onClick={() => { RemoveImage("question") }}>Remove</Button>
                                    <input
                                        type="file"
                                        id="fileInput"
                                        ref={fileInputRef}
                                        className="absolute h-0 w-0 overflow-hidden"
                                        accept="image/*"
                                        onChange={(event) => { handleImageReplace(event, `question`) }}
                                    />
                                </DialogActions>
                            </Dialog>
                        ) : (
                            question.questionType === 'mcqs' || question.questionType === null ?
                                ( <Tooltip title={question.questionImage === null || question.questionImage === "" ? "Upload Image" : 'View Image' }>
                                    <img id="Uploadimage" className="m-auto cursor-pointer" style={{ width: question.questionImage && '50px', height: question.questionImage && '55px' }} src={`${questimgSrc}`}
                                    onError={(e) => onImgError(`questionMcqs`)} placeholder={MCQ} alt="Question"
                                    onMouseDown={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                /></Tooltip>)
                                :
                                <Tooltip title={question.questionImage === null || question.questionImage === "" ? "Upload Image" : 'View Image' }>
                                    <img className="m-auto cursor-pointer" style={{ width: question.questionImage && '50px', height: question.questionImage && '55px' }} src={`${questimgSrc}`}
                                    onError={(e) => onImgError(`questionImage`)} placeholder={RankOrProccess} alt="Question"
                                    onMouseDown={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                /></Tooltip>
                        )}
                        {/* ) : null} */}
                        {/* {question.questionType === 'image' && <span><img className="m-auto flex-none w-14 h-16" style={{ borderRadius: '5px' }} src={Image} alt="" /></span>} */}
                        {question.questionType === 'video' && <span><img className="m-auto flex-none w-14 h-16" style={{ borderRadius: '5px' }} src={Video} alt="" /></span>}
                        {question.questionType === 'audio' && <span><img className="m-auto flex-none w-14 h-16" style={{ borderRadius: '5px' }} src={Audio} alt="" /></span>}
                        {/* {question.questionType === 'mcqs' && <span className="mt-6"><span className="m-auto border sm:px-2 py-5" style={{ borderRadius: '5px' }}>MCQs</span></span>} */}

                    </div>
                    <Accordion className="grow">
                        <div id="EditQuestion" style={{
                            backgroundColor:
                                question.questionType === 'image' ? '#ACCCE5' :
                                    question.questionType === 'video' ? '#FFF3C6' :
                                        question.questionType === 'audio' ? '#DDE6C0' :
                                            question.questionType === 'mcqs' || question.questionType === null ? '#E5F2E3' :
                                                'initial'
                        }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                            >
                                <Typography>

                                </Typography>
                                <div className="QUESTION flex w-full gap-2 rounded sm:p-2 py-2 mx-0">
                                    <input onChange={(e) => question.question = e.target.value} Value={question.question} className="flex-1 py-1 ps-2 sm:mx-5 sm:ps-5" placeholder="Your question" type="text" name="" id="" />
                                    {questEquationHover ? (
                                        <Equation Equation={question.question} saveHandle={handleClose}
                                            EquationHover={questEquationHover} text={'Question'}
                                            questionIndex={questionIndex} SetEquationValue={SetEquationValue} type={'question'} />
                                    ) : (
                                        <Tooltip title='Equation'><img className="m-auto cursor-pointer hover:scale-110" src={MathIcon} alt="" onMouseDown={() => setQuestEquationHover(true)} onMouseLeave={() => setQuestEquationHover(false)} /></Tooltip>
                                    )}
                                </div>
                            </AccordionSummary>
                        </div>
                        <AccordionDetails>
                            <Typography>
                                <div className="pt-1">
                                    <div className="flex w-full gap-2">
                                        <img className="m-auto" src={MCQsRight} alt="" />
                                        <input onChange={(e) => question.answer1 = e.target.value} Value={question.answer1} className="w-full border my-1 p-1 ps-5" placeholder="Text" type="text" />
                                        {/* {question.answer1Image !== "" ? */}
                                        {answer1ImgHover ? (
                                            <Dialog open={answer1ImgHover} onClose={handleClose} >
                                                <DialogTitle>1st answer of question {questionIndex + 1} image</DialogTitle>
                                                {loading ? (
                                                    <CircularProgress className="text-blue-800 cursor-progress m-auto my-10" />
                                                ) :
                                                    (<img src={answer1ImgSrc} onError={(e) => onImgError(`answer1Image`)} style={{ margin: 'auto', maxWidth: "auto", height: 'auto' }} alt="Answer1"></img>)
                                                }
                                                <DialogActions>
                                                    <Button onClick={handleClose}>Close</Button>
                                                    <Button onClick={handleClick}>Replace</Button>
                                                    <Button onClick={() => { RemoveImage("answer1Image") }}>Remove</Button>
                                                    <input
                                                        type="file"
                                                        id="fileInput"
                                                        ref={fileInputRef}
                                                        className="absolute h-0 w-0 overflow-hidden"
                                                        accept="image/*"
                                                        onChange={(event) => { handleImageReplace(event, `answer1Image`) }}
                                                    />
                                                </DialogActions>
                                            </Dialog>
                                        )
                                            :
                                            (
                                                <Tooltip title={question.answer1Image === null || question.answer1Image === "" ? "Upload Image" : 'View Image' }><img className={`m-auto cursor-pointer hover:scale-110  ${!question.answer1Image ? '' : 'border px-0'}`} style={{ width: '28px', height: '28px' }} src={`${answer1ImgSrc}`}
                                                    onError={(e) => onImgError(`answer1Image`)} placeholder={PotoIcon} alt="answer1Image"
                                                    onMouseDown={() => setanswer1ImgHover(true)}
                                                    onMouseLeave={() => setanswer1ImgHover(false)}
                                                /></Tooltip>
                                            )}
                                        {/* : (<></>)} */}
                                        {answer1EquationHover ? (
                                            <Equation Equation={question.answer1} saveHandle={handleClose}
                                                EquationHover={answer1EquationHover} text={'1st answer of question'}
                                                questionIndex={questionIndex} SetEquationValue={SetEquationValue} type={'answer1'} />
                                        ) : (
                                            <Tooltip title='Equation'><img className="m-auto cursor-pointer hover:scale-110" src={MathIcon} alt="" onMouseDown={() => setanswer1EquationHover(true)} onMouseLeave={() => setanswer1EquationHover(false)} /></Tooltip>
                                        )}
                                    </div>
                                    <div className="flex w-full gap-2">
                                        <input onChange={(e) => question.answer2 = e.target.value} Value={question.answer2} className="w-full border my-1 p-1 ps-5" placeholder="Text" type="text" />
                                        {/* {question.answer2Image !== "" ? */}
                                        {answer2ImgHover ? (
                                            <Dialog open={answer2ImgHover} onClose={handleClose} >
                                                <DialogTitle>2nd answer of question {questionIndex + 1} image</DialogTitle>
                                                {loading ? (
                                                    <CircularProgress className="text-blue-800 cursor-progress m-auto my-10" />
                                                ) :
                                                    (<img src={answer2ImgSrc} onError={(e) => onImgError(`answer2Image`)} style={{ margin: 'auto', maxWidth: "auto", height: 'auto' }} alt="Answer2"></img>)
                                                }
                                                <DialogActions>
                                                    <Button onClick={handleClose}>Close</Button>
                                                    <Button onClick={handleClick}>Replace</Button>
                                                    <Button onClick={() => { RemoveImage("answer2Image") }}>Remove</Button>
                                                    <input
                                                        type="file"
                                                        id="fileInput"
                                                        ref={fileInputRef}
                                                        className="absolute h-0 w-0 overflow-hidden"
                                                        accept="image/*"
                                                        onChange={(event) => { handleImageReplace(event, `answer2Image`) }}
                                                    />
                                                </DialogActions>
                                            </Dialog>
                                        )
                                            :
                                            (
                                                <Tooltip title={question.answer2Image === null || question.answer2Image === "" ? "Upload Image" : 'View Image' }><img className={`m-auto cursor-pointer hover:scale-110  ${!question.answer2Image ? '' : 'border px-0'}`} style={{ width: '28px', height: '28px' }} src={`${answer2ImgSrc}`}
                                                    onError={(e) => onImgError(`answer2Image`)} placeholder={PotoIcon} alt="answer2Image"
                                                    onMouseDown={() => setanswer2ImgHover(true)}
                                                    onMouseLeave={() => setanswer2ImgHover(false)}
                                                /></Tooltip>
                                            )}
                                        {/* : (<></>)} */}
                                        {answer2EquationHover ? (
                                            <Equation Equation={question.answer2} saveHandle={handleClose}
                                                EquationHover={answer2EquationHover} text={'2nd answer of question'}
                                                questionIndex={questionIndex} SetEquationValue={SetEquationValue} type={'answer2'} />
                                        ) : (
                                            <Tooltip title='Equation'><img className="m-auto cursor-pointer hover:scale-110" src={MathIcon} alt="" onMouseDown={() => setanswer2EquationHover(true)} onMouseLeave={() => setanswer2EquationHover(false)} /></Tooltip>
                                        )}
                                    </div>
                                    <div className="flex w-full gap-2">
                                        <input onChange={(e) => question.answer3 = e.target.value} Value={question.answer3} className="w-full border my-1 p-1 ps-5" placeholder="Text" type="text" />
                                        {/* {question.answer3Image !== "" ? */}
                                        {answer3ImgHover ? (
                                            <Dialog open={answer3ImgHover} onClose={handleClose} >
                                                <DialogTitle>3rd answer of question {questionIndex + 1} image</DialogTitle>
                                                {loading ? (
                                                    <CircularProgress className="text-blue-800 cursor-progress m-auto my-10" />
                                                ) :
                                                    (<img src={answer3ImgSrc} onError={(e) => onImgError(`answer3Image`)} style={{ margin: 'auto', maxWidth: "auto", height: 'auto' }} alt="Answer3"></img>)
                                                }
                                                <DialogActions>
                                                    <Button onClick={handleClose}>Close</Button>
                                                    <Button onClick={handleClick}>Replace</Button>
                                                    <Button onClick={() => { RemoveImage("answer3Image") }}>Remove</Button>
                                                    <input
                                                        type="file"
                                                        id="fileInput"
                                                        ref={fileInputRef}
                                                        className="absolute h-0 w-0 overflow-hidden"
                                                        accept="image/*"
                                                        onChange={(event) => { handleImageReplace(event, `answer3Image`) }}
                                                    />
                                                </DialogActions>
                                            </Dialog>
                                        )
                                            :
                                            (
                                                <Tooltip title={question.answer3Image === null || question.answer3Image === "" ? "Upload Image" : 'View Image' }><img className={`m-auto cursor-pointer hover:scale-110  ${!question.answer3Image ? '' : 'border px-0'}`} style={{ width: '28px', height: '28px' }} src={`${answer3ImgSrc}`}
                                                    onError={(e) => onImgError(`answer3Image`)} placeholder={PotoIcon} alt="answer3Image"
                                                    onMouseDown={() => setanswer3ImgHover(true)}
                                                    onMouseLeave={() => setanswer3ImgHover(false)}
                                                /></Tooltip>
                                            )}
                                        {/* : (<></>)} */}
                                        {answer3EquationHover ? (
                                            <Equation Equation={question.answer3} saveHandle={handleClose}
                                                EquationHover={answer3EquationHover} text={'3rd answer of question'}
                                                questionIndex={questionIndex} SetEquationValue={SetEquationValue} type={'answer3'} />
                                        ) : (
                                            <Tooltip title='Equation'><img className="m-auto cursor-pointer hover:scale-110" src={MathIcon} alt="" onMouseDown={() => setanswer3EquationHover(true)} onMouseLeave={() => setanswer3EquationHover(false)} /></Tooltip>
                                        )}
                                    </div>
                                    <div className="flex w-full gap-2">
                                        <input onChange={(e) => question.answer4 = e.target.value} Value={question.answer4} className="w-full border my-1 p-1 ps-5" placeholder="Text" type="text" />
                                        {/* {question.answer4Image !== "" ? */}
                                        {answer4ImgHover ? (
                                            <Dialog open={answer4ImgHover} onClose={handleClose} >
                                                <DialogTitle>4th answer of question {questionIndex + 1} image</DialogTitle>
                                                {loading ? (
                                                    <CircularProgress className="text-blue-800 cursor-progress m-auto my-10" />
                                                ) :
                                                    (<img src={answer4ImgSrc} onError={(e) => onImgError(`answer4Image`)} style={{ margin: 'auto', maxWidth: "auto", height: 'auto' }} alt="Answer4"></img>)
                                                }
                                                <DialogActions>
                                                    <Button onClick={handleClose}>Close</Button>
                                                    <Button onClick={handleClick}>Replace</Button>
                                                    <Button onClick={() => { RemoveImage("answer4Image") }}>Remove</Button>
                                                    <input
                                                        type="file"
                                                        id="fileInput"
                                                        ref={fileInputRef}
                                                        className="absolute h-0 w-0 overflow-hidden"
                                                        accept="image/*"
                                                        onChange={(event) => { handleImageReplace(event, `answer4Image`) }}
                                                    />
                                                </DialogActions>
                                            </Dialog>
                                        )
                                            :
                                            (
                                                <Tooltip title={question.answer4Image === null || question.answer4Image === "" ? "Upload Image" : 'View Image' }><img className={`m-auto cursor-pointer hover:scale-110  ${!question.answer4Image ? '' : 'border px-0'}`} style={{ width: '28px', height: '28px' }} src={`${answer4ImgSrc}`}
                                                    onError={(e) => onImgError(`answer4Image`)} placeholder={PotoIcon} alt="answer4Image"
                                                    onMouseDown={() => setanswer4ImgHover(true)}
                                                    onMouseLeave={() => setanswer4ImgHover(false)}
                                                /></Tooltip>
                                            )}
                                        {/* : (<></>)} */}
                                        {answer4EquationHover ? (
                                            <Equation Equation={question.answer4} saveHandle={handleClose}
                                                EquationHover={answer4EquationHover} text={'4th answer of question'}
                                                questionIndex={questionIndex} SetEquationValue={SetEquationValue} type={'answer4'} />
                                        ) : (
                                            <Tooltip title='Equation'><img className="m-auto cursor-pointer hover:scale-110" src={MathIcon} alt="" onMouseDown={() => setanswer4EquationHover(true)} onMouseLeave={() => setanswer4EquationHover(false)} /></Tooltip>
                                        )}
                                    </div>
                                </div>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Tooltip title="Duplicate"><img id="QCopy" className="flex-none mt-5 cursor-pointer hidden sm:block w-6 h-6" src={DuplicateQuestoin} alt="duplicate" onClick={() => copyQuestion(questionIndex)} /></Tooltip>
                    <Tooltip title="Delete"><img id="QDelete" className="flex-none mt-5 cursor-pointer hidden sm:block w-6 h-6" src={DeletQuestoin} alt="delete" onClick={() => onDelete(questionIndex)} /></Tooltip>
                </div>
            }

        </div>
    );
};
export default QuestionComponent;