import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AnalyticnHIcon from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/AnalyticsCourse/Analytics head icon.png'
import GridIconOn from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/grid icon on.png'
import GridIconOff from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/grid icon off.png'
import ListIconOn from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/list icon on.png'
import ListIconOff from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/list icon off.png'
// Activities
import EditIcon from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/AnalyticsCourse/edit icon.png'
import DeleteIcon from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/AnalyticsCourse/delete icon.png'
// Course Chart
import ChartAccurancy from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/AnalyticsCourse/Accuracy dashboard.png'
import ChartHardest from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/AnalyticsCourse/Hardest Questions dashboard.png'
import ChartEasiest from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/AnalyticsCourse/Easiest Questions dashboard.png'
import ChartComplished from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/AnalyticsCourse/Complished dashboard.png'
import sadDog from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/AnalyticsCourse/sadDog.png'
import RankIcon from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/AnalyticsCourse/rank icon.png'
import AccuracyIcon from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/AnalyticsCourse/accurecy icon.png'
import ScoreIcon from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/AnalyticsCourse/score icon.png'
import AttemptIcon from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/AnalyticsCourse/attemped icon.png'

import { useRef } from 'react';
import Chart from 'chart.js/auto';
import LoginHeading from '../../../../../LoginHeading/LoginHeading';
import PlayersAnalysis from './Players';
import LoadingComponent from '../../../../../GlobalComponents/GlobalLoading';
import { Skeleton, Tooltip } from '@mui/material';
import ContentLoader from 'react-content-loader';
import MetaTags from '../../../../../GlobalComponents/MetaData';
import axiosInstance from '../../../../../../axios';

const BarChart = ({ days, gamesPlayed, players }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');

    // Check if there is an existing chart instance
    if (chartInstance.current) {
      // Destroy the existing chart instance
      chartInstance.current.destroy();
    }

    // Initialize Chart.js with line chart configuration
    chartInstance.current = new Chart(ctx, {
      type: 'line',
      data: {
        labels: days,
        datasets: [
          {
            label: 'Game Played', // Label for the first data set
            data: gamesPlayed, // First set of values
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            yAxisID: 'y',
            pointRadius: 3,
            borderWidth: 5,
            tension: 0.4,
          },
          {
            label: 'Players', // Label for the second data set
            data: players, // Second set of values
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            pointRadius: 3,
            borderWidth: 5,
            tension: 0.4,
            yAxisID: 'y1',
            borderRadius: '25px',
            tension: 0.4,
          }
        ]
      },
      options: {
        plugins: {
          legend: {
            labels: {
              pointStyle: 'circle', // Set the desired shape of the labels to 'circle'
            }
          }
        },
        responsive: true,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        stacked: false,
        scales: {
          y: {
            type: 'linear',
            display: true,
            position: 'left',
          },
          y1: {
            type: 'linear',
            display: true,
            position: 'right',

            // grid line settings
            grid: {
              drawOnChartArea: false, // only want the grid lines for one axis to show up
              display: false,
            },
          },
          x: {
            grid: {
              display: false
            }
          },
        }
      }
    });

    // Cleanup function to destroy the chart instance when component unmounts
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [days, gamesPlayed, players]);

  return <canvas ref={chartRef} />
};


const CourseAnalytics = () => {

  const [SelectedCourse, setSelectedCourse] = useState(null)
  const [CourseAnalysis, setCourseAnalysis] = useState(null)
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getCourses = async () => {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };

      try {
        setLoading(true);
        const coursedata = await axiosInstance.get(`${process.env.REACT_APP_ENDPOINT}/analytics/courses/${course_id}`, requestOptions);
        setCourseAnalysis(coursedata.data);

        const response = await axiosInstance.get(`${process.env.REACT_APP_ENDPOINT}/courses/${course_id}/`, requestOptions);
        setSelectedCourse(response.data);
        setfilteredStudent(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching courses:', error);
        // Handle the error if needed
      } finally {
        setLoading(false);
      }
    };
    getCourses();
  }, []);


  // Function to flatten analyticsList arrays
  function flattenAnalyticsList(data) {
    let analytics = [];
    if (Array.isArray(data)) {
      data.forEach((item) => {
        if (item.analyticsList) {
          analytics = analytics.concat(item.analyticsList);
        }
        if (item.activities) {
          analytics = analytics.concat(flattenAnalyticsList(item.activities));
        }
      });
    }
    return analytics;
  }
  const flattenedAnalytics = SelectedCourse?.stages ? flattenAnalyticsList(SelectedCourse.stages) : [];
  // const flattenedAnalytics = flattenAnalyticsList(SelectedCourse.stages);
  const uniquePlayersPerDay = new Map();
  const days = [];
  const playersPlayed = [];
  const gamesPlayed = [];
  flattenedAnalytics.forEach((item) => {
    // Check if timeDateList exists and is an array before iterating over it
    if (Array.isArray(item.timeDateList)) {
      item.timeDateList.forEach((dateString) => {
        const date = dateString; // Ensure parseDateString is defined
        const playersCount = item.sessions;
        const gamesCount = item.sessions;
        const studentId = item.student_id;

        const existingIndex = days.findIndex((day) => day === date);
        if (existingIndex === -1) {
          days.push(date);
          playersPlayed.push(1);
          gamesPlayed.push(gamesCount);
          uniquePlayersPerDay.set(date, new Set([studentId]));
        } else {
          const uniquePlayers = uniquePlayersPerDay.get(date);
          if (!uniquePlayers.has(studentId)) {
            playersPlayed[existingIndex]++;
            uniquePlayers.add(studentId);
          }
          gamesPlayed[existingIndex] += gamesCount;
        }
      });
    }
  });
  // Function to extract date from different date-time string formats
  const extractDate = dateTimeString => {
    // Regular expression to match different date-time formats
    const regex = /(\d{2})[\/,-](\d{2})[\/,-](\d{4}).*$/;
    const match = dateTimeString.match(regex);
    if (match) {
      const day = match[1];
      const month = match[2];
      const year = match[3];
      return `${year}/${month}/${day}`;
    } else {
      return null; // Return null if the format doesn't match
    }
  };

  // Combine dates with players played and games played
  const combinedData = days.map((dateTime, index) => ({
    date: extractDate(dateTime),
    playersPlayed: playersPlayed[index],
    gamesPlayed: gamesPlayed[index]
  }));

  // Group combined data by date and calculate total players played and games played for each date
  const groupedData = combinedData.reduce((acc, curr) => {
    const { date, playersPlayed, gamesPlayed } = curr;
    if (!date) return acc; // Skip if date format is invalid
    if (!acc[date]) {
      acc[date] = { date, totalPlayersPlayed: 0, totalGamesPlayed: 0 };
    }
    acc[date].totalPlayersPlayed += playersPlayed;
    acc[date].totalGamesPlayed += gamesPlayed;
    return acc;
  }, {});

  // Sort grouped data by date in ascending order
  const sortedDates = Object.keys(groupedData).sort((a, b) => {
    const dateA = new Date(a);
    const dateB = new Date(b);
    if (dateA.getMonth() !== dateB.getMonth()) {
      return dateA.getMonth() - dateB.getMonth();
    }
    return dateA.getDate() - dateB.getDate();
  });

  // Extract sorted players played and games played
  const sortedPlayersPlayed = sortedDates.map(date => groupedData[date].totalPlayersPlayed);
  const sortedGamesPlayed = sortedDates.map(date => groupedData[date].totalGamesPlayed);

  // selecters range for dates start and end in chart 
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [filteredDates, setFilteredDates] = useState([]);
  const [filteredPlayersPlayed, setFilteredPlayersPlayed] = useState([]);
  const [filteredGamesPlayed, setFilteredGamesPlayed] = useState([]);
  const initialEffectRan = useRef(false);

  useEffect(() => {
    if (!initialEffectRan.current && sortedDates.length > 0) {
      setStartDate(sortedDates[0]);
      setEndDate(sortedDates[sortedDates.length - 1]);
      setFilteredDates(sortedDates);
      setFilteredPlayersPlayed(sortedPlayersPlayed);
      setFilteredGamesPlayed(sortedGamesPlayed);
      initialEffectRan.current = true; // Mark as ran
    }
  }, [sortedDates, sortedPlayersPlayed, sortedGamesPlayed])

  useEffect(() => {
    if (initialEffectRan.current) {
      filterData();
    }
  }, [startDate, endDate]);

  const filterData = () => {
    const startIndex = sortedDates.indexOf(startDate);
    const endIndex = sortedDates.indexOf(endDate);

    if (startIndex !== -1 && endIndex !== -1 && startIndex <= endIndex) {
      const newDates = sortedDates.slice(startIndex, endIndex + 1);
      const newPlayersPlayed = sortedPlayersPlayed.slice(startIndex, endIndex + 1);
      const newGamesPlayed = sortedGamesPlayed.slice(startIndex, endIndex + 1);

      setFilteredDates(newDates);
      setFilteredPlayersPlayed(newPlayersPlayed);
      setFilteredGamesPlayed(newGamesPlayed);
    }
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const course_id = queryParams.get('course_id');

  // Grid and List view icon toggle
  const [currentImageGrid, setCurrentImageGrid] = useState(GridIconOn);
  const toggleImageView = () => {
    setCurrentImageGrid((prevImage) => (prevImage === GridIconOff ? GridIconOn : GridIconOff));
  };



  // search players
  const [filteredStudent, setfilteredStudent] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);

    const lowercasedSearchTerm = e.target.value.toLowerCase();

    const filteredLists = SelectedCourse.students.filter((students) => {
      return students.firstName.toLowerCase().includes(lowercasedSearchTerm);
    });

    setfilteredStudent(filteredLists);
  };

  // Variable renderings Analytics, player, activities, journey
  const [selectedVariable, setSelectedVariable] = useState(1);
  const variables = [
    // Course OverView
    {
      id: 1, Name: 'Overview', data: () =>
        <div className='sm:mx-20 mx-5'>
          <div className='my-5'>
            {/* <input type="text" id="datePicker" className="datepicker border" autocomplete="off" placeholder="undefined-undefined" style={{ textAlign: 'center', marginLeft: '10px' }} /> */}
            {loading ? (
              <div>
                <div className='sm:mx-20 mx-5'>
                  <div className=''>
                    <Skeleton className='my-3' animation="wave" variant="rounded" width={200} height={50} />
                  </div>
                  <div className='flex gap-2 sm:hidden'>
                    <Skeleton animation="wave" variant="rectangular" width={10} height={200} />
                    <Skeleton animation="wave" variant="rectangular" width='100%' height={200} />
                    <Skeleton animation="wave" variant="rectangular" width={10} height={200} />
                  </div>
                 <div className='hidden sm:block'>
                 <div className='flex gap-2'>
                    <Skeleton animation="wave" variant="rectangular" width={10} height={500} />
                    <Skeleton animation="wave" variant="rectangular" width='100%' height={500} />
                    <Skeleton animation="wave" variant="rectangular" width={10} height={500} />
                  </div>
                 </div>
                  <div className='mt-2 mx-5'>
                    <Skeleton animation="wave" variant="rectangular" width='100%' height={10} />
                  </div>
                  <div className=' grid-cols-4 mt-5'>
                    <div className='flex justify-center'>
                      <div className="relative">
                        <Skeleton animation='wave' variant="rounded" width={200} height={200} />
                        <div className="flex justify-center">
                          <Skeleton className="absolute bottom-3" animation='wave' variant="rounded" width={90} height={30} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            ) : (
              sortedDates.length > 0 ? <div>
                <div>
                  {/* <input
                    type="text"
                    className="border py-3 px-10 w-64 "
                    value={`${sortedDates.length > 0 ? (() => { const parts = sortedDates.sort()[0].substring(0, 10).replace(/[-,]/g, '/').split('/'); return `${parts[2]}/${parts[1]}/${parts[0]}`; })() : ''} - ${sortedDates.length > 0 ? (() => { const parts = sortedDates.sort()[sortedDates.length - 1].substring(0, 10).replace(/[-,]/g, '/').split('/'); return `${parts[2]}/${parts[1]}/${parts[0]}`; })() : ''}`}
                    name=""
                    id=""
                  /> */}
                  {/* slecte srat and end dates  */}
                  <div className='flex border py-3 px-5 w-64'>
                    <div>
                      <select
                        className="no-arrow"
                        id="start-date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      >
                        {sortedDates.map((date) => {
                          const [year, month, day] = date.split('/');
                          return (
                            <option key={date} value={date}>
                              {`${day}/${month}/${year}`}
                            </option>
                          );
                        })}
                      </select>
                    </div> -

                    <div>
                      <select
                        id="end-date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      >
                        {sortedDates.map((date) => {
                          const [year, month, day] = date.split('/');
                          return (
                            <option key={date} value={date}>
                              {`${day}/${month}/${year}`}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <BarChart
                    days={filteredDates.map(date => {
                      const parts = date.split(' ')[0].substring(0, 10).replace(/[-,]/g, '/').split('/');
                      return `${parts[2]}/${parts[1]}/${parts[0]}`;
                    })}
                    gamesPlayed={filteredGamesPlayed} players={filteredPlayersPlayed} loading={loading} />
                  <div className=' grid-cols-4 mt-5'>
                    <div className='flex justify-center'>
                      <div className='relative'>
                        <p className='flex justify-center absolute inset-x-0 top-2 sm:text-xl'><b>Accurancy</b></p>
                        <img className='' src={ChartAccurancy} alt="" />
                        <p className='flex justify-center absolute inset-x-0 bottom-6 sm:text-xl'>
                          <b>{CourseAnalysis.correctAnswers > 0 ? <>{((CourseAnalysis.correctAnswers / (CourseAnalysis.correctAnswers + CourseAnalysis.incorrectAnswers)) * 100).toFixed(2)}</> : "0.00"}</b>%
                        </p>
                      </div>
                    </div>
                    {/* <div className='relative'>
              <p className='flex justify-center absolute inset-x-0 top-4 text-xl'><b>Hardest Questions</b></p>
              <img className='w-full' src={ChartHardest} alt="" />
              <p className='flex justify-center absolute inset-x-0 bottom-8 text-xl'>Top  <b>20</b></p>
            </div>
            <div className='relative'>
              <p className='flex justify-center absolute inset-x-0 top-4 text-xl'><b>Easiest Quesyions</b></p>
              <img className='w-full' src={ChartEasiest} alt="" />
              <p className='flex justify-center absolute inset-x-0 bottom-8 text-xl'>Top <b> 10</b></p>
            </div>
            <div className='relative'>
              <p className='flex justify-center absolute inset-x-0 top-4 text-xl'><b>Complished</b></p>
              <img className='w-full' src={ChartComplished} alt="" />
              <p className='flex justify-center absolute inset-x-0 bottom-8 text-xl'><b>60</b>%</p>
            </div> */}
                  </div>
                </div>

              </div> : <div className='flex justify-center'>
                <div> <p className="text-xl font-bold flex justify-center">No players played yet  </p>
                  <p className='flex justify-center'><img src={sadDog} alt="" /></p>
                </div>
              </div>
            )}

          </div>
        </div>
    },
    // Players Of course
    {
      id: 2, Name: 'Players', data: () =>
        <div>
          <div className='flex my-5 px-3'>
            <p className='text-xl'>Players Analysis</p>
          </div>
            <PlayersAnalysis selectedCourse={SelectedCourse} viewType={currentImageGrid} gridOn={GridIconOn} />
        </div>
    },
    // Journeys of course
    {
      id: 3, Name: 'Journey', data: () =>
        <div>
          {/* List view Stages */}
          <div>
            <div className='flex my-5 px-3'>
              <p className='text-xl'>Journey</p>
            </div>
            {loading ? (
              <div class="relative mx-2 sm:mx-5 mt-5 overflow-x-auto max-w-80 sm:max-w-full">
                <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead class="text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" class="px-3 py-3">
                        Name
                      </th>
                      <th scope="col" class="px-3 py-3">
                        Activities
                      </th>
                      <th scope="col" class="px-3 py-3">
                        Date Created
                      </th>
                      <th scope="col" class="">
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className='border-b'>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></td>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={50} height={40} /></td>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></td>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={50} height={40} /></td>
                    </tr>
                    <tr className='border-b'>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></td>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={50} height={40} /></td>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></td>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={50} height={40} /></td>
                    </tr>
                    <tr className='border-b'>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></td>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={50} height={40} /></td>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></td>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={50} height={40} /></td>
                    </tr>
                    <tr className='border-b'>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></td>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={50} height={40} /></td>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></td>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={50} height={40} /></td>
                    </tr>

                  </tbody>
                </table>
              </div>
            ) : (
              <div class="relative mx-2 sm:mx-5 mt-5 overflow-x-auto max-w-80 sm:max-w-full">
                <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead class="text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" class="px-3 py-3">
                        Name
                      </th>
                      <th scope="col" class="px-3 py-3">
                        Activities
                      </th>
                      <th scope="col" class="px-3 py-3">
                        Date Created
                      </th>
                      <th scope="col" class="">
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {SelectedCourse?.stages.map((stage, stageIndex) => (
                      <tr class="even:bg-white even:dark:bg-gray-900 odd:bg-gray-50 odd:dark:bg-gray-800 border-b dark:border-gray-700">
                        <th scope="row" class="px-3 py-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          {stage.name}
                        </th>
                        <td class="px-3 py-6">
                          {stage.activities.length}
                        </td>
                        <td class="px-3 py-6">
                          {stage.startingDate}
                        </td>
                        <td class="px-3 py-6">
                          <div className="flex justify-center">
                            <div className="flex justify-center gap-2  p-2" style={{ width: '120px', borderRadius: '8px' }}>
                              <span><img src={DeleteIcon} alt="" /></span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}

                  </tbody>
                </table>
              </div>)}
          </div>
        </div>
    },
    // Actiities of Course
    {
      id: 4, Name: 'Activities', data: () =>
        <div>
          <div>
            <div className='flex my-5 px-3'>
              <p className='text-xl'>Activities</p>
            </div>
            {loading ? (
              <div class="relative ms-2 sm:mx-5 mt-5 overflow-x-auto max-w-80 sm:max-w-full">
                <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead class="text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" class="px-3 py-3">
                        Name
                      </th>
                      <th scope="col" class="px-3 py-3">
                        Type
                      </th>
                      <th scope="col" class="px-3 py-3">
                        Stage
                      </th>
                      <th scope="col" class="px-3 py-3">
                        Created by
                      </th>
                      <th scope="col" class="px-3 py-3">
                        Date Created
                      </th>
                      <th scope="col" class="px-3 py-3">
                        Last Modified
                      </th>
                      <th scope="col" class="px-3 py-3">
                        Last Modified By
                      </th>
                      <th scope="col" class="">
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className='border-b'>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></td>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></td>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={50} height={40} /></td>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></td>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></td>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></td>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></td>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={50} height={40} /></td>
                    </tr>
                    <tr className='border-b'>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></td>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></td>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={50} height={40} /></td>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></td>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></td>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></td>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></td>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={50} height={40} /></td>
                    </tr>
                    <tr className='border-b'>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></td>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></td>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={50} height={40} /></td>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></td>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></td>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></td>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></td>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={50} height={40} /></td>
                    </tr>
                    <tr className='border-b'>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></td>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></td>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={50} height={40} /></td>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></td>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></td>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></td>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={100} height={40} /></td>
                      <td class="px-3 py-6"><Skeleton animation='wave' variant="rounded" width={50} height={40} /></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <div class="relative ms-2 sm:mx-5 mt-5 overflow-x-auto max-w-80 sm:max-w-full">
                <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead class="text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" class="px-3 py-3">
                        Name
                      </th>
                      <th scope="col" class="px-3 py-3">
                        Type
                      </th>
                      <th scope="col" class="px-3 py-3">
                        Stage
                      </th>
                      <th scope="col" class="px-3 py-3">
                        Created by
                      </th>
                      <th scope="col" class="px-3 py-3">
                        Date Created
                      </th>
                      <th scope="col" class="px-3 py-3">
                        Last Modified
                      </th>
                      <th scope="col" class="px-3 py-3">
                        Last Modified By
                      </th>
                      <th scope="col" class="">
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {SelectedCourse?.stages.map((stage, stageIndex) => (
                      stage.activities.map((activity) => (
                        <tr class="even:bg-white even:dark:bg-gray-900 odd:bg-gray-50 odd:dark:bg-gray-800 border-b dark:border-gray-700">
                          <th scope="row" class="px-3 py-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            {activity.name}
                          </th>
                          <td class="px-3 py-6">
                            {activity.activityType}
                          </td>
                          <td class="px-3 py-6">
                            {stageIndex + 1}
                          </td>
                          <td class="px-3 py-6">
                            {activity.createdBy}
                          </td>
                          <td class="px-3 py-6">
                            {activity.dateCreated}
                          </td>
                          <td class="px-3 py-6">
                            {activity.lastModified !== null ? activity.lastModified : 'Not modified yet'}
                          </td>
                          <td class="px-3 py-6">
                            {activity.lastModified !== null ? activity.createdBy : 'Not modified yet'}
                          </td>
                          <td class="px-3 py-6">
                            <div className="flex justify-center">
                              <div className="flex justify-center gap-2  p-2" style={{ width: '120px', borderRadius: '8px' }}>
                                <span><img src={DeleteIcon} alt="" /></span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))))}

                  </tbody>
                </table>
              </div>)}
          </div>

        </div>
    },
    // Rewards of Course
    // {
    //   id: 5, Name: 'Rewards', data: () =>
    //     <div><p>Rewards you collected</p></div>
    // },
  ];

  const handleButtonClick = (id) => {
    // Set the selected variable when a button is clicked
    // setSelectedVariable(variables.find((variable) => variable.id === id));
    setSelectedVariable(id);
  };


  return (
    <div className='w-full'>
      <MetaTags title={`Analytics of the ${SelectedCourse?.name}`} description='Gamizign Analytics page--' />
      <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg '>
        <h3 className="text-xl pe-10"><b>{SelectedCourse?.name}</b>/ Course Analytics</h3>
        <LoginHeading />
      </div>
      <div className="m-7  pb-5 bg-white border border-2 ">
        <div className='flex justify-center static'><img className='absolute top-6' src={AnalyticnHIcon} alt="" /></div>
        <div>
          <div className='bg-gray-100 p-2 text-xl  border-b-2 sm:flex '>
            {variables.map((variable, index) => (
              <button
                key={variable.id}
                onClick={() => handleButtonClick(variable.id)}
                // style={{ margin: '5px' }}
                className={`sm:m-2 ${selectedVariable === variable.id ? "font-bold" : ""}`}
              >
                {variable.Name} <b>{variables.length - 1 === index ? '' : '|'}</b>
              </button>
            ))}
            <div className="flex gap-3 sm:absolute right-16 mt-2.5">
            <Tooltip title="Grid View"><img className='cursor-pointer'
                src={currentImageGrid}
                alt='Grid View'
                onClick={toggleImageView}
              /></Tooltip>
              <Tooltip title='Table View'> <img className='cursor-pointer' src={currentImageGrid === GridIconOff ? ListIconOn : ListIconOff} alt="3" onClick={toggleImageView} /></Tooltip>
            </div>
          </div>
          <div>
            <div className=''>
              {variables.find((variable) => variable.id === selectedVariable)?.data()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseAnalytics;
