import React, { useState } from "react";
import FileNametIcon from '../../../../../assets/icons/ActivityContentIcons/NewCourse/Creating/File icon.png';
import SubmiBtntIcon from '../../../../../assets/icons/ActivityContentIcons/NewCourse/Creating/Submit icon.png';
import RadioOnIcon from '../../../../../assets/icons/ActivityContentIcons/NewCourse/Creating/radio button on.png';
import RadioOffIcon from '../../../../../assets/icons/ActivityContentIcons/NewCourse/Creating/radio button off.png';
import MagniferIcon from '../../../../../assets/icons/ActivityContentIcons/NewCourse/Creating/magnifer icon.png';
import CheckOffIcon from '../../../../../assets/icons/ActivityContentIcons/NewCourse/Creating/PPTX check box off.png';
import CheckOnIcon from '../../../../../assets/icons/ActivityContentIcons/NewCourse/Creating/PPTX check box on.png';
import LoginHeading from "../../../../LoginHeading/LoginHeading";
import LoadingComponent from "../../../../GlobalComponents/GlobalLoading";
import { Alert, Button, Dialog, DialogContent, Skeleton } from "@mui/material";


const ReUseAblePptx = ({ selectedFile, submitSlides, selectedDifficulty, questionsRange, toggleImages, toggleZoom, toggleSelectAll, images, isConverting, isGenerating, isDisabled, selectAll, imageBaseUrl, EmptySlice, setEmptySlice, ErrorMesg, setIsConverting, setOnboarding }) => {
    const [selectedButton, setSelectedButton] = useState('Easy');
    const [selectedOption, setSelectedOption] = useState('5 to 10');
    const [betaMessage, setBetaMessage] = useState(true);

    const handleButtonClick = (value) => {
       if (!isGenerating) {
        if (value === "Easy")
            selectedDifficulty = "Medium & Hard";
        if (value === "Medium")
            selectedDifficulty = "Easy & Hard";
        if (value === "Hard")
            selectedDifficulty = "Easy & Medium";
        setSelectedButton(value);
       }
    };
    const handleOptionChange = (value) => {
        if (!isGenerating) {
            setSelectedOption(value);
            questionsRange = value;
        };
    };
    const handleCloseDialog = () => {
        setEmptySlice(false);
    };
    {images.length > 0 && (Promise.all(
        images.map(image => {
          return new Promise(resolve => {
            const img = new Image();
            img.src = imageBaseUrl + `${image.path.substring(image.path.lastIndexOf("/") + 1)}`;
            img.onload = resolve;
          });
        })
      ).then(() => {
        setIsConverting(false);
      }))}
    return (
        <div className="w-full">
            {betaMessage && <Alert severity='info' onClose={() => setBetaMessage(false)}>
                AI Generative questions for <span className="font-bold">Arabic</span> language is in Beta version.</Alert>}
            <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'>
                <h3 className="text-xl">Create New Activity</h3>
                <LoginHeading objects={setOnboarding} />
            </div>
            <div className="m-7 bg-white contentSideCewating">
                {/* dialog empty slides submit */}
                <Dialog className="lg:ms-56" open={EmptySlice} onClose={handleCloseDialog}>
                    <DialogContent>
                        <Alert severity='error'>{ErrorMesg}</Alert>
                    </DialogContent>
                    {ErrorMesg === 'An error occured in generating questions! try again.' ?
                        <div className='flex justify-center'>
                            <Button onClick={() => { submitSlides() }} color="primary">
                                Try Again
                            </Button>
                            <Button onClick={() => { handleCloseDialog() }} color="primary">
                                Okay
                            </Button>
                        </div> :
                        <div className='flex justify-center'>
                            <Button onClick={() => { handleCloseDialog() }} color="primary">
                                Okay
                            </Button>
                        </div>}
                </Dialog>
                {/* progress line */}
                <div>
                    <ol className="flex items-center pt-5 px-2 sm:px-10 w-full text-sm font-medium text-center text-gray-500 dark:text-gray-400 sm:text-base">
                        <li className="flex w-full items-center text-green-600 dark:text-blue-500 sm:after:content-[''] after:w-full after:border-green-200 after:border sm:after:inline-block   dark:after:border-gray-700">
                            <span className="flex items-center after:text-gray-200 dark:after:text-gray-500">
                                <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                <b>Adding</b>
                            </span>
                        </li>
                        <li className="flex w-full items-center text-pink-300 after:content-[''] after:w-full after:border-blue-200 after:border sm:after:inline-block   dark:after:border-gray-700">
                            <span className="flex items-center after:text-gray-200 dark:after:text-gray-500">
                                <b>Creating</b>
                            </span>
                        </li>
                        <li className="flex w-full items-center after:content-[''] after:w-full after:border-blue-200 after:border sm:after:inline-block   dark:after:border-gray-700">
                            <span className="flex items-center after:text-gray-200 dark:after:text-gray-500">
                                <b>Customizing</b>
                            </span>
                        </li>
                        <li className="flex items-center">
                            <b>Saving</b>
                        </li>
                    </ol>
                </div>
                <div className="App lg:px-20">
                    <div className="sm:flex mt-5">
                        <button className="border FixedWidth170 flex mx-2 px-3 py-2 rounded filName flex-none"><img className="mr-3 mt-1" src={FileNametIcon} alt="pic" />File Name</button>
                        <p className="mt-2 ms-3 text-sky-600 flex-1">{selectedFile.name}</p>
                    </div>
                    <div className="flex space-x-4 mt-5">
                        <div id="QType" className="DIFFICULTIES flex-1">
                            <button
                                className={`border FixedWidth170 m-2 py-2 px-5 ${selectedButton === 'Hard' ? 'bg-white' : 'bg-green-50'} text-black rounded`}
                                onClick={() => handleButtonClick('Hard')}
                            >
                                <b>Hard </b>Questions
                            </button>
                            <button
                                className={`border FixedWidth170 m-2 py-2 px-5 ${selectedButton === 'Normal' ? 'bg-white' : 'bg-green-50'} text-black rounded`}
                                onClick={() => handleButtonClick('Normal')}
                            >
                                <b>Normal </b>Questions
                            </button>
                            <button
                                className={`border FixedWidth170 m-2 py-2 px-5 ${selectedButton === 'Easy' ? 'bg-white' : 'bg-green-50'} text-black rounded`}
                                onClick={() => handleButtonClick('Easy')}
                            >
                                <b>Easy </b>Questions
                            </button>
                        </div>
                        <div id="QRange" className="QUESTIONS-COUNT flex-initial w-100 py-2 md:flex">
                            <div className="image-radio flex m-2 hover:cursor-pointer" onClick={() => handleOptionChange('5 to 10')}>
                                <span>
                                    <img
                                        src={selectedOption === '5 to 10' ? RadioOnIcon : RadioOffIcon}
                                        alt="5 to 10"
                                        className="radio-image mt-1"
                                    />
                                </span>
                                <span className={`ps-2 ${selectedOption === '5 to 10' ? 'font-bold' : 'font-light'}`}>5 to 10</span>
                            </div>
                            <div className="image-radio flex m-2 hover:cursor-pointer" onClick={() => handleOptionChange('10 to 20')}>
                                <span>
                                    <img
                                        src={selectedOption === '10 to 20' ? RadioOnIcon : RadioOffIcon}
                                        alt="10 to 20"
                                        className="radio-image mt-1"
                                    />
                                </span>
                                <span className={`ps-2 ${selectedOption === '10 to 20' ? 'font-bold' : 'font-light'}`}>10 to 20</span>
                            </div>
                            <div className="image-radio flex m-2 hover:cursor-pointer" onClick={() => handleOptionChange('20 to 30')}>
                                <span>
                                    <img
                                        src={selectedOption === '20 to 30' ? RadioOnIcon : RadioOffIcon}
                                        alt="20 to 30"
                                        className="radio-image mt-1"
                                    />
                                </span>
                                <span className={`ps-2 ${selectedOption === '20 to 30' ? 'font-bold' : 'font-light'}`}>20 to 30</span>
                            </div>
                        </div>
                    </div>
                    <div className="file-input">
                    </div>
                    {isConverting ? (
                        <div className="image-container">
                            <div className="my-5 ms-2 flex gap-5">
                                <Skeleton animation="wave" variant="rectangular" width={40} height={40} />
                                <Skeleton animation="wave" variant="rounded" width={130} height={40} />
                            </div>

                            <div className="grid px-2 2xl:grid-cols-3 xl:grid-cols-2 grid-cols-1 gap-5 flex justify-center">
                                <div className="image-wrapper flex justify-center">
                                    <Skeleton animation="wave" variant="rounded" width={'70%'} height={235} />
                                </div>
                                <div className="image-wrapper w-full flex justify-center">
                                    <Skeleton animation="wave" variant="rounded" width={'70%'} height={235} />
                                </div>
                                <div className="image-wrapper w-full flex justify-center">
                                    <Skeleton animation="wave" variant="rounded" width={'70%'} height={235} />
                                </div>
                                <div className="image-wrapper w-full flex justify-center">
                                    <Skeleton animation="wave" variant="rounded" width={'70%'} height={235} />
                                </div>
                                <div className="image-wrapper w-full flex justify-center">
                                    <Skeleton animation="wave" variant="rounded" width={'70%'} height={235} />
                                </div>
                                <div className="image-wrapper w-full flex justify-center">
                                    <Skeleton animation="wave" variant="rounded" width={'70%'} height={235} />
                                </div>
                                <div className="image-wrapper w-full flex justify-center">
                                    <Skeleton animation="wave" variant="rounded" width={'70%'} height={235} />
                                </div>
                                <div className="image-wrapper w-full flex justify-center">
                                    <Skeleton animation="wave" variant="rounded" width={'70%'} height={235} />
                                </div>
                                <div className="image-wrapper w-full flex justify-center">
                                    <Skeleton animation="wave" variant="rounded" width={'70%'} height={235} />
                                </div>
                            </div>
                        </div>
                    ):
                      (<>
                            {images.length > 0 && (
                                <div className="image-container">
                                    <div className="my-5 ms-2">
                                        <button id="selectAllSlides"
                                            className="text-xl flex items-center"
                                            onClick={toggleSelectAll}
                                        >
                                            <input
                                                className="mt-1 mr-2 m-auto p-2 "
                                                type="checkbox"
                                                style={{ width: "40px", height: "40px" }}
                                                name=""
                                                id=""
                                                checked={selectAll}
                                            />
                                            Select All Slides
                                        </button>
                                    </div>

                                    <div id="slides" className="grid px-2 2xl:grid-cols-3 xl:grid-cols-2 gap-5 flex justify-center">
                                        {images.map((image, index) => (
                                            <div key={index} className="image-wrapper">
                                                <label className="image-label relative flex justify-center" onClick={() => toggleImages(index)}>
                                                    <span className={`overlay ${image.zoomed ? "visible" : ""}`}></span>
                                                    <span className="magnifier" onClick={(e) => { e.stopPropagation(); toggleZoom(index) }}>
                                                        <span role="img" aria-label="Zoom In">
                                                            <img className="absolute left-40 -bottom-2" src={MagniferIcon} alt="" />
                                                        </span>
                                                    </span>
                                                    <img
                                                        className="hover:scale-95 "
                                                        src={imageBaseUrl + `${image.path.substring(image.path.lastIndexOf("/") + 1)}`}
                                                        alt={`${index}`}
                                                        style={{
                                                            width: image.zoomed ? "100%" : "75%",
                                                            marginRight: index % 3 === 2 ? "0" : "10px",
                                                            cursor: "pointer",
                                                        }}
                                                    />
                                                    <img
                                                        className="absolute left-40 top-5"
                                                        src={isDisabled ? image.checked ? CheckOnIcon : CheckOffIcon : CheckOffIcon}
                                                        alt=""
                                                        style={{ width: '40px', height: '40px', cursor: 'pointer', transition: 'transform 0.3s ease-in-out' }}
                                                        onClick={(e) => { e.stopPropagation(); toggleImages(index); }}
                                                    />
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>)}
                        </>)}

                    {/*Pptx code End */}
                </div>
                {isGenerating ? (<LoadingComponent />) :
                    (<div className="flex justify-center mt-10">
                        {isConverting ? <Skeleton className="mb-5" animation="wave" variant="rounded" width={120} height={40} /> :
                            <button id="submitslide" className="border flex px-3 py-2 mb-5 rounded hover:bg-blue-200 filSubmit" onClick={submitSlides}>
                                <img className="mr-3 mt-1" src={SubmiBtntIcon} alt="pic" />
                                <b>Submit</b>
                            </button>}
                    </div>)}
            </div>
        </div>
    );
};

export default ReUseAblePptx;