import React, { useEffect, useState } from "react";
import QListDashboardIcon from '../../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/Question Lists dashboard icon.png'
import PdfICon from '../../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/pdf icon.png'
import GMZLogo from '../../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/Gamizign-logo.png'
import BackBtn from '../../../../../../assets/icons//ActivityContentIcons/QuestionListsPatch5/back iconB.png'

import { Link, useLocation } from "react-router-dom";
import LoginHeading from "../../../../../LoginHeading/LoginHeading";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Alert, Dialog, DialogContent, Skeleton } from "@mui/material";
import MetaTags from "../../../../../GlobalComponents/MetaData";
import Joyride from "react-joyride";
import axiosInstance from "../../../../../../axios";
pdfMake.vfs = pdfFonts.pdfMake.vfs;


const PdfDownloadQuestionList = () => {
  const location = useLocation();
  const {Qlist} = location.state || "";
  const queryParams = new URLSearchParams(location.search);
  const questionList_id = queryParams.get('questionList_id');
  const [selectedQuestions, setSelectedQuestions] = useState(null);
    // OnBoarding
    const [Onboarding, setOnboarding] = useState(parseInt(localStorage.getItem("accountActive")));
    const [{ run, steps }, setState] = useState({
      run: true,
      steps: [
          {
              content: <h2>Kindly drag and drop</h2>,
              placement: 'left',
              target: '#im',
          },
          {
              content: <h2>Question list name that you want download.</h2>,
              placement: 'bottom',
              target: '#QLname',
          },
          {
              content: <h2>Download Questions with all answers.</h2>,
              placement: 'top',
              target: '#GenQuiz',
          },
          {
              content: <h2>Here you can download question with right answers only.</h2>,
              placement: 'top',
              target: '#GenRight',
          },
  
      ]
  })
  
  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_ENDPOINT}/question-lists/${questionList_id}`);
        // const data = await response.json();

        setSelectedQuestions(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [questionList_id]);
  if (!selectedQuestions) {
    return <div>
      <div>
        <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'>
          <h3 className="text-xl">Question Lists</h3>
          <LoginHeading objects={setOnboarding} />
        </div>
        <div className="sm:m-7 my-7 bg-white">
          <div className='flex justify-center static'><img className='absolute top-6' src={QListDashboardIcon} alt="" /></div>
          <div className="px-5 py-10">
            <div className="sm:flex py-2 relative px-3" style={{ borderBottom: "2px gray solid" }}>
              <Skeleton animation="wave" variant="rounded" width={100} height={30} />
              <div className="flex gap-3 sm:absolute right-3">
                <p className="mt-5 sm:mt-0"><Skeleton animation="wave" variant="rounded" width={200} height={30} /></p>
              </div>
            </div>
            {/* Course Chapters Here */}
            <div className="my-2 bg-gray-100 w-full pt-10" style={{ borderRadius: '10px', border: '1px gray solid', height: '500px' }}>
              <div className="  sm:flex justify-center sm:mt-40 gap-5" >
                <div className="flex justify-center mt-10 px-2 sm:mt-0">
                  <Skeleton animation="wave" variant="rounded" width={250} height={60} />
                </div>
                <div className="flex justify-center mt-10 px-2 sm:mt-0">
                  <Skeleton animation="wave" variant="rounded" width={250} height={60} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>;
  }
// Adding Cairo and Roboto fonts
pdfMake.fonts = {
  Cairo: {
    normal: 'https://fonts.gstatic.com/s/cairo/v20/SLXVc1nY6HkvangtZmpcWmhzfH5lWWgsQQ.ttf',
    bold: 'https://fonts.gstatic.com/s/cairo/v20/SLXVc1nY6HkvangtZmpcWmhzfH5lWWgsQQ.ttf',
  },
  Roboto: {
    normal: 'Roboto-Italic.ttf',
    bold: 'Roboto-Italic.ttf',
  },
};

const generatePDFQuiz = () => {
  if (!selectedQuestions || !selectedQuestions.questions) {
    console.error('Selected questions data is not available.');
    return;
  }

  const isArabic = (text) => /[\u0600-\u06FF]/.test(text);

  const shuffleOptions = (options) => {
    for (let i = options.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [options[i], options[j]] = [options[j], options[i]];
    }
    return options;
  };

  const formatQuestionOrAnswer = (text, level, isQuestion = false, index = null) => {
    const arabicText = isArabic(text);
    const alignment = arabicText ? 'right' : 'left';
    const font = arabicText ? 'Cairo' : 'Roboto';
    const formattedText = isQuestion
      ? (arabicText ? `${text.split(" ").reverse().join(" ")} .${index + 1}` : `${index + 1}. ${text}`)
      : arabicText ? (text.split(" ").reverse().join(" ")) : text;

    return {
      margin: arabicText ? [0, 0, level * 10, 2] : [level * 10, 0, 0, 2],
      text: formattedText,
      alignment,
      font,
    };
  };

  const docDefinition = {
    content: [
      {
        text: isArabic(selectedQuestions.name) ? selectedQuestions.name.split(" ").reverse().join(" "): selectedQuestions.name,
        style: 'header',
        alignment: isArabic(selectedQuestions.name) ? 'right' : 'left',
        font: isArabic(selectedQuestions.name) ? 'Cairo' : 'Roboto',
      },
      {
        text: `Created By: ${isArabic(selectedQuestions.createdBy) ?selectedQuestions.createdBy.split(" ").reverse().join(" "): selectedQuestions.createdBy }`,
        style: 'createdDate',
        alignment: isArabic(selectedQuestions.name) ? 'right' : 'left',
        font: isArabic(selectedQuestions.createdBy) ? 'Cairo' : 'Roboto',
      },
      {
        text: `Date Created: ${selectedQuestions.dateCreated}`,
        style: 'createdDate',
        alignment: isArabic(selectedQuestions.name) ? 'right' : 'left', 
        font: 'Roboto',
      },
      { text: '\n' },
      {
        text: isArabic(selectedQuestions.name) ? ('الاختيار من متعدد').split(" ").reverse().join(" ") : `Multiple Choice`,
        style: 'MultipleChoice',
        alignment: isArabic(selectedQuestions.name) ? 'right' : 'left',
        font: isArabic(selectedQuestions.name) ? 'Cairo' : 'Roboto',
      },
      ...selectedQuestions.questions.flatMap((question, index) => {
        // Shuffle the answers
        const shuffledOptions = shuffleOptions([
          { label: '✇', text: question.answer1 || '' },
          { label: '✇', text: question.answer2 || '' },
          { label: '✇', text: question.answer3 || '' },
          { label: '✇', text: question.answer4 || '' },
        ]);

        return [
          formatQuestionOrAnswer(question.question, 1, true, index),
          ...shuffledOptions.map((option, i) =>
            formatQuestionOrAnswer(
              isArabic(option.text) ? `${option.label}    ${option.text}` : `${option.label}  ${option.text}`, 2
            )
          ),
          { text: '\n' },
        ];
      }),
    ],
    styles: {
      header: { fontSize: 18, bold: true },
      createdDate: { fontSize: 10, bold: true },
      MultipleChoice: { fontSize: 14, bold: true, margin: [0, 0, 0, 10] },
      question: { fontSize: 14, margin: [10, 10, 0, 5] },
      answer: { fontSize: 15, margin: [20, 0, 0, 2] },
    },
    defaultStyle: { font: 'Roboto', alignment: 'left' },
    background: function (currentPage, pageSize) {
      return [
        {
          image: GMZLogo,
          width: 150,
          height: 150,
          opacity: 0.5,
          alignment: 'center',
          absolutePosition: { x: 0, y: 300 },
        },
        {
          text: 'The AI ultimate gamification platform',
          fontSize: 30,
          color: 'gray',
          opacity: 0.2,
          bold: true,
          alignment: 'center',
          absolutePosition: { x: 0, y: 430 },
        },
      ];
    },
  };

  pdfMake.createPdf(docDefinition).download(`${selectedQuestions.name} Quiz.pdf`);
};



  // Generate and download only Right answer form
  const generatePDFRightAnswers = () => {
    if (!selectedQuestions || !selectedQuestions.questions) {
      console.error('Selected questions data is not available.');
      return;
    }
  
    // Function to detect Arabic characters
    const isArabic = (text) => /[\u0600-\u06FF]/.test(text);
  
    // Helper to format text with appropriate font and alignment
    const formatText = (text) => ({
      text: text,
      font: isArabic(text) ? 'Cairo' : 'Roboto',
      alignment: isArabic(text) ? 'right' : 'right'
    });
  
    const docDefinition = {
      content: [
        formatText(selectedQuestions.name),
        formatText(`Created By: ${selectedQuestions.createdBy}`),
        { text: `Date Created: ${selectedQuestions.dateCreated}`, style: 'CreatedDate', alignment: isArabic(selectedQuestions.name) ? 'right' : 'left', },
        { text: '\n' },
        {
          table: {
            body:isArabic( selectedQuestions.name) ? [
              [{ text: formatText('إجابة') } , { text: formatText('سؤال') }, { text: formatText('مسلسل' )}],
              ...selectedQuestions.questions.map((question, index) => [
                formatText(question.answer1 || ''),
                formatText(question.question),
                { text: index + 1 },
              ])
            ] : [
              [{ text: 'Sr#' }, { text: 'Question' }, { text: 'Answer' }],
              ...selectedQuestions.questions.map((question, index) => [
                { text: index + 1},
                formatText(question.question),
                formatText(question.answer1 || '')
              ])
            ]
          }
        }
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true
        },
        CreatedDate: {
          fontSize: 10,
          bold: true
        }
      },
      background: function (currentPage, pageSize) {
        return [
          {
            image: GMZLogo,
            width: 150,
            height: 150,
            opacity: 0.5,
            alignment: 'center',
            absolutePosition: { x: 0, y: 300 },
          },
          {
            text: 'The AI ultimate gamification platform',
            fontSize: 30,
            color: 'gray',
            opacity: 0.2,
            bold: true,
            alignment: 'center',
            absolutePosition: { x: 0, y: 430 },
          }
        ];
      }
    };
  
    pdfMake.createPdf(docDefinition).download(`${selectedQuestions.name} RightAnswers.pdf`);
  };


  return (
    <div className='w-full'>
      <MetaTags title={`Download PDF of the ${selectedQuestions?.name}`} description='Gamizign Download QuestionS List page--' />
      <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'>
        <h3 className="text-xl">Question Lists</h3>
        <LoginHeading objects={setOnboarding} ActualValue={Qlist} />
      </div>
      <div className="sm:m-7 m-3 my-7 bg-white">
      {selectedQuestions && Onboarding === 1  &&<Joyride callback={() => { }} continuous showProgress
                    run={run} steps={steps} hideCloseButton scrollToFirstStep showSkipButton
                    styles={{
                        buttonNext: { backgroundColor: '#E5F2E3', borderRadius: '5px',color:'black', },
                        buttonBack: { backgroundColor: '#E5F2E3',  borderRadius: '5px', color:'black', }
                      }} />}
        <div className='flex justify-center static'><img className='absolute top-6' src={QListDashboardIcon} alt="" /></div>
        {selectedQuestions.instructor_id === parseInt(localStorage.getItem("instructor_id")) ? (
        <div className="px-5 py-10">
          <div className="sm:flex py-2 relative px-3" style={{ borderBottom: "2px gray solid" }}>
            <p><b>Name</b></p>
            <div id="QLname" className="flex gap-3 sm:absolute right-3">
              <p><b>{selectedQuestions.name}</b></p>
            </div>
          </div>
          {/* Course Chapters Here */}
          <div className="my-2 bg-gray-100 w-full pt-10" style={{ borderRadius: '10px', border: '1px gray solid', height: '500px' }}>
            <div className="  sm:flex justify-center sm:mt-40 gap-5" >
              {/* Right answers here */}
              <div className="flex flex justify-center">
                <button id="GenQuiz" onClick={() => { generatePDFQuiz() }} className="flex border p-5 bg-white font-bold" style={{ borderRadius: '11px', width: '250px' }}>
                  <img className="mr-3" src={PdfICon} alt="quiz" />Quiz form</button>
              </div>
              <div className="flex justify-center mt-10 px-2 sm:mt-0">
                <button id="GenRight" onClick={generatePDFRightAnswers} className="flex border p-5 bg-white font-bold" style={{ borderRadius: '11px', width: '250px' }}>
                  <img className="mr-3" src={PdfICon} alt="rightAnswers" />Right Answers form</button>
              </div>
            </div>
          </div>
        </div>
        ):(
            <div className="px-5 py-10">
              <Dialog className="lg:ms-56" open><DialogContent><Alert severity="error">
              <p className="text-center text-2xl font-bold text-red-500">Sory you can't download the another Instructor's question list.</p>
              </Alert>
              <div className="flex justify-center">
                <Link to="/Dashboard/questionList" className='BackBtn flex p-2 px-7 hover:bg-blue-200 mt-4'>
                  <b>Back to yours questions lits</b>
                  <img className='mt-1 ms-2' src={BackBtn} alt="Back" />
                </Link></div>
              </DialogContent></Dialog>
            </div>
        )}
      </div>
    </div>
  )
};

export default PdfDownloadQuestionList;
