import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import '../style.css'
import ReUseAblePptx from "../ReUseablePptx";
import MetaTags from "../../../../../GlobalComponents/MetaData";
import Joyride from "react-joyride";
import axiosInstance from "../../../../../../axios";

function CreatingPptx() {
  const [file, setFile] = useState();
  const [images, setImages] = useState([]);
  const [isConverting, setIsConverting] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const imageBaseUrl = `${process.env.REACT_APP_ENDPOINT}/tmp?file=`;
  const location = useLocation();
  const { selectedFile } = useLocation().state;
  const [slides, setSlides] = useState([]);
  const [EmptySlice, setEmptySlice] = useState(false);
  const [ErrorMesg, setErrorMesg] = useState('');

  const navigate = useNavigate();
  const {courses, courseType, course, stageIndex, courseFormatDate, stratingDate, finishingDate } = useLocation().state;



  useEffect(() => {
    // Access the state from the previous page
    const { selectedFile } = location.state || {};
    if (selectedFile) {
      setFile(selectedFile);
    }
  }, [location.state]);

  // useEffect to log the value of file whenever it changes
  useEffect(() => {
    if (file) {
      // Call handleConversion here if needed
      handleConversion();
    }
  }, [file]);

  const handleConversion = async () => {
    setIsConverting(true);
    // setMessage("Conversion and image extraction are in progress...");
    // setProgress(0);
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await axiosInstance.postForm(`${process.env.REACT_APP_ENDPOINT}/convert/`, formData, {
        onUploadProgress: (progressEvent) => {
          // const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          // setProgress(progress);
        },
      });         

      if (response.data.images) {
        // setMessage("Conversion complete. Images available for download.");
        setImages(response.data.images.map((image) => ({ path: image, zoomed: false })));
        // setIsConverting(false);
      } else {
        // setMessage("An error occurred during conversion. Please try again.");
      }
    } catch (error) {
      // setMessage("An error occurred during conversion. Please try again.");
    } finally {
      // setIsConverting(false);
    }
  };
  const toggleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setImages(images.map((image) => ({ ...image, checked: newSelectAll })));
    if (newSelectAll) {
      const indices = images.map((_, index) => index);
      setSlides(indices);
    } else {
      setSlides([]);
    }

  };


  const toggleZoom = (index) => {
    const newImages = [...images];
    newImages[index].zoomed = !newImages[index].zoomed;
    // newImages[index].checked = false; // Ensure checkbox is not checked
    setImages(newImages);
  };

  const toggleImages = (index) => {
    const newImages = [...images];
    newImages[index].checked = !newImages[index].checked;
    setImages(newImages);
    slides.push(index);
  };

  const submitSlides = async () => {
    setEmptySlice(false);
    if (slides.length > 0 && file != null) {
      try {
        setIsGenerating(true);
        setIsDisabled(false);
        const formData = new FormData();
        formData.append("file", file);
        formData.append("slides", JSON.stringify(slides));
        formData.append("difficulty_level", JSON.stringify(selectedDifficulty));
        formData.append("range", JSON.stringify(questionsRange));
        formData.append("course_type", JSON.stringify(course.mainTopic));
        const response = await axiosInstance.postForm(`${process.env.REACT_APP_ENDPOINT}/question_ppt_chat_gpt/`, formData, {

        });
        if (response.data) {
          setIsGenerating(false);
          setIsDisabled(true);
          var questions = response.data;
          navigate('/Dashboard/customizing', { state: {courses:courses, courseType: courseType, course: course, questions: questions, selectedFile: selectedFile, stageIndex: stageIndex, courseFormatDate: courseFormatDate, stratingDate: stratingDate, finishingDate: finishingDate } });
        }

        else {
          setIsGenerating(false);
          setIsDisabled(false);
          console.log('Error in fetching questions');
        }
      }
      catch (error) {
        setIsGenerating(false);
        setIsDisabled(false);
        console.error('Fetch error:', error);
        setEmptySlice(true);
        setErrorMesg('An error occured in generating questions! try again.')
      }
    } else {
      setEmptySlice(true);
      setErrorMesg('You must select one slide before submit');
    }
  };

  // Here is Question Type and Range Handles
  var selectedDifficulty = "Medium & Hard";
  var questionsRange = "5 to 10";
  // onboarding
  // const [Onboarding, setOnboarding] = useState(courses.length);
  const [Onboarding, setOnboarding] = useState(parseInt(localStorage.getItem("accountActive")));
  const [{ run, steps }, setState] = useState({
    run: true,
    steps: [
      {
        content: <h2>Select.</h2>,
        placement: 'left',
        target: '#Qt',
        title: 'Questions Difficulty'
      },
      {
        content: <h2>Want to challenge your students, select how much difficulty you want your questions to be Green colored is selected.</h2>,
        placement: 'left',
        target: '#QType',
        title: 'Questions Difficulty'
      },
      {
        content: <h2>Selcet question range how may question do you want generate by default range is 5 to 10.</h2>,
        placement: 'left',
        target: '#QRange',
        title: 'Question range'
      },
      {
        content: <h2>Select all slides if you want generate questions from all slides.</h2>,
        placement: 'bottom',
        target: '#selectAllSlides',
        title: 'Select all slides'
      },
      {
        content: <h2>Select the slides you want to generate questions from by clicking the checkbox.</h2>,
        placement: 'left',
        target: '#slides',
        title: 'Select slides manually'
      },
      {
        content: <h2>Click submit to generate questions from your selected slides and wait till generating.</h2>,
        placement: 'top',
        target: '#submitslide',
        title: 'Generate questions'
      },

    ]
  })



  return (
    <div>
      <MetaTags title='Upload Slides' description='Gamizign Upload Slides page--' />
      {!isConverting && Onboarding <= 1  && <Joyride callback={() => { }} continuous showProgress
        run={run} steps={steps} hideCloseButton scrollToFirstStep showSkipButton
        styles={{
          buttonNext: { backgroundColor: '#E5F2E3', borderRadius: '5px',color:'black', },
          buttonBack: { backgroundColor: '#E5F2E3',  borderRadius: '5px', color:'black', }
        }} />}
      <ReUseAblePptx selectedFile={selectedFile} selectedDifficulty={selectedDifficulty} questionsRange={questionsRange}
        submitSlides={submitSlides} toggleImages={toggleImages} toggleZoom={toggleZoom} toggleSelectAll={toggleSelectAll}
        images={images} isConverting={isConverting} isGenerating={isGenerating} isDisabled={isDisabled} selectAll={selectAll} setOnboarding={setOnboarding}
        imageBaseUrl={imageBaseUrl} EmptySlice={EmptySlice} setEmptySlice={setEmptySlice} ErrorMesg={ErrorMesg} setIsConverting={setIsConverting}
      />

    </div>
  );
}

export default CreatingPptx;
