import { Tooltip } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import QuestionVolume from '../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/questions volume icon.png'
import GradVolume from '../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/grade volume icon.png'
import PdfICon from '../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/pdf icon.png'
import EditQuestoin from '../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/edit icon.png'
import DeletQuestoin from '../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/delete icon.png'

const GroupQuestionLists = ({ filteredQuestionLists, setDynamicMessage, openCurseBotModal, setQuestionListName, setQuestionListId, DownloadQlist }) => {
    const QuestionsListsColors = ['#DBE6F5', '#E5F2E3', '#F5F9E6', '#FEFAE2', '#FEF7E2', '#FEF1DD', '#FDE4DC', '#EFE1EE'];
    // Initialize arrays for each mainTopic
    const languageCourses = [];
    const mathCourses = [];
    const scienceCourses = [];
    const socialCourses = [];
    const Unknown = [];

    // Iterate through filteredCourses and sort into respective arrays
    if (filteredQuestionLists && filteredQuestionLists.length > 0) {
        filteredQuestionLists.forEach(questionsList => {
            switch (questionsList.mainTopic) {
                case 'Language Arts':
                    languageCourses.push(questionsList);
                    break;
                case 'Math':
                    mathCourses.push(questionsList);
                    break;
                case 'Science':
                    scienceCourses.push(questionsList);
                    break;
                case 'Social':
                    socialCourses.push(questionsList);
                    break;
                default:
                    Unknown.push(questionsList)
                    // Handle case if mainTopic does not match any expected value
                    console.warn(`Unexpected mainTopic: ${questionsList.mainTopic}`);
                    break;
            }
        });
    }
    return (
        <div>
            {/* Language Arts Question lists */}
            <div>
                <p className="border-b-2 mx-7 px-2 text-2xl pt-10 flex justify-center sm:justify-start font-bold">Language arts</p>
                <div className={`flex flex-wrap gap-4 px-5 justify-center grid grid-cols-1 lg:grid-cols-3 sm:grid-cols-2`}>
                    {languageCourses.length > 0 ? (
                        languageCourses.map((questionsList, QuestionsListsIndex) => (
                            <div className="bg-gray-100 flex px-5 justify-center my-3 hover:scale-95 cursor-pointer shadow-xl" style={{ backgroundColor: QuestionsListsColors[QuestionsListsIndex % QuestionsListsColors.length], borderRadius: '10px', }}>
                                <div className="py-3 relative">
                                    <h1 className="font-bold  sm:px-6 py-1">{questionsList.name}</h1>
                                    <div className="flex justify-center my-2 gap-8">
                                        <p className="flex"><span><img className="mt-1.5 mr-2" src={QuestionVolume} alt="q" /></span>{questionsList.questions.length}</p>
                                        <p className="flex"><span><img className="mt-1.5 mr-2" src={GradVolume} alt="q" /></span> </p>
                                    </div>
                                    <div className="flex justify-center">
                                        <div className="flex justify-center gap-2 bg-white p-2 border absolute -bottom-6" style={{ width: '120px', borderRadius: '8px' }}>
                                            <Tooltip title="Download"><img onClick={()=>{DownloadQlist(questionsList.questionList_id, 'download')}} src={PdfICon} alt="pdf" /></Tooltip>
                                            <Tooltip title="Edit"><img onClick={()=>{DownloadQlist(questionsList.questionList_id, 'edit')}} src={EditQuestoin} alt="edit" /></Tooltip>
                                            <Tooltip title="Delete"> <img className="cursor-pointer" src={DeletQuestoin} alt="delete" onClick={() => { setDynamicMessage('Are you sure you want to Delete the questions list'); openCurseBotModal(); setQuestionListName(questionsList.name); setQuestionListId(questionsList.questionList_id) }} /></Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="text-gray-500 ps-2">No questions lists available in this topic.</div>)}
                </div>
            </div>
            {/* Math Questions lits*/}
            <div>
                <p className="border-b-2 mx-7 px-2 text-2xl pt-10 flex justify-center sm:justify-start font-bold">Math</p>
                <div className={`flex flex-wrap gap-4 px-5 justify-center grid grid-cols-1 lg:grid-cols-3 sm:grid-cols-2`}>
                    {mathCourses.length > 0 ? (
                        mathCourses.map((questionsList, QuestionsListsIndex) => (
                            <div className="bg-gray-100 flex px-5 justify-center my-3 hover:scale-95 cursor-pointer shadow-xl" style={{ backgroundColor: QuestionsListsColors[QuestionsListsIndex % QuestionsListsColors.length], borderRadius: '10px', }}>
                                <div className="py-3 relative">
                                    <h1 className="font-bold  sm:px-6 py-1">{questionsList.name}</h1>
                                    <div className="flex justify-center my-2 gap-8">
                                        <p className="flex"><span><img className="mt-1.5 mr-2" src={QuestionVolume} alt="q" /></span>{questionsList.questions.length}</p>
                                        <p className="flex"><span><img className="mt-1.5 mr-2" src={GradVolume} alt="q" /></span> </p>
                                    </div>
                                    <div className="flex justify-center">
                                        <div className="flex justify-center gap-2 bg-white p-2 border absolute -bottom-6" style={{ width: '120px', borderRadius: '8px' }}>
                                        <Tooltip title="Download"><img onClick={()=>{DownloadQlist(questionsList.questionList_id, 'download')}} src={PdfICon} alt="pdf" /></Tooltip>
                                        <Tooltip title="Edit"><img onClick={()=>{DownloadQlist(questionsList.questionList_id, 'edit')}} src={EditQuestoin} alt="edit" /></Tooltip>
                                            <Tooltip title="Delete"> <img className="cursor-pointer" src={DeletQuestoin} alt="delete" onClick={() => { setDynamicMessage('Are you sure you want to Delete the questions list'); openCurseBotModal(); setQuestionListName(questionsList.name); setQuestionListId(questionsList.questionList_id) }} /></Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="text-gray-500 ps-2">No questions lists available in this topic.</div>)}
                </div>
            </div>
            {/* Science Qestions lists */}
            <div>
                <p className="border-b-2 mx-7 px-2 text-2xl pt-10 flex justify-center sm:justify-start font-bold">Science</p>
                <div className={`flex flex-wrap gap-4 px-5 justify-center grid grid-cols-1 lg:grid-cols-3 sm:grid-cols-2`}>
                    {scienceCourses.length > 0 ? (
                        scienceCourses.map((questionsList, QuestionsListsIndex) => (
                            <div className="bg-gray-100 flex px-5 justify-center my-3 hover:scale-95 cursor-pointer shadow-xl" style={{ backgroundColor: QuestionsListsColors[QuestionsListsIndex % QuestionsListsColors.length], borderRadius: '10px', }}>
                                <div className="py-3 relative">
                                    <h1 className="font-bold  sm:px-6 py-1">{questionsList.name}</h1>
                                    <div className="flex justify-center my-2 gap-8">
                                        <p className="flex"><span><img className="mt-1.5 mr-2" src={QuestionVolume} alt="q" /></span>{questionsList.questions.length}</p>
                                        <p className="flex"><span><img className="mt-1.5 mr-2" src={GradVolume} alt="q" /></span> </p>
                                    </div>
                                    <div className="flex justify-center">
                                        <div className="flex justify-center gap-2 bg-white p-2 border absolute -bottom-6" style={{ width: '120px', borderRadius: '8px' }}>
                                        <Tooltip title="Download"><img onClick={()=>{DownloadQlist(questionsList.questionList_id, 'download')}} src={PdfICon} alt="pdf" /></Tooltip>
                                        <Tooltip title="Edit"><img onClick={()=>{DownloadQlist(questionsList.questionList_id, 'edit')}} src={EditQuestoin} alt="edit" /></Tooltip>
                                            <Tooltip title="Delete"> <img className="cursor-pointer" src={DeletQuestoin} alt="delete" onClick={() => { setDynamicMessage('Are you sure you want to Delete the questions list'); openCurseBotModal(); setQuestionListName(questionsList.name); setQuestionListId(questionsList.questionList_id) }} /></Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="text-gray-500 ps-2">No questions lists available in this topic.</div>)}
                </div>
            </div>
            {/* Social study Qestions lists */}
            <div>
                <p className="border-b-2 mx-7 px-2 text-2xl pt-10 flex justify-center sm:justify-start font-bold">Social</p>
                <div className={`flex flex-wrap gap-4 px-5 justify-center grid grid-cols-1 lg:grid-cols-3 sm:grid-cols-2`}>
                    {socialCourses.length > 0 ? (
                        socialCourses.map((questionsList, QuestionsListsIndex) => (
                            <div className="bg-gray-100 flex px-5 justify-center my-3 hover:scale-95 cursor-pointer shadow-xl" style={{ backgroundColor: QuestionsListsColors[QuestionsListsIndex % QuestionsListsColors.length], borderRadius: '10px', }}>
                                <div className="py-3 relative">
                                    <h1 className="font-bold  sm:px-6 py-1">{questionsList.name}</h1>
                                    <div className="flex justify-center my-2 gap-8">
                                        <p className="flex"><span><img className="mt-1.5 mr-2" src={QuestionVolume} alt="q" /></span>{questionsList.questions.length}</p>
                                        <p className="flex"><span><img className="mt-1.5 mr-2" src={GradVolume} alt="q" /></span> </p>
                                    </div>
                                    <div className="flex justify-center">
                                        <div className="flex justify-center gap-2 bg-white p-2 border absolute -bottom-6" style={{ width: '120px', borderRadius: '8px' }}>
                                        <Tooltip title="Download"><img onClick={()=>{DownloadQlist(questionsList.questionList_id, 'download')}} src={PdfICon} alt="pdf" /></Tooltip>
                                        <Tooltip title="Edit"><img onClick={()=>{DownloadQlist(questionsList.questionList_id, 'edit')}} src={EditQuestoin} alt="edit" /></Tooltip>
                                            <Tooltip title="Delete"> <img className="cursor-pointer" src={DeletQuestoin} alt="delete" onClick={() => { setDynamicMessage('Are you sure you want to Delete the questions list'); openCurseBotModal(); setQuestionListName(questionsList.name); setQuestionListId(questionsList.questionList_id) }} /></Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="text-gray-500 ps-2">No questions lists available in this topic.</div>)}
                </div>
            </div>
            {/* No topic Questions lists */}
            <div>
                <p className="border-b-2 mx-7 px-2 text-2xl pt-10 flex justify-center sm:justify-start font-bold">No topic Questions lists</p>
                <div className={`flex flex-wrap gap-4 px-5 justify-center grid grid-cols-1 lg:grid-cols-3 sm:grid-cols-2`}>
                    {Unknown.length > 0 ? (
                        Unknown.map((questionsList, QuestionsListsIndex) => (
                            <div className="bg-gray-100 flex px-5 justify-center my-3 hover:scale-95 cursor-pointer shadow-xl" style={{ backgroundColor: QuestionsListsColors[QuestionsListsIndex % QuestionsListsColors.length], borderRadius: '10px', }}>
                                <div className="py-3 relative">
                                    <h1 className="font-bold  sm:px-6 py-1">{questionsList.name}</h1>
                                    <div className="flex justify-center my-2 gap-8">
                                        <p className="flex"><span><img className="mt-1.5 mr-2" src={QuestionVolume} alt="q" /></span>{questionsList.questions.length}</p>
                                        <p className="flex"><span><img className="mt-1.5 mr-2" src={GradVolume} alt="q" /></span> </p>
                                    </div>
                                    <div className="flex justify-center">
                                        <div className="flex justify-center gap-2 bg-white p-2 border absolute -bottom-6" style={{ width: '120px', borderRadius: '8px' }}>
                                        <Tooltip title="Download"><img onClick={()=>{DownloadQlist(questionsList.questionList_id, 'download')}} src={PdfICon} alt="pdf" /></Tooltip>
                                        <Tooltip title="Edit"><img onClick={()=>{DownloadQlist(questionsList.questionList_id, 'edit')}} src={EditQuestoin} alt="edit" /></Tooltip>
                                            <Tooltip title="Delete"> <img className="cursor-pointer" src={DeletQuestoin} alt="delete" onClick={() => { setDynamicMessage('Are you sure you want to Delete the questions list'); openCurseBotModal(); setQuestionListName(questionsList.name); setQuestionListId(questionsList.questionList_id) }} /></Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="text-gray-500 ps-2">No questions lists available without topic.</div>)}
                </div>
            </div>
        </div>
    );
};

export default GroupQuestionLists;