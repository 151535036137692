import React, { useState } from "react";
import GMZLogo from '../../../assets/images/SigninPageImages/Gamizign-logo.png'
import AItag from '../../../assets/images/Login & Signing art assets/AI ASSISTED icon.png'
import Arcadtag from '../../../assets/images/Login & Signing art assets/Arcade icon.png'
import Image from '../../../assets/images/Features-trans-Background.png'
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Slider, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FAQ from "./FAQuestions";
import CloseIcon from '@mui/icons-material/Close';
import ServicePlans from "./ServicePlanSchool";
import ServicePlanIndividual from "./ServicePlanIndividual";


const BuyPlan = ({setPlan, Plan}) => {
    // const [Plan, setPlan] = useState('Individual')
    const [License, setLicense] = useState(5);
    const [ProLicense, setProLicense] = useState(5);
    const navigate = useNavigate();
    const CreateAcoount = () => {
        navigate('/', { state: { CreateAccount: true } });
    }
    // Tooltips/deatil of Features
    const [index, setIndex] = useState(0);
    const [featureDialog, setFeatureDialog] = useState(false);
    const [features, setFeatures] = useState([
        { name: 'Flash cards', details: 'Effective educational tool allows learners to test their knowledge & reinforce memory retention.' },
        { name: 'Timing challenge', details: 'A dynamic learning experience that fosters competition, enhances engagement & achieves quiz better results.' },
        { name: 'Leaderboard', details: 'A visual dynamic feature combining competition with learning, immediate feedback & collecting valuable insights.' },
        { name: 'MCQ Questions', details: 'Multiple-choice questions for an effective & engaging way to assess knowledge.' },
        { name: 'Basic Dashboard insights', details: 'Essential tools for visualizing & interpreting data, to monitor KPIs & gain various basic insights.' },
        { name: 'Spin2Win games', details: 'A modern & engaging spin learning game that resembles roulette betting games.' },
        { name: 'Achievements', details: 'Dashboard that monitors student progress, level of competition with others & digital badge score.' },
        { name: 'Live emojis', details: 'Integrates live emojis to enrich the learning experience, making it more engaging, fun, & visually appealing.' },
        { name: 'Export dashboard to CSV', details: 'Export your dashboard data to a CSV format for easier analysis, opening, and reviewing on other platforms.' },
        { name: 'Notification service', details: 'Designed to deliver timely & relevant information to users across channels such as SMS & push notifications.' },
        { name: 'Arcade Games', details: 'Arcade-style learning combines gaming excitement with educational goals to foster deeper learning experiences.' },
        { name: 'Chat', details: 'Chat service enables instant conversations, allowing users to interact with each other & their instructors.' },
        { name: 'AI Questions Generation', details: 'Enables instant creation of engaging quizzes in games & valuable content by artificial intelligence.' },
        { name: 'Export questions to PDF', details: 'Provides a way to create a physical or digital copy of your quiz to share with students.' },
        { name: 'Reorder/Essay/Image Questions', details: 'Different types of questions for assessments, each serving a unique purpose.' },
        { name: 'URL/Image/PDF activities', details: 'Activities catering to various learning styles, enhancing engagement & learning outcomes.' },
        { name: 'Add student by CSV file', details: 'Easily add students (Add in bulk quantity) by importing an excel file.' },
        { name: 'Play2Win Store', details: 'A rewarding system with redeem points for physical & digital gifts to enhance learner loyalty & engagement.' },
        { name: 'Audio/Video Activities', details: 'Incorporating audio & video in e-learning for greater engagement & retention.' },
        { name: 'Advance Dashboard GMZ world', details: 'Aggregates & visualizes data on course progress, user performance, allowing improved learning outcomes.' },
        { name: 'Customized badges', details: 'Allows creation of unique rewards from templates or new badge designs from scratch.' },
        { name: 'Multiplayer', details: 'Integrates gaming & social interaction, allowing learners to work & play together.' },
        { name: 'Single-courses', details: 'Create standalone lessons with a single stage but many activity options.' },
        { name: 'Journey-courses', details: 'Enhanced course creation with multiple stages, activities, PDFs, images, videos, audio files & URLs.' },
        { name: 'Question lists', details: 'A centralized hub to access, edit, manage & review all questions bank.' },
        { name: 'Learners', details: 'Defines player limits for various Gamizign plans.' },
      ]); 
      const handleNext = () => {
        if (index < features.length - 1) {
            setIndex(index + 1);
        }
    };
    const handlePrevious = () => {
        if (index > 0) {
            setIndex(index - 1);
        }
    }; 

    return (
        <div>
            <Dialog open={featureDialog} onClose={() => { setFeatureDialog(false) }}>
                <h1 className="text-2xl my-2 font-bold text-center">{features[index].name}</h1>
                <IconButton
                    aria-label="close"
                    onClick={() => { setFeatureDialog(false) }}
                    sx={(theme) => ({
                        position: 'absolute',
                        right:{xs:-1, sm:8},
                        top: {xs:3, sm:8},
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <p className="text-xl ">{features[index].details}</p>
                    <div className="flex justify-center mt-2">
                        <Button disabled={index === 0} onClick={handlePrevious}>Preview</Button>
                        <Button disabled={index === features.length - 1} onClick={handleNext}>Next</Button>
                    </div>
                </DialogContent>
            </Dialog>
            <div className="px-5">
                <div className="flex justify-center"><img src={GMZLogo} width={150} alt="LOGO" /></div>
                {Plan === 'Individual' ? <div>
                    <h1 className="text-center text-2xl font-bold">Elevate Your Classroom Results with Gamizign</h1>
                    <p className="text-center md:text-xl">With Gamizign's arcade games and custom course creation capabilities with AI-powered question generation, you can tailor lessons to meet the <br /> specific needs of your learners, ensuring that no learner is left behind</p>
                    <p className="text-center font-bold md:text-xl">Close Learning Gaps and Foster Academic Growth</p>
                </div> : Plan === 'Team & School' ? <div>
                    <h1 className="text-center text-2xl font-bold">Unlock Educational Excellence for Your Entire Institution with Gamizign</h1>
                    <p className="text-center md:text-xl">Invest in a platform that breathes new life into schools, fostering a dynamic and engaging learning environment.</p>
                    <p className="text-center font-bold md:text-xl">Bring the Gamizign experience to every physical and virtual classroom!</p>
                </div> : <div>
                    <h1 className="text-center text-2xl">Gamizign District</h1>
                    <p className="text-center">A site-wide license tailored to give your entire educational institution access to Gamizign</p>
                </div>}
                {/* Buy offers cards */}
                <div>
                    <div className="text-center mt-5 font-bold drop-shadow-xl">
                        <button onClick={() => setPlan('Individual')} className={`border sm:w-48 w-full mt-3 p-3 rounded ${Plan === 'Individual' ? 'text-white bg-orange-600' : 'bg-white hover:bg-cyan-600 hover:text-white'}`}>Individual</button>
                        {/* <button onClick={() => setPlan('Team & School')} className={`border sm:w-48 w-full mt-3 p-3 sm:mx-5 rounded ${Plan === 'Team & School' ? 'text-white bg-orange-600' : 'bg-white hover:bg-cyan-600 hover:text-white'}`}>Team & School</button> */}
                        {/* <button onClick={() => setPlan('District')} className={`border sm:w-48 w-full mt-3 p-3 rounded bg-white ${Plan === 'District' ? 'text-white bg-orange-600' : 'hover:bg-cyan-600 hover:text-white'}`}>District</button> */}
                    </div>
                    {/* Services plans */}
                    <div>
                    {Plan === 'Individual' ? <ServicePlanIndividual/> : <ServicePlans />}
                    </div>
                    <div>
                        {Plan === 'Individual' ? (
                            // Individual cards and detail
                            <div className=" mt-3 flex justify-center xl:mx-10">
                                <div className="bg-gray-200 flex overflow-x-auto gap-5 drop-shadow-xl p-2 pb-5">
                                    {/* Features and Limits */}
                                    <div className="mt-5 bg-white rounded-xl shadow-xl" style={{ backgroundImage: `url(${Image})`, backgroundPosition: 'top', backgroundRepeat: 'no-repeat', backgroundSize: '100%' }}>
                                        <div className="w-80">
                                            {/* <div className="flex justify-center text-center"><h1 className="p-20 font-bold text-5xl">Features and Limits</h1></div> */}
                                            <ul className="mb-10 text-xl" style={{ marginTop: '469px' }}>
                                                <li className="border-t p-3 font-bold">Features</li>
                                                {features.map((feature, index)=>(
                                                 <Tooltip arrow title='View Detail'><li onClick={()=>{setFeatureDialog(true);setIndex(index)}} className="border-t p-3 sm:ps-5 relative cursor-pointer">{feature.name}<span className="absolute sm:right-5 right-2 border font-bold">?</span></li></Tooltip>
                                                ))}
                                            </ul>

                                        </div>
                                    </div>
                                    {/* Free plan */}
                                    <div className="mt-5 bg-white rounded-xl shadow-xl">
                                        <div className="text-center w-60">
                                            <div className="flex justify-center bg-red-400 text-white font-bold py-2 rounded-t-xl w-full" style={{ backgroundColor: '#ACCCE5' }}>
                                                <p className="flex"><span className="py-1.5">Demo</span></p>
                                            </div>
                                            <div className="flex justify-center"><h1 className="pt-8 font-bold text-xl w-44">Gamizign free for teachers </h1></div>
                                            <div className="flex justify-center"><p className="flex mt-7"><span className="text-5xl font-bold">FREE</span></p></div>
                                            <p className="flex justify-center text-xl py-2 bg-gray-200 rounded px-3" style={{ marginTop: '117px' }}><span><Groups2OutlinedIcon /></span><span className="ms-2 mt-0.5">Up to 20 learners</span></p>
                                            <button onClick={CreateAcoount} className="bg-cyan-600 hover:bg-orange-600 text-white rounded px-10 py-3 mt-5">Start now</button>
                                            <ul className="mt-6 text-xl">
                                                <li className="border-t p-3 font-bold" style={{ padding: '26px' }}></li>
                                                <li className="border-t p-3 ps-5">✔</li>
                                                <li className="border-t p-3 ps-5">✔</li>
                                                <li className="border-t p-3 ps-5">✔</li>
                                                <li className="border-t p-3 ps-5">✔</li>
                                                <li className="border-t p-3 ps-5">✔</li>
                                                <li className="border-t p-3 ps-5 text-red-500">✘</li>
                                                <li className="border-t p-3 ps-5 text-red-500">✘</li>
                                                <li className="border-t p-3 ps-5 text-red-500">✘</li>
                                                <li className="border-t p-3 ps-5 text-red-500">✘</li>
                                                <li className="border-t p-3 ps-5 text-red-500">✘</li>
                                                <li className="border-t p-3 ps-5 text-red-500">✘</li>
                                                <li className="border-t p-3 ps-5 text-red-500">✘</li>
                                                <li className="border-t p-3 ps-5 text-red-500">✘</li>
                                                <li className="border-t p-3 ps-5 text-red-500">✘</li>
                                                <li className="border-t p-3 ps-5 text-red-500">✘</li>
                                                <li className="border-t p-3 ps-5 text-red-500">✘</li>
                                                <li className="border-t p-3 ps-5 text-red-500">✘</li>
                                                <li className="border-t p-3 ps-5 text-red-500">✘</li>
                                                <li className="border-t p-3 ps-5 text-red-500">✘</li>
                                                <li className="border-t p-3 ps-5 text-red-500">✘</li>
                                                <li className="border-t p-3 ps-5 text-red-500">✘</li>
                                                <li className="border-t p-3 ps-5 text-red-500">✘</li>
                                                <li className="border-t p-3 ps-5">2</li>
                                                <li className="border-t p-3 ps-5 text-red-500">✘</li>
                                                <li className="border-t p-3 ps-5">2</li>
                                                <li className="border-y p-3 ps-5">20</li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* Basic plan */}
                                    <div className="mt-5 bg-white rounded-xl shadow-xl">
                                        <div className="text-center w-60">
                                            <div className="flex justify-center text-white font-bold py-2 rounded-t-xl w-full" style={{ backgroundColor: '#BCE2BB' }}>
                                                <p className="flex"><span className="py-1.5">For Limited</span></p>
                                            </div>
                                            <div className="flex justify-center"><h1 className="pt-8 font-bold text-xl w-44">Gamizign Basic for teachers </h1></div>
                                            <div className="flex justify-center"><p className="flex mt-7"><del className="text-orange-600 mt-3 text-2xl">6.99$</del>&nbsp;<span className="text-5xl font-bold">3.99$</span></p></div>
                                            <span>Per teacher per month</span>
                                            <p className="mt-7"><del className="text-orange-600">83.88</del>$,<span className="text-orange-600 text-xl ms-2">45</span>$ (save more on) <br />billed annually</p>
                                            <p className="flex justify-center text-xl py-2 bg-gray-200 rounded px-3 mt-3"><span><Groups2OutlinedIcon /></span><span className="ms-2 mt-0.5">Up to 50 learners</span></p>
                                            <button className="bg-cyan-600 hover:bg-orange-600 text-white rounded px-10 py-3 mt-5">Buy now</button>
                                            <ul className="mt-6 text-xl">
                                                <li className="border-t p-3 font-bold" style={{ padding: '26px' }}></li>
                                                <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                <li className="border-t p-3 ps-5">6</li>
                                                <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                <li className="border-t p-3 ps-5 text-red-500">✘</li>
                                                <li className="border-t p-3 ps-5 text-red-500">✘</li>
                                                <li className="border-t p-3 ps-5 text-red-500">✘</li>
                                                <li className="border-t p-3 ps-5 text-red-500">✘</li>
                                                <li className="border-t p-3 ps-5 text-red-500">✘</li>
                                                <li className="border-t p-3 ps-5 text-red-500">✘</li>
                                                <li className="border-t p-3 ps-5 text-red-500">✘</li>
                                                <li className="border-t p-3 ps-5 text-red-500">✘</li>
                                                <li className="border-t p-3 ps-5 text-red-500">✘</li>
                                                <li className="border-t p-3 ps-5 text-red-500">✘</li>
                                                <li className="border-t p-3 ps-5 text-red-500">✘</li>
                                                <li className="border-t p-3 ps-5 text-red-500">✘</li>
                                                <li className="border-t p-3 ps-5">3</li>
                                                <li className="border-t p-3 ps-5 text-red-500">✘</li>
                                                <li className="border-t p-3 ps-5">5</li>
                                                <li className="border-y p-3 ps-5">50</li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* Standard plan */}
                                    <div className="mt-5 bg-white rounded-xl shadow-xl relative">
                                        <img className="absolute top-6 -right-4" src={AItag} alt="AI-tag" />
                                        <img className="absolute top-24 -right-4" src={Arcadtag} alt="Arcad-tag" />
                                        <div className="text-center w-60">
                                            <div className="flex justify-center text-white font-bold py-2 rounded-t-xl w-full" style={{ backgroundColor: '#F6C1C1' }}>
                                                <p className="flex"><span className="bg-white  py-1 px-2 rounded-full mx-2" style={{ color: '#F6C1C1' }}>✔</span><span className="mt-1">Most popular</span></p>
                                            </div>
                                            <div>
                                                <div className="flex justify-center"><h1 className="pt-8 font-bold text-xl w-44">Gamizign Standard for teachers </h1></div>
                                                <div className="flex justify-center"><p className="flex mt-8"><del className="text-orange-600 mt-3 text-2xl">14.99$</del>&nbsp;<span className="text-5xl font-bold">11.99$</span></p></div>
                                                <span>Per teacher per month</span>
                                                <p className="mt-7"><del className="text-orange-600">179.88</del>$,<span className="text-orange-600 text-xl ms-2">140</span>$ (save more on) billed annually</p>
                                                <p className="flex justify-center text-xl py-2 bg-gray-200 rounded px-3 mt-3"><span><Groups2OutlinedIcon /></span><span className="ms-2 mt-0.5">Up to 100 learners</span></p>
                                                <button className="bg-cyan-600 hover:bg-orange-600 text-white rounded px-10 py-3 mt-5">Buy now</button>
                                                <ul className="mt-6 text-xl">
                                                    <li className="border-t p-3 font-bold" style={{ padding: '26px' }}></li>
                                                    <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                    <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                    <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                    <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                    <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                    <li className="border-t p-3 ps-5">12</li>
                                                    <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                    <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                    <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                    <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                    <li className="border-t p-3 ps-5">5</li>
                                                    <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                    <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                    <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                    <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                    <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                    <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                    <li className="border-t p-3 ps-5 text-red-500">✘</li>
                                                    <li className="border-t p-3 ps-5 text-red-500">✘</li>
                                                    <li className="border-t p-3 ps-5 text-red-500">✘</li>
                                                    <li className="border-t p-3 ps-5 text-red-500">✘</li>
                                                    <li className="border-t p-3 ps-5 text-red-500">✘</li>
                                                    <li className="border-t p-3 ps-5">10</li>
                                                    <li className="border-t p-3 ps-5">3</li>
                                                    <li className="border-t p-3 ps-5">10</li>
                                                    <li className="border-y p-3 ps-5">100</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Premium plan */}
                                    <div className="mt-5 bg-white rounded-xl shadow-xl relative">
                                        <img className="absolute top-6 -right-4" src={AItag} alt="AI-tag" />
                                        <img className="absolute top-24 -right-4" src={Arcadtag} alt="Arcad-tag" />
                                        <div className="text-center w-60">
                                            <div className="flex justify-center text-white font-bold py-2 rounded-t-xl" style={{ backgroundColor: '#DFBFDD' }}>
                                                <p className="flex"><span className="py-1 px-2 bg-white rounded-full mx-2" style={{ color: '#DFBFDD' }}>✔</span><span className="mt-1">Back-to-school offer</span></p>
                                                <span className="absolute top-7">Save 20%</span>
                                            </div>
                                            <div>
                                                <div className="flex justify-center"><h1 className="pt-8 font-bold text-xl w-44">Gamizign Premium for teachers </h1></div>
                                                <div className="flex justify-center"><p className="flex mt-8"><del className="text-orange-600 mt-3 text-2xl">19.99$</del>&nbsp;<span className="text-5xl font-bold">16.99$</span></p></div>
                                                <span>Per teacher per month</span>
                                                <p className="mt-7"><del className="text-orange-600">239.88</del>$,<span className="text-orange-600 text-xl ms-2">200</span>$ (save more on) billed annually</p>
                                                <p className="flex justify-center text-xl py-2 bg-gray-200 rounded px-3 mt-3"><span><Groups2OutlinedIcon /></span><span className="ms-2 mt-0.5">Up to 200 learners</span></p>
                                                <button className="bg-cyan-600 hover:bg-orange-600 text-white rounded px-10 py-3 mt-5">Buy now</button>
                                                <ul className="mt-6 text-xl">
                                                    <li className="border-t p-3 font-bold" style={{ padding: '26px' }}></li>
                                                    <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                    <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                    <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                    <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                    <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                    <li className="border-t p-3 ps-5">Unlimited</li>
                                                    <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                    <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                    <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                    <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                    <li className="border-t p-3 ps-5">All Games</li>
                                                    <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                    <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                    <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                    <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                    <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                    <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                    <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                    <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                    <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                    <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                    <li className="border-t p-3 ps-5 text-orange-600">✔</li>
                                                    <li className="border-t p-3 ps-5">20</li>
                                                    <li className="border-t p-3 ps-5">5</li>
                                                    <li className="border-t p-3 ps-5">30</li>
                                                    <li className="border-y p-3 ps-5">200</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : Plan === 'Team & School' ? (
                            // School plan cards and detail
                            <div className="mt-3 flex justify-center">
                                <div className="bg-gray-200 flex overflow-x-auto gap-5 drop-shadow-xl p-3">
                                    {/* Standard plan */}
                                    <div className="border-2 border-cyan-600 mt-5 bg-white rounded-xl shadow-xl relative">
                                        <img className="absolute top-20 -right-7" src={AItag} alt="AI-tag" />
                                        <div className="text-center w-80 sm:w-96">
                                            <div className="flex justify-center bg-cyan-600 text-white font-bold py-2 rounded-t-xl w-full">
                                                <p className="flex"><span className="bg-white text-cyan-600 py-1 px-2 rounded-full mx-2">✔</span><span className="mt-1">Now enhanced with AI</span></p>
                                            </div>
                                            <div className="px-2">
                                                <div className="flex justify-center"><h1 className="pt-8 font-bold text-xl w-60">Gamizign! EDU Standard School & District</h1></div>
                                                <div className="flex justify-center"><p className="flex mt-3"><del className="text-orange-600 mt-3 text-2xl">$12.99</del>&nbsp;<span className="text-5xl font-bold">${License <= 19 ? '12.49' : License <= 30 ? '10.49' : License <= 40 ? '9.49' : '8.49'}</span> per teacher <br />per month</p></div>
                                                <p><span className="text-orange-600">
                                                    <del>${License <= 19 ? (12.49 * 12 * License).toFixed(2) : License <= 30 ? (10.49 * 12 * License).toFixed(2) : License <= 40 ? (9.49 * 12 * License).toFixed(2) : (8.49 * 12 * License).toFixed(2)}</del>
                                                    &nbsp;${License <= 19 ? ((12.49 * 12 * License) * 0.9).toFixed(2) : License <= 30 ? ((10.49 * 12 * License) * 0.9).toFixed(2) : License <= 40 ? ((9.49 * 12 * License) * 0.9).toFixed(2) : ((8.49 * 12 * License) * 0.9).toFixed(2)}
                                                    &nbsp;({License} Licenses)</span> billed annually</p>
                                                <div className="border-y-2 px-5 my-10 pb-5 pt-16 ">
                                                    <Slider
                                                        aria-label="Always visible"
                                                        defaultValue={80}
                                                        // getAriaValueText={valuetext}
                                                        value={License}
                                                        onChange={(e) => { setLicense(e.target.value) }}
                                                        step={1}
                                                        valueLabelDisplay="on"
                                                        min={3}
                                                    />
                                                    <p className="text-orange-600 font-bold">Saving ${License <= 19 ? ((12.49 * 12 * License) * 0.1).toFixed(2) : License <= 30 ? ((10.49 * 12 * License) * 0.1).toFixed(2) : License <= 40 ? ((9.49 * 12 * License) * 0.1).toFixed(2) : ((8.49 * 12 * License) * 0.1).toFixed(2)} per annum!</p>
                                                </div>
                                                <p className="flex justify-center text-xl mt-3"><span className="bg-gray-200 p-3 rounded-2xl"><span><Groups2OutlinedIcon /></span><span className="ms-2 mt-0.5">Min. 3 Licenses</span></span></p>
                                                <div className="py-3 mt-5">
                                                    <button className="bg-cyan-600 text-white border border-cyan-600 rounded mt-5 w-40 p-3">Buy now</button>
                                                    <button className="bg-white text-cyan-600  border border-cyan-600 rounded w-40 p-3 mt-5 md:ms-5">Request a quote</button>
                                                </div>
                                                <div className="text-left mt-10">
                                                    <h1 className="font-bold text-xl">This is best for</h1>
                                                    <p className="text-blue-500">Equiping every teacher and student with an enriched Gamizign! experience</p>
                                                </div>
                                                <div className="text-left mt-5">
                                                    <h1 className="font-bold text-xl">What's included?</h1>
                                                    <h1 className="font-bold text-xl">Up to 800 learners per session</h1>
                                                    <p className="border-y py-3">Included early access to AI-assisted gamizign creation</p>
                                                    <p className="border-b py-3 mb-10 ">Give each student a voice</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Pro plan */}
                                    <div className="border-2 border-orange-600 mt-5 bg-white rounded-xl shadow-xl relative">
                                        <img className="absolute top-20 -right-7" src={AItag} alt="AI-tag" />
                                        <div className="text-center w-80 sm:w-96">
                                            <div className="flex justify-center bg-orange-600 text-white font-bold py-2 rounded-t-xl">
                                                <p className="flex"><span className="bg-white text-orange-600 py-1 px-2 rounded-full mx-2">✔</span><span className="mt-1">Now enhanced with AI</span></p>
                                            </div>
                                            <div className="px-2">
                                                <div className="flex justify-center"><h1 className="pt-8 font-bold text-xl w-40">Gamizign! EDU Pro School & District</h1></div>
                                                <div className="flex justify-center"><p className="flex mt-3"><del className="text-orange-600 mt-3 text-2xl">$17.99</del>&nbsp;<span className="text-5xl font-bold">${ProLicense <= 19 ? '17.49' : ProLicense <= 30 ? '15.49' : ProLicense <= 40 ? '13.49' : '11.49'}</span> per teacher <br />per month</p></div>
                                                <p><span className="text-orange-600">
                                                    <del>${ProLicense <= 19 ? (17.49 * 12 * ProLicense).toFixed(2) : ProLicense <= 30 ? (15.49 * 12 * ProLicense).toFixed(2) : ProLicense <= 40 ? (13.49 * 12 * ProLicense).toFixed(2) : (11.49 * 12 * ProLicense).toFixed(2)}</del>
                                                    &nbsp;${ProLicense <= 19 ? ((17.49 * 12 * ProLicense) * 0.9).toFixed(2) : ProLicense <= 30 ? ((15.49 * 12 * ProLicense) * 0.9).toFixed(2) : ProLicense <= 40 ? ((13.49 * 12 * ProLicense) * 0.9).toFixed(2) : ((11.49 * 12 * ProLicense) * 0.9).toFixed(2)}
                                                    &nbsp;({ProLicense} Licenses)</span> billed annually</p>
                                                <div className="border-y-2 px-5 my-10 pb-5 pt-16 ">
                                                    <Slider
                                                        aria-label="Always visible"
                                                        defaultValue={80}
                                                        // getAriaValueText={valuetext}
                                                        value={ProLicense}
                                                        onChange={(e) => { setProLicense(e.target.value) }}
                                                        step={1}
                                                        valueLabelDisplay="on"
                                                        min={3}
                                                    />
                                                    <p className="text-orange-600 font-bold">Saving ${ProLicense <= 19 ? ((17.49 * 12 * ProLicense) * 0.1).toFixed(2) : ProLicense <= 30 ? ((15.49 * 12 * ProLicense) * 0.1).toFixed(2) : ProLicense <= 40 ? ((13.49 * 12 * ProLicense) * 0.1).toFixed(2) : ((11.49 * 12 * ProLicense) * 0.1).toFixed(2)} per annum!</p>
                                                </div>
                                                <p className="flex justify-center text-xl mt-3"><span className="bg-gray-200 p-3 rounded-2xl"><span><Groups2OutlinedIcon /></span><span className="ms-2 mt-0.5">Min. 3 Licenses</span></span></p>
                                                <div className="py-3 mt-5">
                                                    <button className="bg-cyan-600 text-white border border-cyan-600 rounded mt-5  w-40 p-3">Buy now</button>
                                                    <button className="bg-white text-cyan-600  border border-cyan-600 rounded w-40 p-3 mt-5 md:ms-5">Request a quote</button>
                                                </div>
                                                <div className="text-left mt-10">
                                                    <h1 className="font-bold text-xl">This is best for</h1>
                                                    <p className="text-blue-500">Getting the most from Gamizign! EDU with premium content and more</p>
                                                </div>
                                                <div className="text-left mt-5">
                                                    <h1 className="font-bold text-xl">What's included?</h1>
                                                    <h1 className="font-bold text-xl">Up to 2000 learners per session</h1>
                                                    <p className="border-y py-3">Included early access to AI-assisted gamizign creation</p>
                                                    <p className="border-b py-3 mb-10 ">Supplement your curriculum</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            // District plan Detail
                            <div className="text-center">
                                <h1 className="text-2xl font bold my-5">Are you a school district, municipality, or department of education?</h1>
                                <h1 className="text-xl my-5">Empower your students and teachers with:</h1>
                                <div className="bg-blue-50 p-5">
                                    <p className="sm:flex mt-3"><span className="bg-cyan-600 text-white py-1 px-2 rounded-full mx-2">✔</span><span className="mt-1">
                                        Quick and easy onboarding through a single link</span>
                                    </p>
                                    <p className="sm:flex mt-3"><span className="bg-cyan-600 text-white py-1 px-2 rounded-full mx-2">✔</span><span className="mt-1">
                                        Shared folder for time-saving teacger collabration</span>
                                    </p>
                                    <p className="sm:flex mt-3"><span className="bg-cyan-600 text-white py-1 px-2 rounded-full mx-2">✔</span><span className="mt-1">
                                        Advanced reports to track student performance and progress</span>
                                    </p>
                                    <p className="sm:flex mt-3"><span className="bg-cyan-600 text-white py-1 px-2 rounded-full mx-2">✔</span><span className="mt-1">
                                        Gamified learning experiences that build future-ready skills</span>
                                    </p>
                                    <p className="sm:flex mt-3"><span className="bg-cyan-600 text-white py-1 px-2 rounded-full mx-2">✔</span><span className="mt-1">
                                        Save time creating engaging lesson coontent with AI-enhanced Gamizign!</span>
                                    </p>
                                    <p className="sm:flex mt-3"><span className="bg-cyan-600 text-white py-1 px-2 rounded-full mx-2">✔</span><span className="mt-1">
                                        Premium ready-made and editable content to supplement curriculum</span>
                                    </p>
                                    <p className="sm:flex mt-3">
                                        Scale up engagement and assessment at your educational institution with Gamizign! EDU.
                                    </p>
                                    <div className="text-start my-10">
                                        <button className="bg-cyan-600 text-white rounded px-5 py-2">Request a quote</button>
                                    </div>

                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {/* FAQ Section*/}
            <FAQ />
        </div>
    )
};
export default BuyPlan;