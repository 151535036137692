import React, { useState, useEffect } from 'react';
import '../style.css';
import DragDropIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Drag _ Drop icon.png'
import UploadIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Upload a File icon.png'
import LibraryIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Library icon.png'
import BackIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/back iconB.png'
import NextIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Next icon.png'
import SelectedIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/select icon for poster.png'
import CheckBoxOn from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/PPTX check box on.png'
import CheckBoxOff from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/PPTX check box off.png'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import LoginHeading from '../../../../../LoginHeading/LoginHeading';
import DatePicker from "react-datepicker";
import Modal from 'react-modal'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import TextField from '@mui/material/TextField';
import { Autocomplete, CircularProgress, FormControl, InputAdornment, InputLabel, MenuItem, Select, Switch, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import MetaTags from '../../../../../GlobalComponents/MetaData';
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import Joyride from 'react-joyride';
import axiosInstance from '../../../../../../axios';

const itemData = [
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/art.png`,
        title: 'art.png',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/board.png`
        ,
        title: 'board.png',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/boat.png`,
        title: 'boat.png',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/butterfly.png`,
        title: 'butterfly.png',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/cat.png`,
        title: 'cat.png',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/cat2.png`,
        title: 'cat2.png',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/cheer.png`,
        title: 'cheer.png',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/circuit.png`,
        title: 'circuit.png',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/conference.png`,
        title: 'conference.png',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/dog.png`,
        title: 'dog.png',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/dog2.png`,
        title: 'dog2.png',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/English.png`,
        title: 'English.png',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/focusing.png`,
        title: 'focusing.png',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/Gamizingsplash.png`,
        title: 'Gamizingsplash.png',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/grass2.png`,
        title: 'grass2.png',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/hamster.png`,
        title: 'hamster.png',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/hamster2.png`,
        title: 'hamster2.png',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/hearts.png`,
        title: 'hearts.png',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/howdy.png`,
        title: 'howdy.png',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/island.png`,
        title: 'island.png',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/island2.png`,
        title: 'island2.png',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/leaves.png`,
        title: 'leaves.png',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/maze.png`,
        title: 'maze.png',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/mountain.png`,
        title: 'mountain.png',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/mushroom.png`,
        title: 'mushroom.png',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/nature.jpg`,
        title: 'nature.jpg',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/nature.png`,
        title: 'nature.png',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/ocean.png`,
        title: 'ocean.png',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/owl.png`,
        title: 'owl.png',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/owl2.png`,
        title: 'owl2.png',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/paint2.jpg`,
        title: 'paint2.jpg',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/paints.jpg`,
        title: 'paints.jpg',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/paints3.jpg`,
        title: 'paints3.jpg',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/pink.png`,
        title: 'pink.png',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/rain.png`,
        title: 'rain.png',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/rainy.png`,
        title: 'rainy.png',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/seed.png`,
        title: 'seed.png',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/seed2.png`,
        title: 'seed2.png',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/spring.png`,
        title: 'spring.png',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/squirrll.png`,
        title: 'squirrll.png',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/student.png`,
        title: 'student.png',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/students&books.png`,
        title: 'students&books.png',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/trees.png`,
        title: 'trees.png',
    },
    {
        img: `${process.env.REACT_APP_ENDPOINT}/uploads/wave.png`,
        title: 'wave.png',
    },
];




const NewCourse = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [Name, setName] = useState(localStorage.getItem('courseName') || '');
    const [date, setDate] = useState(new Date());
    const [Grade, setGrade] = useState('');
    const [MainTopic, setMainTopic] = useState('');
    const [classes, setClasses] = useState();
    const [Progrees, setProgrees] = useState(false);
    // Libarary
    const [ImageTitle, setImageTitle] = useState('');
    // Input validations
    const [nameError, setNameError] = useState('');
    const [gradeError, setGradeError] = useState('');
    const [mainTopicError, setMainTopicError] = useState('');
    const [background, setbackground] = useState('');
    const [uploading, setUploading] = useState(false);
    const [subtopics, setSubtopics] = useState([]);
    const [editableFields, setEditableFields] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const { courseType, courses } = useLocation().state || "";

    // sub topic's arrays
    const LanguageArts = ['Reading', 'Comprehension', 'Writing', 'Grammar', 'Vocabulary', 'Spelling', 'Punctuation', 'Grammar', 'Literature',]
    const Math = ['Math Operations', 'Fractions, Decimals, and Percentages', 'Geometry and Measurement', 'Algebra and Data Analysis', 'Pre-algebra', 'Calculus and Elective Courses',]
    const Science = ['Life Science', 'Earth Science', 'Physical Science', 'Advanced Science',]
    const Social = ['History', 'Geography', 'Government and Civics', 'Elective Courses',]
    const handlesubtopic = (index, value) => {
        const updatedFields = [...editableFields];
        updatedFields[index] = value;
        setEditableFields(updatedFields);

    }
    const handleAddField = () => {
        if (MainTopic) {
            if (editableFields.length < 3) {
                setEditableFields([...editableFields, '']);
            }
        }
    };

    const handleInputChange = (index, value) => {
        const updatedFields = [...editableFields];
        updatedFields[index] = value;
        setEditableFields(updatedFields);
    };
    // remove input 
    const handleRemoveField = (index) => {
        const updatedFields = [...editableFields];
        updatedFields.splice(index, 1);
        setEditableFields(updatedFields);
    };


    const handleNameChange = (e) => {
        localStorage.setItem('courseName', e.target.value);
        setName(e.target.value);
        setNameError('');
    };

    const handleGradeChange = (e) => {
        localStorage.setItem('grade', e.target.value);
        setGrade(e.target.value);
        setGradeError('');
    };

    const handleMainTopicChange = (e) => {
        localStorage.setItem('mainTopic', e.target.value);
        setMainTopic(e.target.value);
        setMainTopicError('');
        setEditableFields([]);
        if (e.target.value === 'Language Arts') {
            setSubtopics(LanguageArts);
        }
        else if (e.target.value === 'Math') {
            setSubtopics(Math);
        }
        else if (e.target.value === 'Science') {
            setSubtopics(Science);
        }
        else if (e.target.value === 'Social') {
            setSubtopics(Social);
        }
    };

    const uploadFile = async (file) => {
        return new Promise((resolve, reject) => {

            // Prepare the request
            const url = `${process.env.REACT_APP_ENDPOINT}/attachment?type=activity`;
            const formData = new FormData();
            formData.append('file', file);

            // Send the request
            axiosInstance.postForm(url, formData)
                .then(function (response) {
                    if (response.status === 200) {
                        return response.data // Extract the file name from the response
                    } else {
                        setUploading(false);
                        throw new Error('Error uploading file');
                    }
                })
                .then(data => {
                    setUploading(false);
                    resolve(data.message); // Resolve with the file name
                })
                .catch(error => {
                    setUploading(false);
                    reject(error);
                });
        });
    };
    // validation and navigate 
    const navigate = useNavigate();
    const handleSubmit = async (e) => {
        e.preventDefault();

        let hasError = false;
        let fileName = background;
        // Perform validation
        if (!Name) {
            setNameError('Please fill in the course name.');
            hasError = true;
        }

        if (Grade === '') {
            setGradeError('Please select a grade.');
            hasError = true;
        }

        if (MainTopic === '') {
            setMainTopicError('Please select a main topic.');
            hasError = true;
        }
        if (selectedFile !== null) {
            fileName = await uploadFile(selectedFile);
        }

        if (!hasError) {
            var course = {
                name: Name,
                courseType: Type,
                background: fileName,
                grade: Grade,
                mainTopic: MainTopic,
                subTopics: editableFields.toString(),
                startingDate: (date.$d ? date.$d : date).toLocaleString('eng-GB').split(',')[0].trim(),
                stages: [
                    {
                        name: `stage 1`,
                        startingDate: (date.$d ? date.$d : date).toLocaleString('eng-GB').split(',')[0].trim(),
                        finishingDate: new Date(new Date(date).setMonth(new Date(date).getMonth() + 1)).toLocaleString('en-GB').split(',')[0].trim(),
                        activities: [],
                    }
                ]
            };
            if (courseType === 'single') {

                navigate('/Dashboard/adding', { state: {courses:courses, courseType: courseType, course: course } });
            } else {
                navigate('/Dashboard/activityType', { state: {courses:courses, courseType: courseType, course: course, courseFormatDate: date.$d ? date.$d : date, stratingDate: date.$d ? date.$d : date, finishingDate: new Date(new Date(date).setMonth(new Date(date).getMonth() + 1)) } });
            }
        }


    };
    const getClasses = async () => {
        setProgrees(true);
        var organization = parseInt(localStorage.getItem('organization'));
       const response = await  axiosInstance.get(`${process.env.REACT_APP_ENDPOINT}/organization/${organization}/classes/`)
            setClasses(response.data.classes);
            setProgrees(false);            
    }
    useEffect(() => {
        getClasses();
    }, []);

    // Course cover Image
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
        CovercloseModal();
        setImageTitle('');
    };

    const handleDrop = (e) => {
        e.preventDefault();

        const files = e.dataTransfer.files;
        const isValidFileType = Array.from(files).some(
            (file) => file.type === 'image/jpeg' || file.type === 'image/png'
        );

        if (isValidFileType) {
            const file = files[0];
            setSelectedFile(file);
            // Handle the dropped file as needed
        } else {
            console.log('Invalid file type. Please drop a .jpg or .png file.');
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDateChange = (selectedDate) => {
        setDate(selectedDate);
        setShowDropdown(false);
    };

    // selecte cover image of course from Libray PopUp
    const [CovermodalIsOpen, setCovermodalIsOpen] = useState(false);
    const openCoverModal = () => {
        setCovermodalIsOpen(true);
    };
    const CovercloseModal = () => {
        setbackground(ImageTitle)
        setCovermodalIsOpen(false);
    };
    // Selecte course type 
    const [Type, setType] = useState('normal');

    const handleChange = () => {
        setType((prev) => (prev === 'normal' ? 'sequential' : 'normal'));
    };
    // Onboarding
    // const [Onboarding, setOnboarding] = useState(courses?.length);
    const [Onboarding, setOnboarding] = useState(parseInt(localStorage.getItem("accountActive")));
    const [{ run, steps }, setState] = useState({
        run: true,
        steps: [
            {
                content: <h2>Selecte.</h2>,
                placement: 'left',
                target: '#bachgroundImagess',
                title: 'Course Backgound'
            },
            {
                content: <h2>Select a background image from Gamizign libray or upload an image from your computer for course.</h2>,
                placement: 'left',
                target: '#bachgroundImage',
                title: 'Course background'
            },
            {
                content: <h2>Write a specific name of course.</h2>,
                placement: 'left',
                target: '#CourseName',
                title: 'Course Name'
            },
            {
                content: <h2>Can't wait to start keep it as it is or change to a later date.</h2>,
                placement: 'left',
                target: '#StartingDate',
                title: 'Course Start Date'
            },
            {
                content: <h2>Select your students class.</h2>,
                placement: 'left',
                target: '#Grade',
                title: 'Grade'
            },
            {
                content: <h2>Teaching math, science, select one from the dropdown.</h2>,
                placement: 'right',
                target: '#MainTopic',
                title: 'MainTopic'
            },
            {
                content: <h2>We're keeping track of your courses, select subtopics of your course maximum 3.</h2>,
                placement: 'left',
                target: '#SubTopics',
                title: 'SubTopics'
            },
            {
                content: <h2>Consider it's a game, do you want to lock levels and open them at a time? select sequential open all stages with activities keep it normal.</h2>,
                placement: 'left',
                target: '#CourseType',
                title: 'Course Type'
            },
            {
                content: <h2>Save course information and proccess to complete course.</h2>,
                placement: 'right',
                target: '#SaveCourse',
                title: 'Save'
            },
        ]
    })


    return (
        <div className='w-full'>
            <MetaTags title='Add Course Information' description='Gamizign Add Course Information page--' />
            <form action="post" onSubmit={(e) => { handleSubmit(e) }}>
                <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'>
                    <h3 className="text-xl">Create New Activity</h3>
                    <LoginHeading objects={setOnboarding} />
                </div>
                <div className='flex bg-white m-7 pb-5'>
                    {Onboarding <= 1  && <Joyride callback={() => { }} continuous showProgress
                        run={run} steps={steps} hideCloseButton scrollToFirstStep showSkipButton
                        styles={{
                            buttonNext: { backgroundColor: '#E5F2E3', borderRadius: '5px',color:'black', },
                            buttonBack: { backgroundColor: '#E5F2E3',  borderRadius: '5px', color:'black', }
                          }} />}
                    <div className='flex-1'></div>
                    <div className='flex-1'>
                        <div className="flex flex-col items-center justify-center text-center">
                            <div className='text-center mt-5 mb-2'>
                                <div><h1><b>Cover Image</b></h1>
                                    <p className='text-sky-600'>Chose your course cover image</p></div>
                            </div>
                            <Dialog open={CovermodalIsOpen} onClose={CovercloseModal}>
                                <DialogTitle>Choose your course cover image</DialogTitle>
                                <DialogContent>
                                    <header className="App-header">
                                        <ImageList cols={3} rowHeight={180}>
                                            {itemData.map((item) => (
                                                <ImageListItem className='relative' key={item.img}>
                                                    <img
                                                        className={`hover:scale-90 cursor-pointer ${item.title === ImageTitle ? "scale-90" : ""}`}
                                                        onClick={() => { setImageTitle(item.title); setSelectedFile(null) }}
                                                        srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                                        src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                                                        alt={item.title}
                                                        loading="lazy"
                                                    />
                                                    {item.title === ImageTitle &&
                                                        <span className='absolute top-5 left-5'><img src={SelectedIcon} alt="selected" /></span>
                                                    }

                                                </ImageListItem>
                                            ))}
                                        </ImageList>
                                    </header>
                                </DialogContent>
                                <div className='flex justify-center'>
                                    <Button onClick={CovercloseModal} color="primary">
                                        save
                                    </Button>
                                    <Button color="primary">
                                        <label htmlFor="fileInput" className='cursor-pointer'>
                                            <input
                                                type="file"
                                                accept='image/*'
                                                id="fileInput"
                                                className="absolute h-0 w-0 overflow-hidden"
                                                onChange={handleFileChange} />

                                            Upload from your gallery
                                        </label>
                                    </Button>
                                </div>
                            </Dialog>
                            <label id='bachgroundImage'
                                className="InputBg w-100 relative cursor-pointer border-2 border-dashed border-gray-300 p-4 rounded-xl transition duration-300 hover:border-blue-500"
                                onDrop={handleDrop}
                                onClick={openCoverModal}
                                onDragOver={handleDragOver}>
                                <div className="text-center md:flex gap-3">
                                    <div className='text-center mt-3 '>
                                        <div className='flex justify-center'><img src={DragDropIcon} alt="drag" /></div>
                                        <p>Drag & Drop</p>
                                    </div>
                                    <span className='mt-7'>OR</span>
                                    <div className='bg-white flex gap-2 p-3 px-5 rounded-xl'>
                                        <div className='px-5'>
                                            <div className='flex justify-center'><img src={UploadIcon} alt="uploadFile" /></div>
                                            <p>Upload a File</p>
                                        </div>
                                        <div className='px-5'>
                                            <div className='flex justify-center'><img src={LibraryIcon} alt="Library" /></div>
                                            <p>Library</p></div>
                                    </div>
                                </div>
                            </label>

                            {selectedFile && (
                                <div className="mt-4">
                                    <p className="text-lg font-semibold">Selected File:</p>
                                    <p className="text-gray-500">{selectedFile.name}</p>
                                </div>
                            )}
                        </div>
                        {/* Title , Date and Other Options is Here */}
                        <div className='border mt-5 rounded-xl p-5 drop-shadow-xl'>
                            <div>
                                <TextField id='CourseName'
                                    margin="dense"
                                    label="Title"
                                    name="Course Title"
                                    type="text"
                                    value={Name}
                                    autoComplete='off'
                                    placeholder="Course name"
                                    onChange={handleNameChange}
                                    fullWidth
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                border: `${nameError ? '2px solid #FDE4DC' : '2px solid #F3F6F6'}`,
                                            },
                                            '&.Mui-focused fieldset': {
                                                border: '2px solid #DBE6F5',
                                            },
                                            '&:hover fieldset': {
                                                border: '2px solid #DBE6F5',
                                            },
                                        },
                                    }}
                                    InputProps={{
                                        style: {
                                            borderRadius: "10px",
                                            color: '#2E4EB5',
                                            fontSize: '20px',
                                            background: `${Name ? '#F3F6F6' : 'white'}`
                                        }
                                    }}
                                />
                                {nameError && <p className="text-red-500">{nameError}</p>}
                            </div>
                            {/* date */}
                            <div className='mt-5'>
                                <div className="relative w-full">
                                    <button
                                        className="flex items-center justify-center -mt-1 rounded-full focus:outline-none"
                                        type='button'
                                        onMouseEnter={() => setShowDropdown(true)}
                                        onMouseLeave={() => setShowDropdown(false)}
                                    >
                                        {/* <label htmlFor="date">Starting Date: </label>   */}
                                        <DatePicker id='StartingDate' disabled className='text-blue-800 rounded bg-gray-100 cursor-pointer px-3 py-1 w-full' minDate={new Date()} selected={date.$d ? date.$d : date} onChange={(date) => handleDateChange(date)} dateFormat={"dd/MM/yyyy"} />
                                    </button>
                                    {showDropdown && (
                                        <div className="absolute text-black -right-12 -left-12 sm:right-0 sm:left-0 z-10  bg-white border border-gray-200 rounded shadow-lg top-full" onMouseEnter={() => setShowDropdown(true)} onMouseLeave={() => setShowDropdown(false)}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={['DateCalendar', 'DateCalendar']}>

                                                    <DemoItem label="Starting Date">
                                                        <DateCalendar
                                                            onChange={(newValue) => handleDateChange(newValue)}
                                                            minDate={dayjs()}
                                                            value={dayjs(date)}
                                                            dateFormat={"dd/MM/yyyy"}
                                                        />

                                                    </DemoItem>
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </div>
                                    )}
                                </div>
                                {/* <label htmlFor="date">Starting Date: </label> */}
                                {/* <DatePicker className='text-blue-800 rounded-xl bg-gray-100 cursor-pointer px-3 py-1' minDate={new Date()} selected={date} onChange={(date) => handleDateChange(date)} dateFormat={"dd/MM/yyyy"} /> */}

                            </div>
                            <div className='flex flex-wrap xl:gap-8 mt-5'>
                                {/* Select Grade */}
                                <div className='w-44'>
                                    {Progrees ? <div className='flex justify-center mt-4'><CircularProgress className="text-blue-800" /></div>
                                        :
                                        <FormControl fullWidth
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        border: `${gradeError ? '2px solid #FDE4DC' : '2px solid #F3F6F6'}`,
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        border: '2px solid #DBE6F5',
                                                    },
                                                    '&:hover fieldset': {
                                                        border: '2px solid #DBE6F5',
                                                    },
                                                },
                                            }}>
                                            <InputLabel>Grade</InputLabel>
                                            {classes && Array.isArray(classes) && classes.length > 0 ? (<Select
                                                labelId="Grade" id='Grade'
                                                name="Grade"
                                                label="Grade"
                                                onChange={handleGradeChange}
                                                style={{ borderRadius: '10px', color: '#2E4EB5', background: `${Grade ? '#F3F6F6' : 'white'}` }}
                                            >
                                                {classes.map((classItem) => (
                                                    <MenuItem key={classItem.class_id} value={classItem.class_id}>
                                                        {classItem.class_name}
                                                    </MenuItem>
                                                ))}
                                            </Select>) : (<p>No classes available</p>)}
                                        </FormControl>}
                                    {gradeError && <p className="text-red-500">{gradeError}</p>}
                                </div>
                                {/* Main Topic */}
                                <div className='w-44 mt-5 xl:mt-0'>
                                    <FormControl fullWidth
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    border: `${mainTopicError ? '2px solid #FDE4DC' : '2px solid #F3F6F6'}`,
                                                },
                                                '&.Mui-focused fieldset': {
                                                    border: '2px solid #DBE6F5',
                                                },
                                                '&:hover fieldset': {
                                                    border: '2px solid #DBE6F5',
                                                },
                                            },
                                        }}>
                                        <InputLabel>Main Topic</InputLabel>
                                        <Select
                                            labelId="MainTopic" id='MainTopic'
                                            name="Main Topic"
                                            label="Main Topic"
                                            onChange={handleMainTopicChange}
                                            style={{ borderRadius: "10px", color: '#2E4EB5', background: `${MainTopic ? '#F3F6F6' : 'white'}` }}
                                        // value={MainTopic}
                                        >
                                            <MenuItem value='Language Arts'>Language Arts</MenuItem>
                                            <MenuItem value='Math' >Math</MenuItem>
                                            <MenuItem value='Science'>Science</MenuItem>
                                            <MenuItem value='Social'>Social</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {mainTopicError && <p className="text-red-500">{mainTopicError}</p>}
                                </div>
                            </div>

                            {/* Add Topic */}
                            <div className="mt-5">
                                <Autocomplete
                                    multiple
                                    value={editableFields}
                                    id="SubTopics"
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                border: '2px solid #F3F6F6',
                                            },
                                            '&.Mui-focused fieldset': {
                                                border: '2px solid #DBE6F5',
                                            },
                                            '&:hover fieldset': {
                                                border: '2px solid #DBE6F5',
                                            },
                                        },
                                        background: `${editableFields.length > 0 ? '#F3F6F6' : 'white'}`,
                                        color: '#2E4EB5',
                                        borderRadius: "10px",
                                    }}
                                    options={subtopics}
                                    onChange={(e, newValue) => {
                                        if (newValue.length <= 3) {
                                            setEditableFields(newValue);
                                        }
                                    }}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option}
                                    renderOption={(props, option, { selected }) => {
                                        return (
                                            <li {...props}>
                                                <img className='h-6 me-2' src={selected ? CheckBoxOn : CheckBoxOff} alt="" />
                                                {option}
                                            </li>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            // onChange={(e) => {
                                            //     if (editableFields.length < 3) {
                                            //         editableFields.push(e.target.value);
                                            //     }
                                            // }} 
                                            {...params} label="Write/Select SubTopic" placeholder="Search SubTopic" />
                                    )}
                                />
                            </div>
                            <p className='text-gray-400'>Add max 3 topics</p>
                            <p className='text-gray-400 mt-5'>Course Type</p>
                            <div className='flex gap-2' id='CourseType'>
                                <span className={`mt-1.5 ${Type === 'normal' ? '#2E4EB5' : ''}`} style={{ color: Type === 'normal' ? '#2E4EB5' : '', }}>Normal</span>
                                <Switch
                                    checked={Type === 'sequential'}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                                <span className={`mt-1.5 ${Type === 'sequential' ? '#2E4EB5' : ''}`} style={{ color: Type === 'sequential' ? '#2E4EB5' : '', }}>Sequential</span>
                            </div>
                            <div className='flex text-center mt-10 items-center justify-center gap-3'>
                                <Link to='/Dashboard/CreateCourse'><button className='BackBtn flex p-2 px-7 hover:bg-blue-200'><b> Back </b><img className='ml-5 mt-1' src={BackIcon} alt="Back" /></button></Link>
                                <button id='SaveCourse' type='submit' className='BackBtn flex p-2 px-7 hover:bg-blue-200'><img className='mr-5 mt-1' src={NextIcon} alt="Next" /><b> Next</b></button>
                            </div>
                            <div className='flex-1'></div>
                        </div>
                    </div>
                    <div className='flex-1'></div>
                </div>
            </form>
        </div>
    );
};


export default NewCourse;

