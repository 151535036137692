import React from "react";
import avtar0 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-0.png'
import avtar1 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-1.png'
import avtar2 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-2.png'
import avtar3 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-3.png'
import avtar4 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-4.png'
import avtar5 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-5.png'
import avtar6 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-6.png'
import avtar7 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-7.png'
import avtar8 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-8.png'
import avtar9 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-9.png'
import avtar10 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-10.png'
import avtar11 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-11.png'
import avtar12 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-12.png'
import avtar13 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-13.png'
import avtar14 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-14.png'
import avtar15 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-15.png'
import avtar16 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-16.png'
import avtar17 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-17.png'
import avtar18 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-18.png'
import avtar19 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-19.png'
import avtar20 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-20.png'
import avtar21 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-21.png'
import avtar22 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-22.png'
import avtar23 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-23.png'
import avtar24 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-24.png'
import avtar25 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-25.png'
import avtar26 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-26.png'
import avtar27 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-27.png'
import avtar28 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-28.png'
import avtar29 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-29.png'
import avtar30 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-30.png'
import avtar31 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-31.png'
import avtar32 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-32.png'
import avtar33 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-33.png'
import avtar34 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-34.png'
import avtar35 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-35.png'
import avtar36 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-36.png'
import avtar37 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-37.png'
import avtar38 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-38.png'
import avtar39 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-39.png'
import avtar40 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-40.png'
import avtar41 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-41.png'
import avtar42 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-42.png'
import avtar43 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-43.png'
import avtar44 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-44.png'
import avtar45 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-45.png'
import avtar46 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-46.png'
import avtar47 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-47.png'
import avtar48 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-48.png'
import avtar49 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-49.png'
import avtar50 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-50.png'
import avtar51 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-51.png'
import avtar52 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-52.png'
import avtar53 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-53.png'
import avtar54 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-54.png'
import avtar55 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-55.png'
import avtar56 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-56.png'
import avtar57 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-57.png'
import avtar58 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-58.png'
import avtar59 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-59.png'
import avtar60 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-60.png'
import avtar61 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-61.png'
import avtar62 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-62.png'
import avtar63 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-63.png'
import avtar64 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-64.png'
import avtar65 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-65.png'
import avtar66 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-66.png'
import avtar67 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-67.png'
import avtar68 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-68.png'
import avtar69 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-69.png'
import avtar70 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-70.png'
import avtar71 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-71.png'
import avtar72 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-72.png'
import avtar73 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-73.png'
import avtar74 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-74.png'
import avtar75 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-75.png'
import avtar76 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-76.png'
import avtar77 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-77.png'
import avtar78 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-78.png'
import avtar79 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-79.png'
import avtar80 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-80.png'
import avtar81 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-81.png'
import avtar82 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-82.png'
import avtar83 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-83.png'
import avtar84 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-84.png'
import avtar85 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-85.png'
import avtar86 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-86.png'
import avtar87 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-87.png'
import avtar88 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-88.png'
import avtar89 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-89.png'
import avtar90 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-90.png'
import avtar91 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-91.png'
import avtar92 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-92.png'
import avtar93 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-93.png'
import avtar94 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-94.png'
import avtar95 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-95.png'
import avtar96 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-96.png'
import avtar97 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-97.png'
import avtar98 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-98.png'
import avtar99 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-99.png'
import avtar100 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-100.png'
import avtar101 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-101.png'
import avtar102 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-102.png'
import avtar103 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-103.png'
import avtar104 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-104.png'
import avtar105 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-105.png'
import avtar106 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-106.png'
import avtar107 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-107.png'
import avtar108 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-108.png'
import avtar109 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-109.png'
import avtar110 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-110.png'
import avtar111 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-111.png'
import avtar112 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-112.png'
import avtar113 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-113.png'
import avtar114 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-114.png'
import avtar115 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-115.png'
import avtar116 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-116.png'
import avtar117 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-117.png'
import avtar118 from '../../../../../../assets/icons/Arts -avatars/Avatar Sprite-118.png'

const getAvtar = (avatar_id) => {
    switch (avatar_id) {
      case 0:
        return avtar0;
      case 1:
        return avtar1;
      case 2:
        return avtar2;
      case 3:
        return avtar3;
      case 4:
        return avtar4;
      case 5:
        return avtar5;
      case 5:
        return avtar5;
      case 6:
        return avtar6;
      case 7:
        return avtar7;
      case 8:
        return avtar8;
      case 9:
        return avtar9;
      case 10:
        return avtar10;
      case 11:
        return avtar11;
      case 12:
        return avtar12;
      case 13:
        return avtar13;
      case 14:
        return avtar14;
      case 15:
        return avtar15;
      case 16:
        return avtar16;
      case 17:
        return avtar17;
      case 18:
        return avtar18;
      case 19:
        return avtar19;
      case 20:
        return avtar20;
      case 21:
        return avtar21;
      case 22:
        return avtar22;
      case 23:
        return avtar23;
      case 24:
        return avtar24;
      case 25:
        return avtar25;
      case 26:
        return avtar26;
      case 27:
        return avtar27;
      case 28:
        return avtar28;
      case 29:
        return avtar29;
      case 30:
        return avtar30;
      case 31:
        return avtar31;
      case 32:
        return avtar32;
      case 33:
        return avtar33;
      case 34:
        return avtar34;
      case 45:
        return avtar35;
      case 36:
        return avtar36;
      case 37:
        return avtar37;
      case 38:
        return avtar38;
      case 39:
        return avtar39;
      case 40:
        return avtar40;
      case 41:
        return avtar41;
      case 42:
        return avtar42;
      case 43:
        return avtar43;
      case 44:
        return avtar44;
      case 45:
        return avtar45;
      case 46:
        return avtar46;
      case 47:
        return avtar47;
      case 48:
        return avtar48;
      case 49:
        return avtar49;
      case 50:
        return avtar50;
      case 51:
        return avtar51;
      case 52:
        return avtar52;
      case 53:
        return avtar53;
      case 54:
        return avtar54;
      case 55:
        return avtar55;
      case 56:
        return avtar56;
      case 57:
        return avtar57;
      case 58:
        return avtar58;
      case 59:
        return avtar59;
      case 60:
        return avtar60;
      case 61:
        return avtar61;
      case 62:
        return avtar62;
      case 63:
        return avtar63;
      case 64:
        return avtar64;
      case 65:
        return avtar65;
      case 66:
        return avtar66;
      case 67:
        return avtar67;
      case 68:
        return avtar68;
      case 69:
        return avtar69;
      case 70:
        return avtar70;
      case 71:
        return avtar71;
      case 72:
        return avtar72;
      case 73:
        return avtar73;
      case 74:
        return avtar74;
      case 75:
        return avtar75;
      case 76:
        return avtar76;
      case 77:
        return avtar77;
      case 78:
        return avtar78;
      case 79:
        return avtar79;
      case 80:
        return avtar80;
      case 81:
        return avtar81;
      case 82:
        return avtar82;
      case 83:
        return avtar83;
      case 84:
        return avtar84;
      case 85:
        return avtar85;
      case 86:
        return avtar86;
      case 87:
        return avtar87;
      case 88:
        return avtar88;
      case 89:
        return avtar89;
      case 90:
        return avtar90;
      case 91:
        return avtar91;
      case 92:
        return avtar92;
      case 93:
        return avtar93;
      case 94:
        return avtar94;
      case 95:
        return avtar95;
      case 96:
        return avtar96;
      case 97:
        return avtar97;
      case 98:
        return avtar98;
      case 99:
        return avtar99;
      case 100:
        return avtar100;
      case 101:
        return avtar101;
      case 102:
        return avtar102;
      case 103:
        return avtar103;
      case 104:
        return avtar104;
      case 105:
        return avtar105;
      case 106:
        return avtar106;
      case 107:
        return avtar107;
      case 108:
        return avtar108;
      case 109:
        return avtar109;
      case 110:
        return avtar110;
      case 111:
        return avtar111;
      case 112:
        return avtar112;
      case 113:
        return avtar113;
      case 114:
        return avtar114;
      case 115:
        return avtar115;
      case 116:
        return avtar116;
      case 117:
        return avtar117;
      case 118:
        return avtar118;
      default:
        return avtar0;
    }
  };
  export default getAvtar;