import { AccountCircle } from "@mui/icons-material";
import KeyIcon from '@mui/icons-material/Key';
import EmailIcon from '@mui/icons-material/Email';
import { Alert, Button, CircularProgress, Dialog, DialogContent, InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import axiosInstance from "../../../../../../axios";

const UpdateAccount = () => {
    const [Edit, setEdit] = useState(false);
    const [Progress, setProgress] = useState(false);
    const [DialogMessage, setDialogMessage] = useState('');

    // Instructor Info validation
    const [newItem, setNewItem] = useState({
        email: localStorage.getItem("email"),
        firstName: localStorage.getItem("name"),
        lastName: localStorage.getItem("lastname"),
        organization_id: parseInt(localStorage.getItem("organization")),
    });
    const [FirstName, setFirstName] = useState(newItem.firstName);
    const [LastName, setLastName] = useState(newItem.lastName);
    const [Paswoord, setPaswoord] = useState('');
    // errors
    const [FirstNameError, setFirstNameError] = useState('');
    const [LastNameError, setLastNameError] = useState('');
    const [PaswoordError, setPaswoordError] = useState('');
    // Handle getting info changes
    const handleChange = (event) => {
        const { name, value } = event.target;
        setNewItem((prevState) => ({ ...prevState, [name]: value }));
    };
    // Validate password 8 chars and must one alphabetic char and make error live , show hide 
    const [showPassword, setShowPassword] = useState(false);
    const passwordHandler = (value) => {
        setPaswoord(value);
        if (!value) {
            setPaswoordError('Please enter the Password.')
        } else if (value.length < 8) {
            setPaswoordError('Password must be at least 8 characters long.');
        } else if (!/[a-zA-Z]/.test(value)) {
            setPaswoordError('Password must contain at least one alphabetic character.(a-zA-Z)');
        } else if (value.length >= 8 && /[a-zA-Z]/.test(value)) {
            setPaswoordError('Perfect ✔');
        }
    }

    const closeDialog = () => {
        setFirstNameError("");
        setLastNameError("");
        setPaswoordError("");
        setPaswoord("");
        setEdit(false);
        setProgress(false);
        setShowPassword(false);
        setNewItem((prevState) => ({
            ...prevState,
            firstName: localStorage.getItem("name"),
            lastName: localStorage.getItem("lastname"),
        }));
    }
    const SubmiHandle = async () => {
        setProgress(true);
        try {
            const organization_id = localStorage.getItem("organization")
            const instructor_id = localStorage.getItem("instructor_id")
            const UpdateInfo = await axiosInstance.put(`${process.env.REACT_APP_ENDPOINT}/organizations/${organization_id}/instructors/${instructor_id}`, newItem);
            if (UpdateInfo.status === 200) {
                setDialogMessage('Account has been Updated successfully.');
                localStorage.setItem("name", UpdateInfo.data.firstName);
                localStorage.setItem("lastname", UpdateInfo.data.lastName);
            }

        } catch (error) {
            setDialogMessage('An error occured updaing try gain later.')

        } finally {
            setProgress(false);
        }

    }
    const validationHandler = () => {
        let mainError = false;
        if (!FirstName) {
            setFirstNameError('Please fill the First Name.');
            mainError = true;
        }
        if (!LastName) {
            setLastNameError('Please fill the Last Name.')
            mainError = true;

        }
        if (!Paswoord) {
            setPaswoordError('Please enter the Password.')
            mainError = true;
        } else if (Paswoord.length < 8) {
            setPaswoordError('Password must be at least 8 characters long.');
            mainError = true;
        } else if (!/[a-zA-Z]/.test(Paswoord)) {
            setPaswoordError('Password must contain at least one alphabetic character.(a-zA-Z)');
            mainError = true;
        } else if (Paswoord.length >= 8 && /[a-zA-Z]/.test(Paswoord)) {
            setPaswoordError('Perfect ✔');
        }
        if (!mainError) {
            SubmiHandle();
        }
    }
    return (
        <div>

            <Dialog className="sm:ms-56" open={DialogMessage} onClose={() => { setDialogMessage(false) }}>
                <DialogContent>
                    <Alert severity={DialogMessage === 'Account has been Updated successfully.' ? "success" : 'error'} >
                        {DialogMessage}</Alert>
                    <div className="flex justify-center mt-3">
                        <Button onClick={() => { setDialogMessage(false); closeDialog() }}>Okay</Button>
                    </div>
                </DialogContent>
            </Dialog>
            {/*  */}
            <Dialog className="sm:ms-56" open={Edit} onClose={closeDialog}>
                {Progress && (
                    <div className="absolute inset-0 flex justify-center items-center bg-gray-50 bg-opacity-75 z-50">
                        <CircularProgress className="text-blue-800" />
                    </div>
                )}
                <DialogContent>
                    <>
                        <TextField
                            margin="dense"
                            label="Email"
                            name="email"
                            type="email"
                            autoComplete='off'
                            fullWidth
                            disabled
                            value={newItem.email}
                            onChange={(e) => { handleChange(e); }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EmailIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            label="First Name"
                            name="firstName"
                            type="text"
                            autoComplete='off'
                            fullWidth
                            value={newItem.firstName}
                            onChange={(e) => { handleChange(e); setFirstName(e.target.value); setFirstNameError('') }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {FirstNameError && <p className="text-red-500">{FirstNameError}</p>}
                        <TextField
                            margin="dense"
                            label="Last Name"
                            name="lastName"
                            type="text"
                            autoComplete='off'
                            fullWidth
                            value={newItem.lastName}
                            onChange={(e) => { handleChange(e); setLastName(e.target.value); setLastNameError('') }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {LastNameError && <p className="text-red-500">{LastNameError}</p>}
                        <TextField
                            margin="dense"
                            label="Password"
                            name="hashed_password"
                            type={showPassword ? 'text' : 'password'}
                            fullWidth
                            // value={newItem.hashed_password}
                            onChange={(e) => { handleChange(e); passwordHandler(e.target.value) }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <KeyIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment sx={{ cursor: 'pointer' }} position="end">
                                        <span onClick={() => setShowPassword(prev => !prev)} className="cursor-pointer">{showPassword ? <FaEye size={24} /> : <FaEyeSlash size={24} />}</span>
                                    </InputAdornment>
                                ),
                            }}

                        />
                        {PaswoordError && <p className={`${PaswoordError === 'Perfect ✔' ? 'text-green-500' : 'text-red-500'}`}>{PaswoordError}</p>}
                    </>
                    {/* Action */}
                    <div className="flex justify-center mt-3">
                        <Button onClick={() => { closeDialog('') }}>Cancel</Button>
                        <Button onClick={() => { validationHandler() }}>Submit</Button>
                    </div>
                </DialogContent>
            </Dialog>
            <div className="text-center my-5">
                <button onClick={() => { setEdit(true) }}
                    className="rounded text-white font-bold px-10 py-3 bg-green-500 hover:bg-green-600 shadow-md w-56 ">Update Profile</button>
            </div>
        </div>
    )
};
export default UpdateAccount;