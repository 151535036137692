import { Alert, Button, CircularProgress, Dialog, DialogContent, InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";
import { json, Link, useLocation } from "react-router-dom";
import EmailIcon from '@mui/icons-material/Email';
import GMZbg from '../../../assets/images/SigninPageImages/BG_for_Login_12-10-2024.png'
import axios from "axios";
import { FaEye, FaEyeSlash } from "react-icons/fa";


const ForgotPassword = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const ResetToken = queryParams.get('token') ? queryParams.get('token') : "";
    const [View, setView] = useState('');
    const [Email, setEmail] = useState('');
    const [EmailError, setEmailError] = useState('');
    const [Password, setPassword] = useState('');
    const [PasswordError, setPasswordError] = useState('');
    const [ReEnterPassword, setReEnterPassword] = useState('');
    const [ReEnterPasswordError, setReEnterPasswordError] = useState('');
    const [Progress, setProgress] = useState(false);
    const SendEmilReq = () => {
        let error = false
        if (!Email) {
            error = true;
            setEmailError('Please fill the Email.')
        }
        if (Email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(Email)) {
            setEmailError('Please fill the Email in correct format like `example@gamizign.com` ')
            error = true;

        }
        if (!error) {
            ForgotPassword();

        }
    }
    // Send Email to reset password
    const ForgotPassword = async () => {
        setProgress(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_ENDPOINT}/forgot-password?email=${Email}`);
            if (response.status === 200) {
                setView('Password reset email sent');
            }
        } catch (error) {
            if (error.response.status === 404) {
                setFailedReset(`Sorry account not found , please try sign up.`);
            }else{
                setFailedReset(`Sorry we can not proccess reset password now, please try again later.`);
            }
            console.log(error);
        }finally{setProgress(false);}

    }
    // Validate password 8 chars and must one alphabetic char and make error live , show hide 
    const [showPassword, setShowPassword] = useState(false);
    const passwordHandler = (value) => {
        setPassword(value);
        if (!value) {
            setPasswordError('Please enter the Password.')
        } else if (value.length < 8) {
            setPasswordError('Password must be at least 8 characters long.');
        } else if (!/[a-zA-Z]/.test(value)) {
            setPasswordError('Password must contain at least one alphabetic character.(a-zA-Z)');
        } else if (value.length >= 8 && /[a-zA-Z]/.test(value)) {
            setPasswordError('Perfect ✔');
        }
    }
    const PaswworValidation = () => {
        let error = false;
        if (!Password) {
            setPasswordError('Please enter the Password.')
            error = true;
        } else if (Password.length < 8) {
            error = true;
        } else if (!/[a-zA-Z]/.test(Password)) {
            error = true;
        }
        if (Password !== ReEnterPassword) {
            error = true;
            setReEnterPasswordError('Password is not same please make sure same password in Re-enter.');
        }
        if (!error) {
            SendNewPassword();
        }
    }
    const SendNewPassword = async () => {
        setProgress(true);
        try {
            const ValidToken = await fetch(`${process.env.REACT_APP_ENDPOINT}/reset-password?token=${ResetToken}`,{method:'GET',headers:{'accept': 'application/json',}});            
            if (ValidToken.status === 200) {
                const resp = await fetch(`${process.env.REACT_APP_ENDPOINT}/reset-password?token=${ResetToken}&new_password=${Password}`, {
                    method: 'POST',
                    headers: {
                        'accept': 'application/json',
                        'Authorization': `Bearer ${ResetToken}`,
                    },
                    body:'',
                });
                if (resp.status === 200) {
                    setView('Password reset successful');
                    setProgress(false);
                }   
            }else{
                setFailedReset('Reset password token is not valid.');
            }
        } catch (error) {
            console.log(error);
            setProgress(false);
            setFailedReset(`Sorry we can not proccess reset password now, please try again later.`);
        } finally{setProgress(false);}
    }
    // On failed reset password dailog message
    const [FailedReset, setFailedReset] = useState(false);
    const closeDialog = () => {
        setFailedReset(false);
    }
    return (
        <div style={{ backgroundImage: `url(${GMZbg})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', height: '100vh' }}>
            <Link className="fixed left-5 top-5 border bg-gray-200 p-2 hover:bg-blue-200 rounded" to='/'>Back To Login</Link>
            <Dialog open={FailedReset} onClose={closeDialog}>
                <DialogContent><Alert severity="error">{FailedReset}</Alert></DialogContent>
                {FailedReset === 'Sorry account not found , please try sign up.' ? 
                <div className="flex justify-center"> 
                <Link to='/signup'><Button onClick={closeDialog}>SignUp Now</Button></Link>
                <Button onClick={closeDialog}>Okay</Button>
                </div> 
                : 
                <div className="flex justify-center"> <Button onClick={closeDialog}>Okay</Button></div>}
            </Dialog>
            <div className="flex justify-center sm:pt-40 pt-20 mx-3">
                <div className="border bg-white rounded-xl shadow-xl px-5">
                    {Progress && (
                        <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75 z-50">
                            <CircularProgress className="text-blue-800" />
                        </div>
                    )}
                    <p className="text-center mt-5 text-xl font-bold">Reset Password</p>
                    {ResetToken ?
                        (
                            <div>
                                {View === 'Password reset successful' ? (
                                    <div>
                                        <Alert severity="success">Password has been reset successfully.</Alert>
                                        <div className="flex justify-center my-3"><Button><Link to='/'>Go to Login</Link></Button></div>
                                    </div>
                                ) : (
                                    <div className="text-center">
                                        <TextField
                                            margin="dense"
                                            label="Password"
                                            name="hashed_password"
                                            type={showPassword ? 'text' : 'password'}
                                            fullWidth
                                            placeholder="Enter new password"
                                            value={Password}
                                            focused
                                            sx={{
                                                width: { sm: '30vw' },
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        border: '',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        border: '2px solid #4183d9',
                                                    },
                                                },
                                            }}
                                            onChange={(e) => { passwordHandler(e.target.value) }}
                                            InputProps={{
                                                // startAdornment: (
                                                //     <InputAdornment position="start">
                                                //         <KeyIcon style={{ color: '#4183d9' }} />
                                                //     </InputAdornment>
                                                // ),
                                                endAdornment: (
                                                    <InputAdornment sx={{ cursor: 'pointer' }} position="end">
                                                        <span onClick={() => setShowPassword(prev => !prev)} className="cursor-pointer">{showPassword ? <FaEye style={{ color: '#4183d9' }} size={24} /> : <FaEyeSlash style={{ color: '#4183d9' }} size={24} />}</span>
                                                    </InputAdornment>
                                                ),
                                                style: {
                                                    borderRadius: "10px",
                                                    fontSize: '20px',
                                                    color: '#4183d9', background: 'white',
                                                }
                                            }} />
                                        {PasswordError && <p style={{ color: PasswordError === 'Perfect ✔' ? 'green' : 'red' }}>{PasswordError}</p>}
                                       <div>
                                       <TextField
                                            margin="dense"
                                            label="Re-Enter password"
                                            name="hashed_password"
                                            type={showPassword ? 'text' : 'password'}
                                            fullWidth
                                            placeholder="Re-Enter password"
                                            value={ReEnterPassword}
                                            focused
                                            sx={{
                                                width: { sm: '30vw' },
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        border: '',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        border: ReEnterPasswordError ? '2px solid red' : '2px solid #4183d9',
                                                    },
                                                },
                                            }}
                                            onChange={(e) => { setReEnterPassword(e.target.value);setReEnterPasswordError('') }}
                                            InputProps={{
                                                style: {
                                                    borderRadius: "10px",
                                                    fontSize: '20px',
                                                    color:'#4183d9', background: 'white',
                                                }
                                            }} />
                                        {ReEnterPasswordError && <p style={{ color:'red' }}>{ReEnterPasswordError}</p>}
                                       </div>
                                        <div>
                                            <button onClick={PaswworValidation} className="my-5 rounded-xl bg-blue-500 hover:bg-green-500 text-white font-bold px-7 py-2">Reset password</button>
                                        </div>
                                    </div>
                                )}
                            </div>

                        ) : (
                            <div>
                                {View === 'Password reset email sent' ? (
                                    <div>
                                        <Alert severity="success">Password reset email sent to <b>{Email}.</b></Alert>
                                        <div className="flex justify-center my-3"><Button><Link to='https://mail.google.com/mail/'>Check Mail</Link></Button></div>
                                    </div>
                                ) : (
                                    <div className="text-center">
                                        <TextField
                                            margin="dense"
                                            label="Email"
                                            name="email"
                                            type="email"
                                            placeholder="Enter email"
                                            autoComplete="off"
                                            // fullWidth
                                            onChange={(e) => { setEmail(e.target.value); setEmailError('') }}
                                            sx={{
                                                marginTop: '20px',
                                                width: { sm: '30vw' },
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        border: '',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        border: '2px solid #4183d9',
                                                    },
                                                },
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <EmailIcon style={{ color: '#4183d9' }} />
                                                    </InputAdornment>
                                                ),
                                                style: {
                                                    borderRadius: "10px",
                                                    color: '#4183d9', background: 'white',
                                                    fontSize: '20px',
                                                }
                                            }}
                                        />
                                        {EmailError && <p style={{ color: 'red' }}>{EmailError}</p>}
                                        <div>
                                            <button onClick={SendEmilReq} className="my-5 rounded-xl bg-blue-500 hover:bg-green-500 text-white font-bold px-7 py-2">Submit</button>
                                        </div>
                                    </div>
                                )}
                            </div>

                        )}
                </div>
            </div>
        </div>
    )
};
export default ForgotPassword;