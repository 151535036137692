import React from "react";
import LoginHeading from "../../../../LoginHeading/LoginHeading";
import MetaTags from "../../../../GlobalComponents/MetaData";
import UpdateAccount from "./components/UpdateAccount";
import DeleteAccount from "./components/DeleteAccount";


const Settings = () => {
    const DeletAccount = localStorage.getItem("LearnersList");

    return (
        <div className='w-full'>
            <MetaTags title='Account settings' description='Update and delete Account settings page--' />
            <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'>
                <h3 className="text-xl">Settings</h3>
                <LoginHeading/>
                </div>
            <div className="m-7 bg-white py-10">
                {DeletAccount === 'show' ? (
                    <div>
                        <UpdateAccount/>
                        <DeleteAccount/>
                    </div>
                ) : (
                <div>
                    <UpdateAccount/>
                </div>
            )}
            </div>
        </div>
    )
};

export default Settings;