import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import GamizignHIcon from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Gamizign head icon.png'
import CourseLock from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/course locked icon.png'
import DeletQuestoin from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/delete icon.png'
import LearnerDone from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/learners done number icon.png'
import LearnerTotal from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/total learners number icon.png'
import BackIcon from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/back iconB.png'
import LoginHeading from "../../../../../../LoginHeading/LoginHeading";
import LoadingComponent from "../../../../../../GlobalComponents/GlobalLoading";
import CoursesSkeleton from "../CoursesSkeleton";
import MetaTags from "../../../../../../GlobalComponents/MetaData";
import { Tooltip } from "@mui/material";
import axiosInstance from "../../../../../../../axios";
import GetActivityIcon from "../../../../../../GlobalComponents/GetActivityIcon";

const HomeWork = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const course_id = queryParams.get('course_id');    

    const [SelectedCourse, setSelectedCourse] = useState(null)
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const getCourse = async () => {
            try {
                setLoading(true);

                const response = await axiosInstance.get(`${process.env.REACT_APP_ENDPOINT}/courses/${course_id}/`);
                setLoading(false);
                setSelectedCourse(response.data);
            } catch (error) {
                console.error('Error fetching courses:', error);
                // Handle the error if needed
            } finally {
                setLoading(false);
            }
        };
        getCourse();
    }, []);
    const navigate = useNavigate();
    const ActivityEvaluation = (activity, Course_name, Students,) =>{
        navigate(`/Course/ActivityEvaluation?course_id=${course_id}`, {state:{activity: activity, Course_name: Course_name, Students: Students, course_id: course_id}})
    }
    const ActivityCourseColors = ['#DBE6F5', '#E5F2E3', '#F5F9E6', '#FEFAE2', '#FEF7E2', '#FEF1DD', '#FDE4DC', '#EFE1EE'];

    return (
        <div className='w-full'>
            <MetaTags title={`Home Work of the ${SelectedCourse?.name} `} description='Gamizign Home Work page--' />
            <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'>
                <h3 className="text-xl">Students Homework</h3>
                <LoginHeading />
            </div>
            <div className="m-7 bg-white border-4 pb-5">
                <div className='flex justify-center static'><img className='absolute top-6' src={GamizignHIcon} alt="" /></div>
                <div className="p-5  bg-gray-50 border-b font-bold text-xl">Homework List</div>
                {loading ? (
                   <CoursesSkeleton/>
                ) : (
                    <div>
                        <div className=" grid grid-cols-1 px-5 mt-10 sm:grid sm:grid-cols-2 md:grid-cols-3  sm:px-5 xl:grid-cols-5 gap-5 2xl:px-40">
                            {(SelectedCourse)?(SelectedCourse.stages.map((stage, stageIndex)=>(stage.activities.map((activity, activityIndex)=>(
                                 <div className={`mt-5  ${activity.activityType === 'game' ? 'hidden' : ''}`}>
                                 <div className="border relative" style={{ borderRadius: '20px', height: "250px", backgroundColor: ActivityCourseColors[activityIndex % ActivityCourseColors.length] }}>
                                   <div className={`flex justify-center mt-3`}><img src={GetActivityIcon(activity.activityType)} alt="" /></div>
                                   <div className="bg-gray-900 text-white  absolute inset-x-0 bottom-0" style={{ borderRadius: '20px', height: "100px" }}>
                                     <div className="flex justify-center"><img src={CourseLock} alt="" style={{ marginTop: "-25px" }} /></div>
                                     <Tooltip title='Evaluate'><p onClick={()=>{ActivityEvaluation(activity , SelectedCourse.name, SelectedCourse.students)}} className="flex justify-center pt-2 text-sm cursor-pointer"><b>{activity.name}</b></p></Tooltip>
                                     <p className="flex justify-center mt-2 text-sm"><b>{SelectedCourse.name}</b></p>
                                     {/* <p className="flex justify-center mt-2 text-sm"><b>{activity.stageName}</b></p> */}
                                   </div>
                                 </div>
                                 <div className="flex gap-7 justify-center mt-2 border py-2" style={{ borderRadius: "10px" }}>
                                   {/* <img src={CourseNotActive} alt="" /> */}
                                   {/* <img src={EditQuestoin} alt="" /> */}
                                   <img className="cursor-pointer" src={DeletQuestoin} alt="" 
                                //    onClick={() => { setActivityName(activity.name); setActivity_id(activity.activity_id); setcourseIndex(CourseIndex); setStageIndex(stageIndex); setActivityIndex(activityIndex); openActivityBotModal() }}
                                    />
                                 </div>
                                 <div className="flex gap-5 justify-center border py-2" style={{ borderRadius: "10px" }}>
                                   <span className="relative"><img src={LearnerTotal} alt="" /><span className="absolute inset-0 left-2.5 top-0.5">{activity.analyticsList.length}</span> </span>
                                   <p>Learners</p>
                                   <span className="relative"><img src={LearnerDone} alt="" /><span className="absolute inset-0 left-2.5 top-0.5">{SelectedCourse.students.length}</span> </span>
                                 </div>
                               </div>
                            ))
                                
                            ))):(<div className="font-bold text-xl">No activity in <b>{SelectedCourse?.name}</b> course</div>)}
                        </div>
                    </div>
                )}
                 <div className="flex justify-center mt-5">
                    <Link to={`/Course?course_id=${SelectedCourse?.course_id}`}><button className='BackBtn flex p-2 px-7 hover:bg-blue-200'><b> Back </b><img className='ml-5 mt-1' src={BackIcon} alt="Back" /></button></Link>
                </div>
               
            </div>
        </div>
    )
};

export default HomeWork;