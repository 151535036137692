import React, { useEffect, useState } from "react";
import RankIcon from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/AnalyticsCourse/rank icon.png'
import AccuracyIcon from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/AnalyticsCourse/accurecy icon.png'
import ScoreIcon from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/AnalyticsCourse/score icon.png'
import AttemptIcon from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/AnalyticsCourse/attemped icon.png'
import { Skeleton, Tooltip } from "@mui/material";
import getAvtar from "../../The Gamizign World/Components/GetAvtar";
import axiosInstance from "../../../../../../axios";

const PlayersAnalysis = ({ selectedCourse, viewType, gridOn }) => {
    const [analyticsData, setAnalyticsData] = useState([]);
    const [Loading, setLoading] = useState(true);    
    useEffect(() => {
        setLoading(true);
        const fetchData = async (student_id) => {
            try {
                const response = await axiosInstance.get(`${process.env.REACT_APP_ENDPOINT}/analytics/courses/students/${student_id}?course_id=${selectedCourse.course_id}`);
                if (response.status === 200) {
                    setLoading(false);                    
                }else{
                    setLoading(false);
                }
                // const data = await response.data;
                return response.data;
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
                return null;
            }
        };

        const fetchAnalyticsData = async () => {
            if (selectedCourse) {
                const results = await Promise.all(selectedCourse.students.map(student => fetchData(student.student_id)));
                setAnalyticsData(results);
            }
        };

        fetchAnalyticsData();
    }, [selectedCourse]);
    //   combine arrays for ranking 
    const mergeAndSortArrays = (analyticsData, students) => {
        if (!analyticsData || !students || analyticsData.length === 0 || students.length === 0) {
            return [];
        }
        // Ensure both arrays have the same length
        if (analyticsData.length !== students.length) {
            throw new Error("Arrays must have the same length");
        }

        // Merge the arrays based on index
        if (analyticsData) {
            const mergedArray = analyticsData.map((data, index) => {
                return { ...data, ...students[index] };
            });
            mergedArray.sort((a, b) => b.score - a.score);
            return mergedArray;
        }
    };
    const mergedAndSortedStudents = mergeAndSortArrays(analyticsData, selectedCourse?.students);
    // time formation 
    function formatTime(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        return `${String(hours).padStart(2, '0')}h:${String(minutes).padStart(2, '0')}m:${String(
            Math.floor(remainingSeconds)
        ).padStart(2, '0')}s`;
    }

    return (
        <div>
         {Loading ? (
            <div>
              <div className='mx-3 my-5 sm:hidden'>
                <Skeleton className='mt-3' animation='wave' variant="rounded" width='100%' height={250} />
                <Skeleton className='mt-3' animation='wave' variant="rounded" width='100%' height={250} />
                <Skeleton className='mt-3' animation='wave' variant="rounded" width='100%' height={250} />
                <Skeleton className='mt-3' animation='wave' variant="rounded" width='100%' height={250} />
                <Skeleton className='mt-3' animation='wave' variant="rounded" width='100%' height={250} />
                <Skeleton className='mt-3' animation='wave' variant="rounded" width='100%' height={250} />
              </div>
              <div className='hidden sm:block'>
                <div className={`grid md:grid-cols-2 xl:grid-cols-3 gap-3 mx-3 my-5`}>
                  <Skeleton animation='wave' variant="rounded" width='100%' height={100} />
                  <Skeleton animation='wave' variant="rounded" width='100%' height={100} />
                  <Skeleton animation='wave' variant="rounded" width='100%' height={100} />
                  <Skeleton animation='wave' variant="rounded" width='100%' height={100} />
                  <Skeleton animation='wave' variant="rounded" width='100%' height={100} />
                  <Skeleton animation='wave' variant="rounded" width='100%' height={100} />
                </div>
              </div>
            </div>
          ) : (<div>
            {/* Cards view */}
            <div className={`grid md:grid-cols-2 xl:grid-cols-3 gap-3 mx-3 my-5 ${viewType === gridOn ? '' : 'hidden'}`}>
                {mergedAndSortedStudents.map((student, index) => (<div>
                    <div className={`${student.score === 0 && 'hidden'}`}>
                        <div className={`bg-gray-100 p-5`} style={{ borderRadius: '25px' }}>
                            <div className='flex'>
                                <div>
                                    <p><b>{student.firstName}</b></p>
                                    <b>{student.lastName}</b>
                                </div>
                                <div className='grid sm:grid-cols-4 gap-3 bg-white w-full p-3 ms-5' style={{ borderRadius: '20px' }}>
                                    {/* Rank of player */}
                                    <div className='mt-1.5 flex justify-center'>
                                    <Tooltip title="Player Rank"><div className='relative'><img className='' width={40} src={RankIcon} alt="" /><span className='absolute inset-0 mt-2 ms-4' style={{}}>{index + 1}</span></div></Tooltip>
                                    </div>
                                    {/* Accurancy */}
                                    <div className=''>
                                    <Tooltip title="Accuracy"><div className='flex justify-center'><img src={AccuracyIcon} alt="" /></div>
                                        <div className='flex justify-center'>
                                            {student.correctAnswers > 0 ? (
                                                <div>{((student.correctAnswers / (student.correctAnswers + student.incorrectAnswers)) * 100).toFixed(1)}%</div>
                                            ) : <>0.0%</>}
                                        </div></Tooltip>
                                    </div>
                                    {/* Score */}
                                    <div className=''>
                                    <Tooltip title="Score">
                                        <div className='flex justify-center'><img src={ScoreIcon} alt="" /></div>
                                        <div className='flex justify-center'>
                                            {student.score}
                                        </div>
                                        </Tooltip>
                                    </div>
                                    {/* Sessions */}
                                    <div className=''>
                                    <Tooltip title="Sessions">
                                        <div className='flex justify-center'><img src={AttemptIcon} alt="" /></div>
                                        <div className='flex justify-center'>
                                            {student.sessions}
                                        </div>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                            <p className='flex justify-center mt-2'>{student.email}</p>
                        </div>
                    </div>
                </div>))}
            </div>
            {/* Table view */}
            <div className={`${viewType === gridOn ? 'hidden' : ''}`}>
                <div class="relative mx-2 sm:mx-5 mt-5 overflow-x-auto max-w-80 sm:max-w-full">
                    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead class="text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th className='cursor-pointer' scope="col" class="px-3 py-3">
                                    Name
                                </th>
                                <th scope="col" class="px-3 py-3">
                                Avatar
                                </th>
                                <th scope="col" class="px-3 py-3">
                                    Email
                                </th>
                                <th scope="col" class="px-3 py-3">
                                    Score
                                </th>
                                <th scope="col" class="px-3 py-3">
                                    Accuracy
                                </th>
                                <th scope="col" class="px-3 py-3">
                                    Sessions
                                </th>
                                <th scope="col" class="px-3 py-3">
                                    Avg Time
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {mergedAndSortedStudents.map((student, studentIndex) => (
                                <tr class="even:bg-white even:dark:bg-gray-900 odd:bg-gray-50 odd:dark:bg-gray-800 border-b dark:border-gray-700">
                                    <th scope="row" class="px-3 py-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        {student.firstName} {student.lastName}
                                    </th>
                                    <td class="py-2">
                                    <img className="h-16" src={getAvtar(student.avatar_id)} alt="avatar" />
                                    </td>
                                    <td class="px-3 py-6">
                                        {student.email}
                                    </td>
                                    <td class="px-3 py-6">
                                        {student.score}
                                    </td>
                                    <td class="px-3 py-6">
                                        {student.correctAnswers > 0 ? <>{((student.correctAnswers / (student.correctAnswers + student.incorrectAnswers)) * 100).toFixed(2)}</> : <>0.0</>}%
                                    </td>
                                    <td class="px-3 py-6">
                                        {student.sessions}
                                    </td>
                                    <td class="px-3 py-6">
                                        {student.totalTime > 0 ? <>{formatTime(student.totalTime)}</> : <>00h:00m:00s</>}
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>
            </div>
        </div>)}
        </div>

    );
};
export default PlayersAnalysis;
