import React, { useEffect, useState } from "react";
import axiosInstance from "../../../../../../../axios";
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import DatePicker from "react-datepicker";
import { Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";


const ChangCourseInfo = ({ UpdatedCourse, setUpdatedCourse, DialogCourseInfo, submitCourse, CourseDateConverted, courseName, copyCourse }) => {
    const [classes, setClasses] = useState();
    const [Progrees, setProgrees] = useState(false);
    const [Grade, setGrade] = useState('');
    const [mainTopic, setMainTopic] = useState('');
    const [nameError, setNameError] = useState('');
    const [gradeError, setGradeError] = useState('');
    const [mainTopicError, setMainTopicError] = useState('');
    const [date, setDate] = useState(new Date());
    const [showDropdown, setShowDropdown] = useState(false);
    
    
    const getClasses = async () => {
        setProgrees(true);
        var organization = parseInt(localStorage.getItem('organization'));
        const response = await axiosInstance.get(`${process.env.REACT_APP_ENDPOINT}/organization/${organization}/classes/`)
        setClasses(response.data.classes);
        setProgrees(false);
    }
    useEffect(() => {
        getClasses();
    }, []);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setUpdatedCourse((prevState) => ({ ...prevState, [name]: value }));
    };
    const handleDateChange = (selectedDate) => {
        setDate(selectedDate);
        setShowDropdown(false);
        const finishingDateValue = new Date(new Date(selectedDate).setMonth(new Date(selectedDate).getMonth() + 1));
        if (UpdatedCourse.stages.length > 1) {
            setUpdatedCourse((prevState) => ({ ...prevState, [UpdatedCourse.dateCreated ? 'dateCreated' : 'startingDate'] : (selectedDate.$d).toLocaleString('eng-GB').split(',')[0].trim(),
                stages: prevState.stages.map((stage) => ({
                    ...stage,
                    startingDate: selectedDate.$d.toLocaleString('en-GB').split(',')[0].trim(),
                    finishingDate: finishingDateValue.toLocaleString('en-GB')
                      .split(',')[0]
                      .trim(),
                  })),
             }));
        }else{
            setUpdatedCourse((prevState) => ({ ...prevState, [UpdatedCourse.dateCreated ? 'dateCreated' : 'startingDate'] : (selectedDate.$d).toLocaleString('eng-GB').split(',')[0].trim(),
                stages: [
                    {
                      ...prevState.stages[0],
                      startingDate: selectedDate.$d.toLocaleString('en-GB').split(',')[0].trim(),
                      finishingDate: finishingDateValue.toLocaleString('en-GB').split(',')[0].trim(),
                    },
                    ...prevState.stages.slice(1)
                  ]
             }));
        }
    };
    // validation for empty Course name 
    const Validation = () => {
        let error = false;
        if (!UpdatedCourse.name) {
            error = true;
            setNameError('Please fill in the course name.');
        } 
        if (!Grade) {
            error = true;
            setGradeError('Please fill in the course Grade.')
        }
         if (!mainTopic) {
            error = true;
            setMainTopicError('Please fill in the course mainTopic.')
        }
        
        if (!error) {
            // DialogCourseInfo();
            submitCourse();
        }
    }
    // Change starting date of course and stage initially auto
    const changeDateAuto = () => {
        // Auto change date
        const finishingDateValue = new Date(new Date(date).setMonth(new Date(date).getMonth() + 1));
        setUpdatedCourse((prevState) => ({ ...prevState, [UpdatedCourse.dateCreated ? 'dateCreated' : 'startingDate'] : date.toLocaleString('eng-GB').split(',')[0].trim() }));
        if (UpdatedCourse.stages.length > 1) {
            setUpdatedCourse((prevState) => ({
                ...prevState,
                stages: prevState.stages.map((stage) => ({
                  ...stage,
                  startingDate: date.toLocaleString('en-GB').split(',')[0].trim(),
                  finishingDate: finishingDateValue.toLocaleString('en-GB')
                    .split(',')[0]
                    .trim(),
                })),
              }));
        }else{
            setUpdatedCourse((prevState) => ({ ...prevState,  stages: [
                {
                  ...prevState.stages[0],
                  startingDate: date.toLocaleString('en-GB').split(',')[0].trim(),
                  finishingDate: finishingDateValue.toLocaleString('en-GB').split(',')[0].trim(),
                },
                ...prevState.stages.slice(1)
              ]}));
        };
        // Error error if main topic is not or grade
        if (UpdatedCourse.grade !== 'R') {
            setGrade(UpdatedCourse.grade);
        }
        if (UpdatedCourse.mainTopic !== 'R') {
            setMainTopic(UpdatedCourse.mainTopic);
        }
    } 
    useEffect(()=>{
        if (copyCourse) {
            changeDateAuto();
        }
    },[])

    return (
        <div className="w-full">
            <h1 className="text-center text-xl font-bold py-2">Make a copy of your previous course named is <b>"{courseName}"</b> </h1>
            {/* course name */}
            <TextField id='CourseName'
                margin="dense"
                label="Course Title"
                name="name"
                type="text"
                value={UpdatedCourse.name}
                autoComplete='off'
                placeholder="Course name"
                onChange={(e) => { handleChange(e); setNameError('') }}
                fullWidth
                sx={{
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            border: `${!UpdatedCourse.name ? '2px solid #FDE4DC' : '2px solid #F3F6F6'}`,
                        },
                        '&.Mui-focused fieldset': {
                            border: '2px solid #DBE6F5',
                        },
                        '&:hover fieldset': {
                            border: '2px solid #DBE6F5',
                        },
                    },
                }}
                InputProps={{
                    style: {
                        borderRadius: "10px",
                        color: '#2E4EB5',
                        fontSize: '20px',
                        background: `${UpdatedCourse.name ? '#F3F6F6' : 'white'}`
                    }
                }}
            />
            {nameError && <p className="text-red-500">{nameError}</p>}
            {/* date */}
            <div className='my-5 w-full'>
                <div className="relative w-full">
                    <button
                        className="flex items-center justify-center -mt-1 rounded-full focus:outline-none"
                        type='button'
                        onMouseEnter={() => setShowDropdown(true)}
                        onMouseLeave={() => setShowDropdown(false)}
                    >
                        {/* <label htmlFor="date">Starting Date: </label>   */}
                        <DatePicker id='StartingDate' disabled className='text-blue-800 rounded bg-gray-100 cursor-pointer px-3 py-1 w-full' minDate={new Date()} selected={date.$d ? date.$d : date} onChange={(date) => handleDateChange(date)} dateFormat={"dd/MM/yyyy"} />
                    </button>
                    {showDropdown && (
                        <div className="absolute text-black -right-12 -left-12 sm:right-0 sm:left-0 z-10  bg-white border border-gray-200 rounded shadow-lg top-full" onMouseEnter={() => setShowDropdown(true)} onMouseLeave={() => setShowDropdown(false)}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DateCalendar', 'DateCalendar']}>

                                    <DemoItem label="Starting Date">
                                        <DateCalendar
                                            onChange={(newValue) => handleDateChange(newValue)}
                                            minDate={dayjs()}
                                            value={dayjs(CourseDateConverted)}
                                            dateFormat={"dd/MM/yyyy"}
                                        />

                                    </DemoItem>
                                </DemoContainer>
                            </LocalizationProvider>
                        </div>
                    )}
                </div>
            </div>
            {/* Select Grade */}
            <div>
                {Progrees ? <div className='flex justify-center mt-4'><CircularProgress className="text-blue-800" /></div>
                    :
                    <FormControl fullWidth
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    border: '2px solid #F3F6F6',
                                },
                                '&.Mui-focused fieldset': {
                                    border: '2px solid #DBE6F5',
                                },
                                '&:hover fieldset': {
                                    border: '2px solid #DBE6F5',
                                },
                            },
                        }}>
                        <InputLabel>Grade</InputLabel>
                        {classes && Array.isArray(classes) && classes.length > 0 ? (<Select
                            labelId="Grade" id='Grade'
                            name="grade"
                            label="Grade"
                            value={parseInt(UpdatedCourse.grade)}
                            onChange={(e)=>{handleChange(e);setGradeError(''); setGrade(e.target.value)}}
                            style={{ borderRadius: '10px', color: '#2E4EB5', background: `${Grade ? '#F3F6F6' : 'white'}` }}
                        >
                            {classes.map((classItem) => (
                                <MenuItem key={classItem.class_id} value={classItem.class_id}>
                                    {classItem.class_name}
                                </MenuItem>
                            ))}
                        </Select>) : (<p>No classes available</p>)}
                    </FormControl>}
            </div>
            {gradeError && <p className="text-red-500">{gradeError}</p>}
            <div className="mt-5">
                <FormControl fullWidth
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border:  '2px solid #F3F6F6',
                            },
                            '&.Mui-focused fieldset': {
                                border: '2px solid #DBE6F5',
                            },
                            '&:hover fieldset': {
                                border: '2px solid #DBE6F5',
                            },
                        },
                    }}>
                    <InputLabel>Main Topic</InputLabel>
                    <Select
                        labelId="MainTopic" id='MainTopic'
                        name="mainTopic"
                        label="Main Topic"
                        value={UpdatedCourse.mainTopic}
                        onChange={(e)=>{handleChange(e);setMainTopicError(''); setMainTopic(e.target.value)}}
                        style={{ borderRadius: "10px", color: '#2E4EB5', background:  '#F3F6F6' }}
                    // value={MainTopic}
                    >
                        <MenuItem value='Language Arts'>Language Arts</MenuItem>
                        <MenuItem value='Math' >Math</MenuItem>
                        <MenuItem value='Science'>Science</MenuItem>
                        <MenuItem value='Social'>Social</MenuItem>
                    </Select>
                </FormControl>
            </div>
            {mainTopicError && <p className="text-red-500">{mainTopicError}</p>}
            <div className='flex justify-center mt-5'>
                <Button onClick={() => { Validation() }} color="primary">
                    Submit
                </Button>
                <Button onClick={() => { DialogCourseInfo() }} color="primary">
                    Cancel
                </Button>
            </div>
        </div>
    )
};
export default ChangCourseInfo; 