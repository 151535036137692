import React from "react";


const ServicePlanIndividual = () => {
    return(
        <div>
            <div className="flex justify-center">
                <div className="mt-10 md:mx-80 shadow-xl bg-transparent md:p-5 p-2">
                    <h1 className="text-3xl font-bold ">Gamizign Service Plans: What's in it for individual teacher & What's included?</h1>
                    {/* Free Plan */}
                    <div className="mt-5">
                        <h1 className="text-sky-500 text-3xl">Free Plan: A perfect fit for new teachers seeking exploring & free option</h1>
                        <h1 className="text-xl">What's included?</h1>
                        <div className="ms-10">
                            <p className="md:text-xl"><b>●</b> Available at no cost</p>
                            <p className="md:text-xl"><b>●</b> Provides basic features like flash cards, timing challenges, leaderboards, MCQ questions, and a basic dashboard</p>
                            <p className="md:text-xl"><b>●</b> Two single-courses and two question lists</p>
                        </div>
                    </div>
                    {/* Basic Plan */}
                    <div className="mt-5">
                        <h1 className="text-sky-500 text-3xl">Basic Plan: Best for new users and teachers on a budget</h1>
                        <h1 className="text-xl">What's included?</h1>
                        <div className="ms-10">
                            <p className="md:text-xl"><b>●</b> Essential features for creating engaging lessons</p>
                            <p className="md:text-xl"><b>●</b> Spin2Win games, achievements, and live emojis</p>
                            <p className="md:text-xl"><b>●</b> Export dashboard to CSV and notification service</p>
                        </div>
                    </div>
                    {/* Standard Plan */}
                    <div className="mt-5">
                        <h1 className="text-sky-500 text-3xl">Standard Plan: Best for teachers looking to enhance their classroom engagement</h1>
                        <h1 className="text-xl">What's included?</h1>
                        <div className="ms-10">
                            <p className="md:text-xl"><b>●</b> Everything in the Basic Plan, plus advanced feature</p>
                            <p className="md:text-xl"><b>●</b> AI question generation, chat, and arcade games</p>
                            <p className="md:text-xl"><b>●</b> Export questions to PDF, reorder/essay/image questions, URL/image/PDF activities, and Add student by CSV file</p>
                        </div>
                    </div>
                    {/* Premium Plan */}
                    <div className="mt-5">
                        <h1 className="text-sky-500 text-3xl">Premium Plan: Best for teachers aiming for a fully immersive with exceptional learning environments</h1>
                        <h1 className="text-xl">What's included?</h1>
                        <div className="ms-10">
                            <p className="md:text-xl"><b>●</b> All features from the Standard Plan, plus premium features</p>
                            <p className="md:text-xl"><b>●</b> Audio/video activities, customized badges, and multiplayer capabilities</p>
                            <p className="md:text-xl"><b>●</b> Play2Win store and advanced dashboard</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};
export default ServicePlanIndividual;