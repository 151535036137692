import { Skeleton } from "@mui/material";
import React from "react";


const QuestionsListsSkeleton = () => {
    return (
        <div className={`flex flex-wrap gap-4 my-3 px-5 justify-center grid grid-cols-1 lg:grid-cols-3 sm:grid-cols-2`}>
        <div className="relative w-full">
            <Skeleton animation='wave' variant="rounded" width='100%' height={90} />
            <div className="flex justify-center">
                <Skeleton className="absolute bottom-3" animation='wave' variant="rounded" width={90} height={30} />
            </div>
        </div>
        <div className="relative">
            <Skeleton animation='wave' variant="rounded" height={90} />
            <div className="flex justify-center">
                <Skeleton className="absolute bottom-3" animation='wave' variant="rounded" width={90} height={30} />
            </div>
        </div>
        <div className="relative">
            <Skeleton animation='wave' variant="rounded" height={90} />
            <div className="flex justify-center">
                <Skeleton className="absolute bottom-3" animation='wave' variant="rounded" width={90} height={30} />
            </div>
        </div>
        <div className="relative">
            <Skeleton animation='wave' variant="rounded" height={90} />
            <div className="flex justify-center">
                <Skeleton className="absolute bottom-3" animation='wave' variant="rounded" width={90} height={30} />
            </div>
        </div>
        <div className="relative">
            <Skeleton animation='wave' variant="rounded" height={90} />
            <div className="flex justify-center">
                <Skeleton className="absolute bottom-3" animation='wave' variant="rounded" width={90} height={30} />
            </div>
        </div>
        <div className="relative">
            <Skeleton animation='wave' variant="rounded" height={90} />
            <div className="flex justify-center">
                <Skeleton className="absolute bottom-3" animation='wave' variant="rounded" width={90} height={30} />
            </div>
        </div>
        <div className="relative">
            <Skeleton animation='wave' variant="rounded" height={90} />
            <div className="flex justify-center">
                <Skeleton className="absolute bottom-3" animation='wave' variant="rounded" width={90} height={30} />
            </div>
        </div>
        <div className="relative">
            <Skeleton animation='wave' variant="rounded" height={90} />
            <div className="flex justify-center">
                <Skeleton className="absolute bottom-3" animation='wave' variant="rounded" width={90} height={30} />
            </div>
        </div>
        <div className="relative">
            <Skeleton animation='wave' variant="rounded" height={90} />
            <div className="flex justify-center">
                <Skeleton className="absolute bottom-3" animation='wave' variant="rounded" width={90} height={30} />
            </div>
        </div>

    </div>
    )
};
export default QuestionsListsSkeleton;