import React, { useState } from "react";
import userIcon from '../../assets/icons/userIcon.png'
import { Button, Dialog, DialogActions, DialogContent, Alert, Slide, Tooltip, styled, Switch, FormControlLabel } from '@mui/material';
import HelpIcon from '../../assets/icons/helpIcon.png'
import { useNavigate } from "react-router-dom";

const LoginHeading = ({objects, setMode, mode}) => {
    var inst_name = localStorage.getItem("name");
    var inst_Email = localStorage.getItem("email");
    const LearnersList = localStorage.getItem("LearnersList");
    const navigate = useNavigate();

    const [showDropdown, setShowDropdown] = useState(false);
    const [IsLogout, setIsLogout] = useState(false);

    const HandllogoutCancel = () =>{
        setIsLogout(false);
    };
    const logout = () =>{
        localStorage.clear(); window.location.href = "/";
        setIsLogout(false);
        sessionStorage.clear();
    };

    const MaterialUISwitch = styled(Switch)(({ theme }) => ({
        width: 62,
        height: 34,
        padding: 7,
        '& .MuiSwitch-switchBase': {
          margin: 1,
          padding: 0,
          transform: 'translateX(6px)',
          '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
              backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                '#fff',
              )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
            },
            '& + .MuiSwitch-track': {
              opacity: 1,
              backgroundColor: '#aab4be',
              ...theme.applyStyles('dark', {
                backgroundColor: '#8796A5',
              }),
            },
          },
        },
        '& .MuiSwitch-thumb': {
          backgroundColor: '#001e3c',
          width: 32,
          height: 32,
          '&::before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
              '#fff',
            )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
          },
          ...theme.applyStyles('dark', {
            backgroundColor: '#003892',
          }),
        },
        '& .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: '#aab4be',
          borderRadius: 20 / 2,
          ...theme.applyStyles('dark', {
            backgroundColor: '#8796A5',
          }),
        },
      }));

    return (
        <div>
            <div className="text-xl absolute  right-12 lg:right-5 flex">
               <div className={`hidden sm:block ${mode === 'light' ? 'text-gray-700' : ''}`}> {inst_Email}
                ({inst_name})</div>
                <div className="relative">
                    {/*  */}
                    <Dialog className="lg:ms-56" open={IsLogout} onClose={HandllogoutCancel}>
                    <DialogContent>
                        <Alert
                            severity= 'warning'
                            >
                                Are you sure you want to logout?
                        </Alert>
                    </DialogContent>
                        <div className='flex justify-center'>
                            <Button onClick={logout} color="primary">
                                Yes
                            </Button>
                            <Button onClick={HandllogoutCancel} color="primary">
                                No
                            </Button>
                        </div>
                </Dialog>
                    {/* Button with dropdown toggle */}
                    <button
                        className="flex items-center justify-center w-8 h-8  hover:bg-gray-300 rounded-full focus:outline-none"
                        onMouseEnter={() => setShowDropdown(true)}
                        onMouseLeave={() => setShowDropdown(false)}
                    >
                        <img width={30} src={userIcon} alt="" />
                    </button>

                    {/* Dropdown menu */}
                    {showDropdown && (
                        <div className="absolute text-black right-0 z-10 w-40 py-2 bg-white border border-gray-200 rounded shadow-lg top-full" onMouseEnter={() => setShowDropdown(true)} onMouseLeave={() => setShowDropdown(false)}>
                            {/* Option 1 */}
                            <button className="flex items-center w-full px-4 py-2 hover:bg-gray-100 focus:outline-none">
                                Profile
                            </button>

                            {/* Option 2 */}
                             <button onClick={()=>navigate('/Dashboard/settings')} className="flex items-center w-full px-4 py-2 hover:bg-gray-100 focus:outline-none">
                                Settings
                            </button>

                            {/* Option 3 */}
                            <button className="flex items-center w-full px-4 py-2 hover:bg-gray-100 focus:outline-none"
                                onClick={() => {setIsLogout(true)}}>
                                Logout
                            </button>
                        </div>
                    )}
                </div>
                <Tooltip title='Help'><button type="button" className="cursor-pointer flex items-center justify-center w-8 h-8 hover:bg-gray-300 rounded-full"
                 onClick={()=>objects((prevImage) => (prevImage === 1 ? 5 : 1))}>
                    <img width={30} src={HelpIcon} alt="help" /></button></Tooltip>
          {/* <Tooltip title={mode === 'light' ? 'DarkMode' : 'LightMode'}>
            <label className="-mt-2 -me-5" onClick={() => setMode((prevImage) => (prevImage === 'light' ? 'dark' : 'light'))} htmlFor="ViewMode">
              <FormControlLabel id="ViewMode"
                control={<MaterialUISwitch sx={{ m: 1 }} checked={mode !== 'light'} />}
              /></label></Tooltip> */}
            </div>
        </div>
    )
};
export default LoginHeading;
