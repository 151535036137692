import React, { useEffect, useRef, useState } from "react";
import Modal from 'react-modal'
// Images
import MultiActivity from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch3/Activity-Type/multiactivity  icon hover off.png'
import Image from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/image icon _ multi activity.png'
import Video from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/video icon _ multi activity.png'
import Audio from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/audio icon _ multi activity.png'
import Text from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/assay icon _ multi activity.png'
import RankOrProccess from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/rank or process icon _ multi activity.png'
import MCQ from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/MSQ.png'
// Question Card images 
import AddNewActivity from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/Add New Manual Question icon.png'
import BackIcon from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/back iconB.png'
import SaveIcon from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/save icon.png'
import DragDropIcon from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/Drag & Dorp fixed color icon.png'
import UploadIcon from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/Upload a File fixed color icon.png'
import MicUploadIcon from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/Audio icon.png'
import ImageUploadIcon from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/image icon hover off color.png'
import VideoUploadIcon from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/video icon hover off color.png'
import VoiceRecorderIcon from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/Voice Recorder icon.png'
import ArrowUpIcon from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/double arrow - up.png'
// 
import AlertIcon from '../../../../../../../assets/icons/Alert-icon.png'

import { Link, useLocation, useNavigate } from "react-router-dom";
import LoginHeading from "../../../../../../LoginHeading/LoginHeading";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Alert, Button, CircularProgress, Dialog, DialogContent, Tooltip } from "@mui/material";
import Equation from "./equation";
import axios from "axios";
import QuestionComponent from "../../../QuestionList/ReuseableQuestionCard";
import MetaTags from "../../../../../../GlobalComponents/MetaData";
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import axiosInstance from "../../../../../../../axios";



const MultiActivy = ({ resetActivityTypemain }) => {
    const [mathText, setMathText] = useState('')
    const {courses, courseType, course, courseFormatDate, activity, stageIndex, finishingDate, stratingDate } = useLocation().state;
    const [latex, setLatex] = useState('')
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileVideo, setSelectedFileVideo] = useState(null);
    const [selectedFileAudio, setSelectedFileAudio] = useState(null);
    const [MultipleTypeQuestions, setMultipleTypeQuestions] = useState([]);
    const [SelecteQuestionType, SetSelecteQuestionType] = useState(null);
    const [progress, setIsProgress] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [ErrorMessage, SetErrorMessage] = useState('Kindly fill all the fields of the last question before adding new one question!');
    // Activity formation
    const [dateCreated, setDateCreated] = useState(moment().format("DD/MM/YYYY HH:mm:ss"));
    const [startingDate, setStartingDate] = useState(courseFormatDate);
    const [activityData, setActivityData] = useState({
        name: `Multi Activity ${course.stages[stageIndex].activities.length + 1}`,
        activityType: 'multiActivity',
        instructor_id: localStorage.getItem("instructor_id"),
        activity_file_name: "",
        description: "",
        scoreVolume: 50,
        url: "",
        gameType: null,
        dateCreated: dateCreated,
        createdBy: localStorage.getItem("name"),
        answer_requirement: "",
        answer_exact: "",
        activity_case: "",
        actionType: 1,
        questionList_id: null,
        questions: MultipleTypeQuestions,
    });
    useEffect(() => {
        setActivityData(prevState => ({
            ...prevState,
            questions: MultipleTypeQuestions,
        }));
    }, [MultipleTypeQuestions]);
    const HandelNameActivity = (event) => {
        setActivityData(prevState => ({
            ...prevState,
            name: event.target.value
        }));
    };
    // date formation
    const [date, setDate] = useState(courseFormatDate);
    const [stageFormatDate, setstageFormatDate] = useState(course.stages[stageIndex].startingDate == null ? courseFormatDate : date);
    const handleDateChange = (selectedDate) => {
        setDate(selectedDate);
        // setstageFormatDate(selectedDate);
    };
    const navigate = useNavigate();
    const HanldeBackActivityType = () => {
        navigate('/Dashboard/activityType', { state: {courses:courses, courseType: courseType, course: course, courseFormatDate: courseFormatDate, stratingDate: stratingDate, finishingDate: finishingDate  } });
    };
    // validation for add , coopy or save question
    const validation = (lastQuestion) => {
        if (lastQuestion.questionType === 'essay'
            ? (lastQuestion.question !== '' || lastQuestion.questionImage) && (lastQuestion.answer1 !== '' || lastQuestion.answer1Image)
            : (lastQuestion.question !== '' || lastQuestion.questionImage) &&
            (lastQuestion.answer1 !== '' || lastQuestion.answer1Image) &&
            (lastQuestion.answer2 !== '' || lastQuestion.answer2Image) &&
            (lastQuestion.answer3 !== '' || lastQuestion.answer3Image) &&
            (lastQuestion.answer4 !== '' || lastQuestion.answer4Image)) {
            return true;
        } else { return false };

    }
    // Save course and validation
    const HandelSaveMultiActivity = async () => {
        const lastQuestion = MultipleTypeQuestions[MultipleTypeQuestions.length - 1];
        if (MultipleTypeQuestions.length === 0) {
            setCoursebotmodalIsOpen(true);
            console.error('Please add a question befor saving.');
            SetErrorMessage('Please add a question befor saving.');
        } else if (validation(lastQuestion)) {
            // Save question list on endpoint
            setCoursebotmodalIsOpen(false);
            setIsProgress(true);
            try {
                var instructor_id = localStorage.getItem("instructor_id");
                var inst_name = localStorage.getItem("name");
                const today = new Date();
                const yyyy = today.getFullYear();
                let mm = today.getMonth() + 1; // Months start at 0!
                let dd = today.getDate();
                let hh = today.getHours();
                let min = today.getMinutes();
                let ss = today.getSeconds();

                if (dd < 10) dd = '0' + dd;
                if (mm < 10) mm = '0' + mm;

                const formattedToday = dd + '/' + mm + '/' + yyyy
                var req = {
                    "name": `${activityData.name} Question list`,
                    "dateCreated": formattedToday,
                    "createdBy": inst_name,
                    "lastModified": formattedToday,
                    "mainTopic": course.mainTopic,
                    "questions": MultipleTypeQuestions,
                }

                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(req)
                }
                const  response = await axiosInstance.post(`${process.env.REACT_APP_ENDPOINT}/instructors/${instructor_id}/questions/`, req,);
                if(response.data) {
                            setIsProgress(false);
                            activityData.questionList_id = response.data.questionList_id;
                            navigate(`/Dashboard/gameDone`, { state: {courses:courses, courseType: courseType, course: course, activity: activityData, stageIndex: stageIndex, courseFormatDate: courseFormatDate, stratingDate: stratingDate, finishingDate: finishingDate  } });
                    }else{
                        setIsProgress(false);
                            setCoursebotmodalIsOpen(true);
                            SetErrorMessage('An error occurred during saving question list. Please try again.')
                    }
            }
            catch (error) {
                setIsProgress(false);
            }
        } else {
            setCoursebotmodalIsOpen(true);
            console.error('Kindly fill all the fields of the last question befor saving');
            SetErrorMessage('Please fill all the fields of the last question befor saving.');
        }
    };
    // Voice recorder
    const [isRecording, setIsRecording] = useState(false);
    const [audioChunks, setAudioChunks] = useState([]);
    const [audioUrl, setAudioUrl] = useState('');

    const [activityType, setActivityType] = useState('');

    const get_activity_type = (type) => {
        setActivityType(type);
    };

    const resetActivityType = () => {
        setActivityType('');
    };
    // Add new question 
    const [IsquestionType, setIsquestionType] = useState('');
    const addQuestionMultiple = (questionType) => {
        // Create a new question object based on questionType
        const newQuestionObject = questionType === 'essay' ? {
            question: "",
            answer1: "",
            answer2: "",
            answer3: "",
            answer4: "",
            answer_exact: false,
            answer1Image: null,
            answer2Image: null,
            answer3Image: null,
            answer4Image: null,
            questionImage: null,
            rank: false,
            bloom_level: "Remembering",
            slide_no: "manual",
            difficulty: "easy",
            questionType: questionType.toString()
        } : {
            question: "",
            answer1: "",
            answer2: "",
            answer3: "",
            answer4: "",
            answer_exact: false,
            answer1Image: null,
            answer2Image: null,
            answer3Image: null,
            answer4Image: null,
            questionImage: null,
            rank: questionType === 'reorder' ? true : false,
            bloom_level: "Remembering",
            slide_no: "manual",
            difficulty: "easy",
            questionType: questionType.toString()
        };
        // If there are questions already in the array, validate the last one
        if (MultipleTypeQuestions.length > 0) {
            // Validate the last question only if the array already has questions
            const lastQuestion = MultipleTypeQuestions[MultipleTypeQuestions.length - 1];

            if (validation(lastQuestion)) {
                setMultipleTypeQuestions([...MultipleTypeQuestions, newQuestionObject]);
                resetActivityType();
                setIsquestionType('');
                SetSelecteQuestionType(null);
            } else {
                setCoursebotmodalIsOpen(true);
                SetErrorMessage('Kindly fill all the fields of the last question before adding new one question!');
                console.error('Kindly fill all the fields of the last question before adding new one question!');

            }
        } else {
            setMultipleTypeQuestions([...MultipleTypeQuestions, newQuestionObject]);
        }
    };

    // Delete Question Multiple
    const handleDeleteMultiple = (questionIndex) => {
        const updatedQuestionsCopy = [...MultipleTypeQuestions];
        updatedQuestionsCopy.splice(questionIndex, 1);
        setMultipleTypeQuestions(updatedQuestionsCopy);
    };
    // copy Question multiple
    const copyQuestionMultiple = (questionIndex) => {
        const lastQuestion = JSON.parse(JSON.stringify(MultipleTypeQuestions[questionIndex]));
        if (MultipleTypeQuestions.length > 0) {
            // Validate the last question only if the array already has questions
            // const lastQuestion = MultipleTypeQuestions[questionIndex];
            if (validation(lastQuestion)) {
                const updatedQuestionsCopy = [...MultipleTypeQuestions];
                // const selectedQuestionCopy = { ...updatedQuestionsCopy[questionIndex] };
                updatedQuestionsCopy.push(lastQuestion);
                setMultipleTypeQuestions(updatedQuestionsCopy);
            } else {
                setCoursebotmodalIsOpen(true);
                SetErrorMessage('You can not copy empty question!');
            }
        } else {
            const updatedQuestionsCopy = [...MultipleTypeQuestions];
            // const selectedQuestionCopy = { ...updatedQuestionsCopy[questionIndex] };
            updatedQuestionsCopy.push(lastQuestion);
            setMultipleTypeQuestions(updatedQuestionsCopy);
        }
    }
    // Model error to add new question
    const [CoursebotmodalIsOpen, setCoursebotmodalIsOpen] = useState(false);
    const EmptyQuestionBotcloseModal = () => {
        setCoursebotmodalIsOpen(false);
        resetActivityType();
    };

    const renderOptions = () => {
        // Image upload
        const handleFileChangeimage = (e) => {
            const file = e.target.files[0];
            setSelectedFile(file);
        };
        const handleDropimage = (e) => {
            e.preventDefault();
            const files = e.dataTransfer.files;
            const isValidFileType = Array.from(files).some(
                (file) => file.type === 'image/jpeg' || file.type === 'image/png'
            );
            if (isValidFileType) {
                const file = files[0];
                setSelectedFile(file);
                // Handle the dropped file as needed
            } else {
                console.log('Invalid file type. Please drop a .jpg or .png file.');
            }
        };
        const handleDragOverimage = (e) => {
            e.preventDefault();
        };
        // Video Upload
        const handleFileChangeVideo = (e) => {
            const file = e.target.files[0];
            setSelectedFileVideo(file);
        };

        const handleDropVideo = (e) => {
            e.preventDefault();
            const files = e.dataTransfer.files;
            const isValidFileType = Array.from(files).some(
                (file) => file.type.startsWith('video/')
            );

            if (isValidFileType) {
                // Handle the dropped video file as needed
                const videoFile = files[0];
                setSelectedFileVideo(videoFile);
            } else {
                console.log('Invalid file type. Please drop a video file.');
            }
        };
        const handleDragOverVideo = (e) => {
            e.preventDefault();
        };
        // Audio upload
        const handleFileChangeAudio = (e) => {
            const file = e.target.files[0];
            setSelectedFileAudio(file);
        };

        const handleDropAudio = (e) => {
            e.preventDefault();
            const files = e.dataTransfer.files;
            const isValidFileType = Array.from(files).some(
                (file) => file.type.startsWith('audio/')
            );

            if (isValidFileType) {
                // Handle the dropped video file as needed
                const audioFile = files[0];
                setSelectedFileAudio(audioFile);
            } else {
                console.log('Invalid file type. Please drop a video file.');
            }
        };
        const handleDragOverAudio = (e) => {
            e.preventDefault();
        };
        // voice recorder

        let mediaRecorder;

        const startRecording = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

                mediaRecorder = new MediaRecorder(stream);

                mediaRecorder.ondataavailable = (event) => {
                    if (event.data.size > 0) {
                        setAudioChunks((prevChunks) => [...prevChunks, event.data]);
                    }
                };

                mediaRecorder.onstop = () => {
                    const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
                    const url = URL.createObjectURL(audioBlob);
                    setAudioUrl(url);
                };

                mediaRecorder.start();
                setIsRecording(true);
            } catch (error) {
                console.error('Error accessing microphone:', error);
            }
        };

        const stopRecording = () => {
            if (mediaRecorder.state !== 'inactive') {
                mediaRecorder.stop();
                setIsRecording(false);
            }
        };
        switch (activityType) {
            case 'image':
                return (
                    <div>
                        <div className='w-full'>
                            <div className='px-5 py-2 mb-2 ContentHeadingBg'><h3 className="text-xl">Create New Activity</h3></div>
                            <div className="m-7 bg-white  ">
                                {/* PopUp Fill all field Of question */}
                                <Modal
                                    isOpen={CoursebotmodalIsOpen}
                                    onRequestClose={EmptyQuestionBotcloseModal}
                                    className="Modal flex justify-center mt-40"
                                // overlayClassName="Overlay"
                                >
                                    {/* Modal Content */}
                                    <div className="flex sm:ms-20" >
                                        <div className="sm:ms-20 mx-20 sm:mx-0 drop-shadow-xl bg-gray-100 flex justify-center px-5" style={{ width: "", borderRadius: "20px 20px" }}>
                                            <div className="mt-5">
                                                <div className="flex justify-center"><img width={50} src={AlertIcon} alt="" /></div>
                                                <h1 className={`sm:text-2xl `}>Kindly fill all the fields of the last question before adding new one question!</h1>
                                                <div className="flex justify-center gap-8 my-4">
                                                    <button onClick={EmptyQuestionBotcloseModal}><b>OK</b></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                                <div className="px-5 py-14 " >
                                    <div className=" w-full  static" style={{ borderRadius: '20px', height: '500px', backgroundColor: 'rgb(240, 245, 244)' }}>
                                        <div className='flex justify-center '><img className='PdfImage' src={ImageUploadIcon} alt="" /></div>
                                        <div className="flex justify-center">
                                            <div className="bg-white py-10 px-16 mt-20" style={{ borderRadius: '20px' }}>
                                                <label
                                                    htmlFor="fileInput"
                                                    style={{ borderRadius: '20px' }}
                                                    className="flex InputBg w-100 relative cursor-pointer border-2 border-dashed border-gray-300 p-4 rounded-xl transition duration-300 hover:border-blue-500"
                                                    onDrop={handleDropimage}
                                                    onDragOver={handleDragOverimage}>
                                                    <input
                                                        type="file"
                                                        accept='image/*'
                                                        id="fileInput"
                                                        className="absolute h-0 w-0 overflow-hidden"
                                                        onChange={handleFileChangeimage}
                                                    />
                                                    <div className="text-center md:flex gap-3">
                                                        <div className='text-center mt-3 py-5 px-10'>
                                                            <div className='flex justify-center'><img src={DragDropIcon} alt="drag" /></div>
                                                            <p>Drag & Drop</p>
                                                        </div>
                                                        <span className='mt-14 mr-5'>OR</span>
                                                        <div className='bg-white flex gap-2 p-3 px-5 rounded-xl'>
                                                            <div className='px-20 py-5'>
                                                                <div className='flex justify-center'><img src={UploadIcon} alt="uploadFile" /></div>
                                                                <p>Upload a File</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </label>
                                                <div className="flex justify-center mt-4">
                                                    <img src={ArrowUpIcon} alt="arrow" />
                                                </div>
                                                <p className="flex justify-center text-blue-800">Image size less than  1mb with ratio of 2:3 JPG or PNG extention</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex  justify-center gap-5 py-5">
                                    <button className="border flex px-7 py-2 rounded BackBtn hover:bg-blue-200" onClick={resetActivityType}><b>Back</b><img className="mt-1 ms-2" src={BackIcon} alt="back" /></button>
                                    {/* <Link to='/Dashboard/ImportContent'> */}
                                    <button type='submit' className='BackBtn flex p-2 px-7 hover:bg-blue-200' onClick={() => { addQuestionMultiple('image') }} ><img className='mr-5 mt-1' src={SaveIcon} alt="Next" /><b> Save</b></button>
                                    {/* </Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 'video':
                return (
                    <div>
                        <div className='w-full'>
                            <div className='px-5 py-2 mb-2 ContentHeadingBg'><h3 className="text-xl">Create New Activity</h3></div>
                            <div className="m-7 bg-white  ">
                                {/* PopUp Fill all field Of question */}
                                <Modal
                                    isOpen={CoursebotmodalIsOpen}
                                    onRequestClose={EmptyQuestionBotcloseModal}
                                    className="Modal flex justify-center mt-40"
                                // overlayClassName="Overlay"
                                >
                                    {/* Modal Content */}
                                    <div className="flex sm:ms-20" >
                                        <div className="sm:ms-20 mx-20 sm:mx-0 drop-shadow-xl bg-gray-100 flex justify-center px-5" style={{ width: "", borderRadius: "20px 20px" }}>
                                            <div className="mt-5">
                                                <div className="flex justify-center"><img width={50} src={AlertIcon} alt="" /></div>
                                                <h1 className={`sm:text-2xl `}>Kindly fill all the fields of the last question before adding new one question!</h1>
                                                <div className="flex justify-center gap-8 my-4">
                                                    <button onClick={EmptyQuestionBotcloseModal}><b>OK</b></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                                <div className="px-5 py-14 " >
                                    <div className=" w-full  static" style={{ borderRadius: '20px', height: '500px', backgroundColor: 'rgb(240, 245, 244)' }}>
                                        <div className='flex justify-center '><img className='PdfImage' src={VideoUploadIcon} alt="" /></div>
                                        <div className="flex justify-center">
                                            <div className="bg-white py-10 px-16 mt-20" style={{ borderRadius: '20px' }}>
                                                <label
                                                    htmlFor="fileInput"
                                                    style={{ borderRadius: '20px' }}
                                                    className="flex InputBg w-100 relative cursor-pointer border-2 border-dashed border-gray-300 p-4 rounded-xl transition duration-300 hover:border-blue-500"
                                                    onDrop={handleDropVideo}
                                                    onDragOver={handleDragOverVideo}>
                                                    <input
                                                        type="file"
                                                        accept='video/*'
                                                        id="fileInput"
                                                        className="absolute h-0 w-0 overflow-hidden"
                                                        onChange={handleFileChangeVideo}
                                                    />
                                                    <div className="text-center md:flex gap-3">
                                                        <div className='text-center mt-3 py-5 px-10'>
                                                            <div className='flex justify-center'><img src={DragDropIcon} alt="drag" /></div>
                                                            <p>Drag & Drop</p>
                                                        </div>
                                                        <span className='mt-14 mr-5'>OR</span>
                                                        <div className='bg-white flex gap-2 p-3 px-5 rounded-xl'>
                                                            <div className='px-20 py-5'>
                                                                <div className='flex justify-center'><img src={UploadIcon} alt="uploadFile" /></div>
                                                                <p>Upload a File</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </label>
                                                <div className="flex justify-center mt-4">
                                                    <img src={ArrowUpIcon} alt="arrow" />
                                                </div>
                                                <p className="flex justify-center text-blue-800">Video size less than 2mb with ratio 2:3 or 3:2 by MP4 extention</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex  justify-center gap-5 py-5">
                                    <button className="border flex px-7 py-2 rounded BackBtn hover:bg-blue-200" onClick={resetActivityType}><b>Back</b><img className="mt-1 ms-2" src={BackIcon} alt="back" /></button>
                                    {/* <Link to='/Dashboard/ImportContent'> */}
                                    <button type='submit' className='BackBtn flex p-2 px-7 hover:bg-blue-200' onClick={() => { addQuestionMultiple('video') }} ><img className='mr-5 mt-1' src={SaveIcon} alt="Next" /><b> Save</b></button>
                                    {/* </Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 'audio':
                return (
                    <div>
                        <div className='w-full'>
                            <div className='px-5 py-2 mb-2 ContentHeadingBg'><h3 className="text-xl">Create New Activity</h3></div>
                            <div className="m-7 bg-white  ">
                                {/* PopUp Fill all field Of question */}
                                <Modal
                                    isOpen={CoursebotmodalIsOpen}
                                    onRequestClose={EmptyQuestionBotcloseModal}
                                    className="Modal flex justify-center mt-40"
                                // overlayClassName="Overlay"
                                >
                                    {/* Modal Content */}
                                    <div className="flex sm:ms-20" >
                                        <div className="sm:ms-20 mx-20 sm:mx-0 drop-shadow-xl bg-gray-100 flex justify-center px-5" style={{ width: "", borderRadius: "20px 20px" }}>
                                            <div className="mt-5">
                                                <div className="flex justify-center"><img width={50} src={AlertIcon} alt="" /></div>
                                                <h1 className={`sm:text-2xl `}>Kindly fill all the fields of the last question before adding new one question!</h1>
                                                <div className="flex justify-center gap-8 my-4">
                                                    <button onClick={EmptyQuestionBotcloseModal}><b>OK</b></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                                <div className="px-5 py-14 " >
                                    <div className=" w-full  static" style={{ borderRadius: '20px', height: '500px', backgroundColor: 'rgb(240, 245, 244)' }}>
                                        <div className='flex justify-center '><img className='PdfImage' src={MicUploadIcon} alt="" /></div>
                                        <div>
                                            <button onClick={startRecording} disabled={isRecording}>
                                                Start Recording
                                            </button>
                                            <button onClick={stopRecording} disabled={!isRecording}>
                                                Stop Recording
                                            </button>
                                            {audioUrl && (
                                                <div>
                                                    <audio controls src={audioUrl}></audio>
                                                </div>
                                            )}
                                        </div>
                                        <div className="flex justify-center">
                                            <div className="bg-white py-10 px-16 mt-20" style={{ borderRadius: '20px' }}>
                                                <label
                                                    htmlFor="fileInput"
                                                    style={{ borderRadius: '20px' }}
                                                    className="flex InputBg w-100 relative cursor-pointer border-2 border-dashed border-gray-300 p-4 rounded-xl transition duration-300 hover:border-blue-500"
                                                    onDrop={handleDropAudio}
                                                    onDragOver={handleDragOverAudio}>
                                                    <input
                                                        type="file"
                                                        accept='audio/*'
                                                        id="fileInput"
                                                        className="absolute h-0 w-0 overflow-hidden"
                                                        onChange={handleFileChangeAudio}
                                                    />
                                                    <div className="text-center md:flex gap-3">
                                                        <div className='text-center mt-3 py-5 px-10'>
                                                            <div className='flex justify-center'><img src={DragDropIcon} alt="drag" /></div>
                                                            <p>Drag & Drop</p>
                                                        </div>
                                                        <span className='mt-14 mr-5'>OR</span>
                                                        <div className='bg-white flex gap-2 p-3  rounded-xl'>
                                                            <div className='px-10 py-5'>
                                                                <div className='flex justify-center'><img src={UploadIcon} alt="uploadFile" /></div>
                                                                <p>Upload Audio</p>
                                                            </div>
                                                            <div className='px-20 py-5'>
                                                                <div className='flex justify-center'><img src={VoiceRecorderIcon} alt="uploadFile" /></div>
                                                                <p>Voice Recorder</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </label>
                                                <div className="flex justify-center mt-4">
                                                    <img src={ArrowUpIcon} alt="arrow" />
                                                </div>
                                                <p className="flex justify-center text-blue-800">Audio size less than 1 mb by MP3 extension</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex  justify-center gap-5 py-5">
                                    <button className="border flex px-7 py-2 rounded BackBtn hover:bg-blue-200" onClick={resetActivityType} ><b>Back</b><img className="mt-1 ms-2" src={BackIcon} alt="back" /></button>
                                    {/* <Link to='/Dashboard/ImportContent'> */}
                                    <button type='submit' className='BackBtn flex p-2 px-7 hover:bg-blue-200' onClick={() => { addQuestionMultiple('audio') }} ><img className='mr-5 mt-1' src={SaveIcon} alt="Next" /><b> Save</b></button>
                                    {/* </Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 'text':
                return <p onClick={addQuestionMultiple}>Text Attachment</p>;
            case 'rank':
                return (
                    <div>
                        <h1 onClick={addQuestionMultiple}>Rank page is here</h1>
                    </div>
                );
            case 'eqution':
                return (
                    <div>
                        <div className='w-full'>
                            <div className='px-5 py-2 mb-2 ContentHeadingBg'><h3 className="text-xl">Create New Activity</h3></div>
                            <div className="sm:m-7 my-7 bg-white contentSide static">
                                <div className='flex justify-center '><img className='PdfImage' src={MultiActivity} alt="" /></div>
                                {/* Eqution editer here */}
                                <div className="sm:px-5 px-2 my-10" >
                                    <div className=" w-full flex justify-center py-5" style={{ borderRadius: '20px', height: '500px', backgroundColor: 'rgb(240, 245, 244)' }}>
                                        <div className="bg-white sm:p-5 p-2" style={{ borderRadius: '20px' }}>
                                            <div>
                                                <Equation mathText={mathText} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex  justify-center gap-5">
                                    <Link to='/Dashboard/activityType'>
                                        <button className="border flex px-7 py-2 rounded BackBtn hover:bg-blue-200" ><b>Back</b><img className="mt-1 ms-2" src={BackIcon} alt="back" /></button>
                                    </Link>
                                    {/* <Link to='/Dashboard/ImportContent'> */}
                                    <button type='submit' className='BackBtn flex p-2 px-7 hover:bg-blue-200' onClick={resetActivityType} ><img className='mr-5 mt-1' src={SaveIcon} alt="Next" /><b> Save</b></button>
                                    {/* </Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div>
            {!activityType && (
                <div>
                    {/* Your main content goes here */}

                </div>
            )}

            {activityType ? (
                <div>
                    {renderOptions()}
                    <button onClick={resetActivityType}>Reset</button>
                </div>
            ) : (
                <div>
                    <div className='w-full'>
                        <MetaTags title='Multi Activity' description='Gamizign Multi Activity page--' />
                        <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'>
                            <h3 className="text-xl">Create New Activity</h3>
                            <LoginHeading />
                        </div>
                        <div className="sm:m-7 my-7 bg-white  static" disabled={progress}>
                            {progress && (
                                <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75 z-50">
                                    <CircularProgress className="text-blue-800" />
                                </div>
                            )}
                            <div className='flex justify-center '><img className='PdfImage' src={MultiActivity} alt="" /></div>
                            {/* PopUp Fill all field Of question */}
                            <Dialog open={CoursebotmodalIsOpen} onClose={EmptyQuestionBotcloseModal}>
                                <DialogContent>
                                    <Alert severity='error'>{ErrorMessage}</Alert>
                                </DialogContent>

                                {ErrorMessage === 'An error occurred during saving question list. Please try again.' ?
                                    <div className='flex justify-center'>
                                        <Button onClick={() => { HandelSaveMultiActivity() }} color="primary">
                                            Try Again
                                        </Button>
                                        <Button onClick={() => { EmptyQuestionBotcloseModal() }} color="primary">
                                            Cancel
                                        </Button>

                                    </div> :
                                    <div className='flex justify-center'>
                                        <Button onClick={() => { EmptyQuestionBotcloseModal() }} color="primary">
                                            Okay
                                        </Button>

                                    </div>}
                            </Dialog>
                            <div className="mx-2 sm:mx-5 xl:mx-40 my-5">
                                <div className="sm:flex sm:relative">
                                    <label htmlFor=""><b>Title</b>
                                        <input className="border p-2 mx-5 rounded bg-gray-100" type="text" defaultValue={`Multi Activity`} id="Title" onChange={(e) => HandelNameActivity(e)} />
                                    </label>
                                    <div className="">
                                        <div className='my-2 sm:my-0 sm:absolute  right-0'>
                                             {/* date picker MUI */}
                                             <div className="relative">
                                                <button
                                                    className="flex items-center justify-center -mt-1 rounded-full focus:outline-none"
                                                    onMouseEnter={() => setShowDropdown(true)}
                                                    onMouseLeave={() => setShowDropdown(false)}
                                                >
                                                    <label htmlFor="date">Starting Date: </label>  <DatePicker disabled className='text-sky-600 border p-2 mx-5 rounded bg-gray-100' minDate={new Date()} selected={date.$d ? date.$d : date} onChange={(date) => handleDateChange(date)} dateFormat={"dd/MM/yyyy"} />
                                                </button>
                                                {showDropdown && (
                                                    <div className="absolute text-black right-0 z-10  bg-white border border-gray-200 rounded shadow-lg top-full" onMouseEnter={() => setShowDropdown(true)} onMouseLeave={() => setShowDropdown(false)}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                                                                <DemoItem>
                                                                    <DateCalendar
                                                                        onChange={(newValue) => handleDateChange(newValue)}
                                                                        minDate={dayjs(stageFormatDate)}
                                                                        value={dayjs(date)}
                                                                        dateFormat={"dd/MM/yyyy"}
                                                                    />

                                                                </DemoItem>
                                                            </DemoContainer>
                                                        </LocalizationProvider>
                                                    </div>
                                                )}
                                            </div>
                                            {/* <label htmlFor="date">Starting Date: </label>
                                            <DatePicker className='text-sky-600 border p-2 mx-5 rounded bg-gray-100' minDate={courseFormatDate} selected={stageFormatDate} onChange={(date) => handleDateChange(date)} dateFormat={"dd/MM/yyyy"} /> */}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {/* Question cards map */}
                                    {MultipleTypeQuestions?.map((question, questionIndex) => (
                                        <div key={Math.random()} id={Math.random()}>
                                            <QuestionComponent
                                                question={question}
                                                questionIndex={questionIndex}
                                                onDelete={() => handleDeleteMultiple(questionIndex)}
                                                copyQuestion={() => copyQuestionMultiple(questionIndex)}
                                            />
                                        </div>
                                    ))}
                                </div>
                                {/* Multi Activity Type*/}
                                {SelecteQuestionType &&
                                    <div className="mt-5 justify-center flex gap-3">
                                        <Tooltip title='MCQs Type'><img className="transition-transform transform hover:scale-110 cursor-pointer" onClick={() => { setIsquestionType('mcqs'); addQuestionMultiple('mcqs') }} src={MCQ} alt="" /></Tooltip>
                                        <Tooltip title='Image Type'><img className="transition-transform transform hover:scale-110 cursor-pointer" onClick={() => { setIsquestionType('image'); addQuestionMultiple('image'); }} src={Image} alt="" /></Tooltip>
                                        {/* <img className="transition-transform transform hover:scale-110 cursor-pointer" onClick={() => { get_activity_type('video'); setIsquestionType('video') }} src={Video} alt="" /> */}
                                        {/* <img className="transition-transform transform hover:scale-110 cursor-pointer" onClick={() => { get_activity_type('audio'); setIsquestionType('audio') }} src={Audio} alt="" /> */}
                                        <Tooltip title='Essay Type'><img className="transition-transform transform hover:scale-110 cursor-pointer" onClick={() => { setIsquestionType('essay'); addQuestionMultiple('essay') }} src={Text} alt="" /></Tooltip>
                                        <Tooltip title='Rank Type'><img className="transition-transform transform hover:scale-110 cursor-pointer" onClick={() => { setIsquestionType('reorder'); addQuestionMultiple('reorder') }} src={RankOrProccess} alt="" /></Tooltip>
                                    </div>}
                            </div>
                            <div className="mt-10">
                                <Tooltip title='Add New Question'>
                                    <div onClick={() => { SetSelecteQuestionType(true) }} className="flex justify-center cursor-pointer"><img src={AddNewActivity} alt="" /></div>
                                    <p className="flex justify-center mt-2">Add New Question</p>
                                </Tooltip>
                            </div>
                            <div className="flex  justify-center gap-5 py-10">
                                {/* <Link to='/Dashboard/activityType'> */}
                                <button onClick={HanldeBackActivityType} className="border flex px-7 py-2 rounded BackBtn hover:bg-blue-200"><b>Back</b><img className="mt-1 ms-2" src={BackIcon} alt="back" /></button>
                                {/* </Link> */}
                                {/* <Link to='/Dashboard/ImportContent'> */}
                                <button type='submit' onClick={HandelSaveMultiActivity} className='BackBtn flex p-2 px-7 hover:bg-blue-200' ><img className='mr-5 mt-1' src={SaveIcon} alt="Next" /><b> Save</b></button>
                                {/* </Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>


    )
}

export default MultiActivy;
