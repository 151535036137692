import React, { useState } from "react";
import Image from '../../../assets/images/SigninPageImages/BG_for_Login_12-10-2024.png'
import EmailICon from '../../../assets/images/SigninPageImages/checkEmail.png'
import GMZLogo from '../../../assets/images/SigninPageImages/Gamizign-logo.png'
import SignUpBg from '../../../assets/images/Login & Signing art assets/Big-Trans-iconsfor-Background.png'
import ProfessionalOff from '../../../assets/images/Login & Signing art assets/Professional off- S & C.png'
import ProfessionalOn from '../../../assets/images/Login & Signing art assets/Professional on- S & C.png'
import TeacherOff from '../../../assets/images/Login & Signing art assets/Teacher off- S & C.png'
import TeacherOn from '../../../assets/images/Login & Signing art assets/Teacher on- S & C.png'
import StudentOff from '../../../assets/images/Login & Signing art assets/Student off- S & C.png'
import StudentOn from '../../../assets/images/Login & Signing art assets/Student on- S & C.png'
import FamilyOff from '../../../assets/images/Login & Signing art assets/Family off- S & C.png'
import FamilyOn from '../../../assets/images/Login & Signing art assets/Family on- S & C.png'

import { Link, useLocation } from "react-router-dom";
import BuyPlan from "./buyPlan";


const SignUp = () => {
    const { AccountCreated } = useLocation().state || false;
    const [acountCreated, setAcountCreated] = useState(AccountCreated);
    const [Hover, setHover] = useState('');
    const [View, setView] = useState('ChoseAccount');
    const [Plan, setPlan] = useState('Individual')

    const ChoseAccountHandler = (value, accType) => {
        setView(value);
        setPlan(accType);
    }
    return (
        <div>
            {acountCreated ? (
                <div className="text-center" style={{ backgroundImage: `url(${Image})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                    <div className="flex-none bg-white opacity-75 text-blue-700 font-bold text-center">
                        <h1 className="text-5xl pt-5">Thank You!</h1>
                        <p className="text-xl">Thank you for submitting your registration form for Gamizign account! To finish the process,<br /> please check your email to activate your account... <Link className="text-black" to='https://mail.google.com/mail/'>Check Mail</Link></p>
                    </div>
                    <div className="flex justify-center -mt-5"><img width='75%' src={EmailICon} alt="" /></div>
                </div>
            ) : (
                <div style={{ backgroundImage: `url(${SignUpBg})`, backgroundPosition: 'top', backgroundRepeat: 'repeat-y',  backgroundSize:'100%' }}>
                    {View === 'Professional' ? (
                        <div>
                            <button className="m-5 px-7 p-2 border rounded bg-gray-200 hover:bg-blue-200" onClick={() => setView('')}>Back</button>
                            <BuyPlan Plan={Plan} setPlan={setPlan} />
                        </div>) : View === 'Teacher' ? (
                            <div>
                                <button className="m-5 px-7 p-2 border rounded bg-gray-200 hover:bg-blue-200" onClick={() => setView('')}>Back</button>
                                <BuyPlan Plan={Plan} setPlan={setPlan} />
                            </div>) : (
                        <div style={{height:"100vh"}}>
                            <div className="flex justify-center"><img src={GMZLogo} width={100} alt="LOGO" /></div>
                            <h1 className="flex justify-center text-2xl">Choose your account type</h1>
                            <div className="flex justify-center">
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-5 p-5">
                                <div className="flex justify-center choseAccount">
                                   <div className="cursor-pointer transition-transform transform hover:scale-110 shadow-xl mt-3"
                                    onMouseEnter={() => { setHover('Professional') }} onMouseLeave={() => { setHover('') }}
                                    onClick={() => ChoseAccountHandler('Professional', 'Individual')}>
                                   <div className="p-20 bg-blue-200">
                                    <img width={96} height={96} src={Hover === 'Professional' ? ProfessionalOn : ProfessionalOff} alt="PIcon" />
                                   </div>
                                   <p className={`text-center text-2xl font-bold py-10 ${Hover === 'Professional' ? 'bg-blue-800 text-white' : 'bg-white'}`}>Professional</p>
                                   </div>
                                </div>
                                <div className="flex justify-center choseAccount">
                                <div className="cursor-pointer transition-transform transform hover:scale-110 shadow-xl mt-3"
                                    onMouseEnter={() => { setHover('Teacher') }} onMouseLeave={() => { setHover('') }}
                                    onClick={() => ChoseAccountHandler('Teacher', 'Individual')}>
                                   <div className="p-20 bg-emerald-100">
                                    <img width={96} height={96} src={Hover === 'Teacher' ? TeacherOn : TeacherOff} alt="PIcon" />
                                   </div>
                                   <p className={`text-center text-2xl font-bold py-10 ${Hover === 'Teacher' ? 'bg-green-700 text-white' : 'bg-white'}`}>Teacher</p>
                                   </div>
                                </div>
                                <div className="flex justify-center choseAccount">
                                <div className="cursor-pointer transition-transform transform hover:scale-110 shadow-xl mt-3"
                                    onMouseEnter={() => { setHover('Student') }} onMouseLeave={() => { setHover('') }}
                                    onClick={() => ChoseAccountHandler('Student')}>
                                   <div className="p-20 bg-amber-100">
                                    <img width={96} height={96} src={Hover === 'Student' ? StudentOn : StudentOff} alt="PIcon" />
                                   </div>
                                   <p className={`text-center text-2xl font-bold py-10 ${Hover === 'Student' ? 'bg-orange-400 text-white' : 'bg-white'}`}>Student</p>
                                   </div>
                                </div>
                                <div className="flex justify-center choseAccount">
                                <div className="cursor-pointer transition-transform transform hover:scale-110 shadow-xl mt-3"
                                    onMouseEnter={() => { setHover('Family') }} onMouseLeave={() => { setHover('') }}
                                    onClick={() => ChoseAccountHandler('Family')}>
                                   <div className="p-20 bg-red-200">
                                    <img width={96} height={96} src={Hover === 'Family' ? FamilyOn : FamilyOff} alt="PIcon" />
                                   </div>
                                   <p className={`text-center text-2xl font-bold py-10 ${Hover === 'Family' ? 'bg-red-600 text-white' : 'bg-white'}`}>Family</p>
                                   </div>
                                </div>
                            </div>
                            </div>
                            <p className="text-center mt-5 text-xl font-bold">Already have an account <Link to='/' className="text-blue-500">Login</Link></p>
                        </div>)}
                </div>
            )}
        </div>
    )
};
export default SignUp;