import { Alert, Button, CircularProgress, Dialog, DialogContent } from "@mui/material";
import React, { useState } from "react";
import axiosInstance from "../../../../../../axios";

const DeleteAccount = () => {
    const [DeleleAcc, setDeleleAcc] = useState(false);
    const [Progress, setProgress] = useState(false);
    const [DialogMessage, setDialogMessage] = useState('');

    const closeDialog = () => {
        if (DialogMessage === 'Account has been deleted successfully.') {
            localStorage.clear(); window.location.href = "/";
        }
        
        setDeleleAcc(false);
    }
    const HandleDeleteAccount = async () => {
        setProgress(true);
        try{
            const organization_id = localStorage.getItem("organization")
            const DeleteAccount = await axiosInstance.delete(`${process.env.REACT_APP_ENDPOINT}/organization/${organization_id}`);
            if (DeleteAccount.status === 200) {
                setDialogMessage('Account has been deleted successfully.');
            }

        }catch (error){
            setDialogMessage('An error occured while deleting account try again later.')
        }finally{
            setProgress(false);
        }
    }
    return (
        <div>
            <Dialog className="sm:ms-56" open={DeleleAcc} onClose={closeDialog}>
                {Progress && (
                    <div className="absolute inset-0 flex justify-center items-center bg-gray-50 bg-opacity-75 z-50">
                        <CircularProgress className="text-blue-800" />
                    </div>
                )}
                <DialogContent>
                    <Alert severity={DialogMessage === 'Are you sure do you want delete account permenently from Gamizign?' ? "warning" : DialogMessage ===  'Account has been deleted successfully.' ? "success" : 'error'} >
                        {DialogMessage}</Alert>
                    {DialogMessage === 'Are you sure do you want delete account permenently from Gamizign?' ?
                        <div className="flex justify-center mt-3">
                            <Button onClick={HandleDeleteAccount}>Yes</Button>
                            <Button onClick={() => { closeDialog('') }}>Cancel</Button>
                        </div>: 
                        <div className="flex justify-center mt-3">
                            <Button onClick={() => { closeDialog() }}>Okay</Button> 
                        </div> 
                    }
                </DialogContent>
            </Dialog>
            <div className="text-center">
                <button 
                onClick={() => { setDeleleAcc(true); setDialogMessage('Are you sure do you want delete account permenently from Gamizign?') }} 
                className="rounded text-white font-bold px-10 py-3 bg-red-500 hover:bg-red-600 shadow-md w-56">Delete Account</button>
            </div>
        </div>
    )
};
export default DeleteAccount;