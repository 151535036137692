import React, { useEffect, useState } from 'react';
import '../../../style.css';
import IconGamizignWorld from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/The Gamizign World off icon.png'
import IconGamizignWorldOn from '../../../../../../assets/icons/left panel icons/HoverState/The Gamizign World on icon.png'
// Normal State Sidebar Icons 
import BackIcon from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/Back icon panel.png'
import BackIconOff from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/Back icon panel off.png'
import AnalyticsIcon from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/Analytics panel icon.png'
import AnalyticsIconOff from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/Analytics panel icon off.png'
import HomeworkIcon from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/Homework panel icon.png'
import HomeworkIconOff from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/Homework panel icon off.png'
import SettingIcon from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/Settings panel icon.png'
import SettingIconOff from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/Settings panel icon off.png';
import ViewCourseIcon from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/View Course icon.png';
import ViewCourseIconOff from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/Test the Course icon.png';
import PublishCourseIcon from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/Publish Course icon.png';
import LogoPic from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/Gamizign-logo.png';
import { Link, Outlet, useLocation } from 'react-router-dom';
import axios from 'axios';
import axiosInstance from '../../../../../../axios';
import { Box, Divider, Drawer, Hidden, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';




const CourseBar = ({mode}) => {
  const [dynamicUrl, setDynamicUrl] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_ENDPOINT}/webgl`);
        setDynamicUrl(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const course_id = queryParams.get('course_id');
  var instructor_id = parseInt(localStorage.getItem("instructor_id"));
  const [selectedTab, setSelectedTab] = useState(2);
  const [selectedTabName, setSelectedTabName] = useState('Analytics');
  const menuItems = [
    { id: 11, icon: IconGamizignWorld, iconOff: IconGamizignWorldOn, label: 'The Gamizign World', style: 'font-bold text-xl', link: '/Dashboard' },
    { id: 1, icon: BackIcon, iconOff: BackIconOff, label: 'Back', style: 'font-bold text-xl', link: '/Dashboard/Courses' },
    { id: 2, icon: AnalyticsIcon, iconOff: AnalyticsIconOff, label: 'Analytics', style: '', link: `/Course?course_id=${course_id}`, },
    { id: 3, icon: HomeworkIcon, iconOff: HomeworkIconOff, label: 'Homework', style: '', link: `/Course/HomeWork?course_id=${course_id}`, },
    // { id: 4, icon: SettingIcon, iconOff: SettingIconOff, label: 'Settings', style: '', link: `/Course/CourseSetting?course_id=${course_id}`, },
    { id: 5, icon: ViewCourseIcon, iconOff: ViewCourseIconOff, label: 'View Course', style: 'mx-8 bg-white mt-6', link: `/Course/ViewCourse?course_id=${course_id}`, },
    // { id: 6, icon: PublishCourseIcon, iconOff: PublishCourseIcon, label: 'View Gamizign Courses', style: 'mx-8 bg-white mt-2', targrte:'_blank', link: `${dynamicUrl?.url}?port=8000&id=${instructor_id}&ip=gamizign.co&secured=true`, },
  ];

  const [isNavOpen, setIsNavOpen] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleTabClick = (tabId, tabLabel) => {
    setSelectedTab(tabId);
    setSelectedTabName(tabLabel);
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const drawerWidth = 240;
  const drawer = (
    <div>
      {mobileOpen && 
      <Box
      component="div"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 64,
        backgroundColor: 'primary.main',
        color: 'white',
        fontSize: 20,
      }}
      >
        <img src={LogoPic} alt="Logo" style={{ height: '100%' }} />
      </Box>
      }
      <Divider />
      <List sx={{ padding: '0px' }}>
        {menuItems.map((item, index) => (
          <Link id={`step-${index +1}`} to={item.link}>
            <ListItem 
              button
              key={item.text}
              selected={selectedTab === item.id}
              onClick={(event) => { handleTabClick(item.id, event, index); setMobileOpen(false) }}
            >
              <ListItemIcon
              >
                <img src={mode === 'light' ?  item.icon : item.iconOff} alt="" />
              </ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItem><Divider /></Link>
        ))}
      </List>
      <Divider />
      <Box
        component="div"
        sx={{
          display: '',
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: '10px'
        }}
      >
        {!mobileOpen && <img src={LogoPic} alt="Logo" className='w-20 h-20' />}
        <Link to='https://gamizign.com/privacy-policy/'><p className={`font-bold ${mobileOpen && 'mt-5'}`}>Privacy Policy & Terms of Use</p></Link>
        <p>Subscription Agreement</p>
      </Box>
    </div>

  );
  return (
    <div>
      <div className="flex">
        {/* Sidebar Tabs*/}
        <Box
          component="nav"
          sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 } }}
          aria-label="mailbox folders"
        >
          <Hidden smUp implementation="css">
            <Drawer
              labelStyle={{fontFamily: 'Cairo_Regular'}}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: 'block', lg: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              variant="permanent"
              sx={{
                display: { xs: 'none', lg: 'block' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
              }}
              // open
              open={!mobileOpen}
            >
              {drawer}
            </Drawer>
          </Hidden>
        </Box>
        <div className="SIDEMENU flex-none FixedWidth256 hidden">
          <div className="SidbarBgColor textcolor">
            <div className="mb-8">
              {/* <div className='flex py-2 px-4 BgcolorOf4'><img className='mr-2' src={IconGamizignWorld} alt="" /><h3 className='font-bold text-xl'>The Gamizign World</h3>
              </div> */}
              {menuItems.map((item) => (
                <div className={item.style}>
                  <Link to={item.link}> <div
                    key={item.id}
                    className={`flex items-center py-2 px-4 border cursor-pointer hover:bg-blue-100 ${selectedTab === item.id ? 'SlectTabColor' : ''}`}
                    onClick={() => handleTabClick(item.id, item.label)}>
                    <div className="mr-2"><img src={selectedTab === item.id ? item.iconOn : item.icon} alt="" /></div>
                    <span>{item.label}</span>
                  </div>
                  </Link>
                </div>
              ))}
            </div>
            <div className=" justify-center items-center">
              {/*Sidebar Footer */}
              <div className='ml-4 mb-7'>
                <img src={LogoPic} alt="Logo" className="w-20 h-20 mr-2" />
                <Link to='https://gamizign.com/privacy-policy/'><p className='font-bold'>Privacy Policy & Terms of Use</p></Link>
                <p>Subscription Agreement</p>
              </div>

            </div>
          </div>
        </div>
        <div className='lg:hidden z-10 absolute top-0 right-0 px-3 py-3' onClick={handleDrawerToggle}>
          {!mobileOpen ? 
          <div>
            <span className="block h-0.5 w-8 bg-gray-600"></span>
            <span className="block h-2 w-8"></span>
            <span className="block h-0.5 w-8 bg-gray-600"></span>
            <span className="block h-2 w-8"></span>
            <span className="block h-0.5 w-8 bg-gray-600"></span>
          </div>
            :
          <div>
            <svg
              className="h-8 w-8 text-gray-600"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              >
              <line x1="18" y1="6" x2="6" y2="18" />
              <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
          </div>
            }
        </div>

          <Outlet></Outlet>
      </div>
    </div>
  );
};

export default CourseBar;

