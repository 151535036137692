import React from "react";
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
    ...theme.applyStyles('dark', {
        backgroundColor: 'rgba(255, 255, 255, .05)',
    }),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    textAlign:'justify'
}));

const FAQ = () => {
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    return (
        <div>
            <div className="px-5 md:px-20 py-20">
            <h1 className="font-bold w-24 text-5xl text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-pink-500">FAQ</h1>
                <Accordion className='flex-grow' expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <h1 className="font-bold text-xl">Can I upgrade or downgrade my Gamizign subscription?</h1>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        <b>Yes, you can upgrade or downgrade your Paid Service Plan subscription at any time. </b> 
                        Downgrades take effect the day after the current subscription period ends, 
                        while upgrades take effect immediately, subject to an upgrade fee. 
                        You'll be credited for the remaining term of your previous plan when upgrading.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion className='flex-grow' expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                        <h1 className="font-bold text-xl">How can I contact Gamizign support for assistance?</h1>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        You can contact Gamizign support by emailing <a className="text-blue-600" href="mailto:support@gamizign.com?subject=Help and support">support@gamizign.com</a>. 
                        The support team is available to assist you with any questions or issues you may have regarding the platform.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion className='flex-grow' expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                        <h1 className="font-bold text-xl">Is the pricing listed by student or by teacher?</h1>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        The pricing for Gamizign is listed per <b>teacher. </b> 
                        This means that each teacher pays a <b>monthly</b> or <b>annually</b> fee for their individual account, regardless of the number of students they teach. 
                        So, you have to choose the plan that suits you and this allows you to use Gamizign to its full potential without worrying about additional costs per student.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion className='flex-grow' expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                        <h1 className="font-bold text-xl">Can I add student to a group?</h1>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        <b>Yes, you can add students to a group in Gamizign. </b> 
                        To do this, simply navigate to the Learners tab and add your students in your class. 
                        Please note that this feature is available for school administrators using the organization <b>(school subscription)</b> client, and not available for individual teachers.
                        <p className="ms-5 -mt-4"> <b className="me-2 text-5xl">.</b><b>Add student by CSV:</b> Easily add students <b>Add in bulk quantity</b> by importing an excel file.</p>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion className='flex-grow' expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                    <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                        <h1 className="font-bold text-xl">How do I get a verified Gamizign certificate?</h1>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        To earn a verified Gamizign certificate, you must demonstrate your effective use of the platform to enhance student learning. 
                        The <b>Gamizign Advanced Criteria</b> require that you achieve the following with at least <b>20 students:</b>
                        <p className="ms-5 -mt-4"> <b className="me-2 text-5xl">.</b><b>Significant Improvement in Learning Outcomes:</b> This includes measurable improvement in class marks, 
                            high satisfaction rates exceeding 80%, engagement levels above 80%, and documented growth in student skills and competencies.</p>
                            By meeting these criteria, you will be eligible to receive a verified Gamizign certificate, 
                            recognizing your expertise in utilizing the platform for impactful teaching and learning experiences.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion className='flex-grow' expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                    <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                        <h1 className="font-bold text-xl">Could students complete their courses and activities on their time?</h1>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        <b>Yes, Gamizign allows for student-paced learning. </b> 
                        Teachers can assign courses that students can complete at their own convenience. This flexibility is supported by several features:
                        <p className="ms-5 -mt-4"> <b className="me-2 text-5xl">.</b><b>Ongoing assigned courses without a deadline: </b>
                        Students can access and complete courses at their preferred pace, making it ideal for ongoing review or long-term projects.
                        </p>
                        <p className="ms-5 -mt-4"> <b className="me-2 text-5xl">.</b><b>Arcade games: </b>  
                        These interactive games allow students to work at their own speed, both in the classroom and at home.
                        </p>
                        <p className="ms-5 -mt-4"> <b className="me-2 text-5xl">.</b><b>Chat feature: </b>  
                        </p>
                        <p className="ms-5 -mt-4"> <b className="me-2 text-5xl">.</b><b>Automated notifications: </b>  
                        automated notifications will be send to inform students about new courses or activities, 
                        helping them stay on track while maintaining flexibility in their learning schedule.
                        </p>
                        <p className="ms-5 -mt-4"> <b className="me-2 text-5xl">.</b><b>Access on personal devices: </b>  
                        Students can conveniently access assigned courses and activities on their smartphones or tablets.
                        </p>
                        <p className="ms-5 -mt-4"> <b className="me-2 text-5xl">.</b><b>Sequential courses: </b>  
                        Teachers can create sequential courses to guide students through a structured learning path, improving their knowledge, skills, and competencies.
                        </p>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion className='flex-grow' expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                    <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
                        <h1 className="font-bold text-xl">Creating Gamizign Courses Across Subjects and Levels</h1>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        Gamizign offers a versatile platform for creating engaging courses across <b>various subjects</b> and grade levels <b>(KG-12).</b>
                        <p className="ms-5 -mt-4"> <b className="me-2 text-5xl">.</b><b>Math: </b>  
                        you can create courses on numbers, operations, geometry, measurement, and data analysis.
                        </p>
                        <p className="ms-5 -mt-4"> <b className="me-2 text-5xl">.</b><b>Science: </b>  
                        You can teach biology, chemistry, physics, and earth science. 
                        </p>
                        <p className="ms-5 -mt-4"> <b className="me-2 text-5xl">.</b><b>Language arts: </b>  
                        you can focus on reading, writing, grammar, vocabulary, and literature with reading passages and writing prompts.
                        </p>
                        <p className="ms-5 -mt-4"> <b className="me-2 text-5xl">.</b><b>Social: </b>  
                        studies, you can cover history, geography, civics, and economics.
                        </p>
                        You can effectively create Gamizign courses that align with your teaching goals, engage students, 
                        and promote meaningful learning across all subjects and grade levels
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion className='flex-grow' expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                    <AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
                        <h1 className="font-bold text-xl">What is Journey-courses in Gamizign?</h1>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Journey-courses in Gamizign are a unique feature that allows you to create structured learning paths with 
                            <b>multiple stages</b>, 
                            similar to multi-unit lessons in a classroom textbook. This enables you to guide students through a comprehensive and sequential learning experience.
                            Within Journey-courses, you can add various types of activities, including:
                            <p className="ms-5 -mt-4"> <b className="me-2 text-5xl">.</b><b>Homework assignments: </b>
                            Assign tasks to reinforce learning and practice skills.
                            </p>
                            <p className="ms-5 -mt-4"> <b className="me-2 text-5xl">.</b><b>Worksheets: </b>
                            Create printable or digital worksheets with PDF, image, video, audio, and URL-based activities.
                            </p>
                            This flexibility allows you to tailor Journey-courses to your specific teaching goals and ensure that students’ 
                            progress through the material in a structured and engaging manner.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion className='flex-grow' expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                    <AccordionSummary aria-controls="panel9d-content" id="panel9d-header">
                        <h1 className="font-bold text-xl">Gamizign arcade games?</h1>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        Gamizign offers a variety of games across different <b>themes</b>, including:
                            <p className="ms-5 -mt-4"> <b className="me-2 text-5xl">.</b><b>Adventure game: </b>Squirrlo, Jupitario, Deepmarine</p>
                            <p className="ms-5 -mt-4"> <b className="me-2 text-5xl">.</b><b>Strategy game: </b>AntBattle War</p>
                            <p className="ms-5 -mt-4"> <b className="me-2 text-5xl">.</b><b>Dessert game:  </b>SugarSmash, DinerDash</p>
                            <p className="ms-5 -mt-4"> <b className="me-2 text-5xl">.</b><b>Sport game: </b>Laxileague, BeastBasket</p>
                            <p className="ms-5 -mt-4"> <b className="me-2 text-5xl">.</b><b>Action game: </b>StreetSprint, Freesign War</p>
                            <p className="ms-5 -mt-4"> <b className="me-2 text-5xl">.</b><b>Puzzle game: </b>Loopylizer, ActBig, CanalTour, HexMatch</p>
                            <p className="ms-5 -mt-4"> <b className="me-2 text-5xl">.</b><b>Mission game: </b>SolrizCity, TrianAid, SpaceoX, Kanwa & Kango</p>
                            The <b>Standard</b> Plan offers <b>5 Arcade Games.</b> Pick one game from each catogory.<br />
                            The <b>Premium Plan</b> grants access to <b>All Arcade Games.</b>

                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion className='flex-grow' expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
                    <AccordionSummary aria-controls="panel10d-content" id="panel10d-header">
                        <h1 className="font-bold text-xl">What are Customized Badges in Gamizign?</h1>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        Customized Badges are a premium feature in Gamizign that allow teachers to create unique rewards for their students. 
                        Gamizign provides <b>pre-designed badge templates</b>, 
                        but teachers also have the flexibility to design <b>their own badges from scratch</b>. <br />
                        You can set up specific criteria for awarding badges, such as performance levels or achievements. Additionally, 
                        you can link badges to a virtual currency system, allowing students to earn coins <b>(points)</b> as they progress in play2win store.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
               
            </div>
        </div>
    )
};
export default FAQ;