import React, { useEffect, useState } from "react";
import LoginHeading from "../../../../LoginHeading/LoginHeading";
import LoadingComponent from "../../../../GlobalComponents/GlobalLoading";
import axiosInstance from "../../../../../axios";
import EditIcon from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/ViewCourse/edit icon.png'
import DeleteIcon from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/ViewCourse/delete icon.png'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Papa from 'papaparse';
import KeyIcon from '@mui/icons-material/Key';
import EmailIcon from '@mui/icons-material/Email';
import Zoom from '@mui/material/Zoom';
import AddIcon from '@mui/icons-material/Add';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import MetaTags from "../../../../GlobalComponents/MetaData";


const LearnerList = ({mode}) => {
    const [classes, setClasses] = useState(null);
    const [loading, setLoading] = useState(true);
    const [NetworkResp, setNetworkResp] = useState(false);
    const [RespMessage, setRespMessage] = useState('');
    const [NewRecord, setNewRecord] = useState(null);
    const [actionType, setActionType] = useState('');
    const [open, setOpen] = useState(false);
    const [progress, setProgress] = useState(false);
    const [newItem, setNewItem] = useState({});
    const [student_id, setStudent_id] = useState(null);
    // Fields
    const [FirstName, setFirstName] = useState('');
    const [LastName, setLastName] = useState('');
    const [Email, setEmail] = useState('');
    const [Grade, setGrade] = useState('');
    const [Class, setClass] = useState('');
    const [Paswoord, setPaswoord] = useState('');
    // errors
    const [FirstNameError, setFirstNameError] = useState('');
    const [LastNameError, setLastNameError] = useState('');
    const [EmailError, setEmailError] = useState('');
    const [GradeError, setGradeError] = useState('');
    const [ClassError, setClassError] = useState('');
    const [PaswoordError, setPaswoordError] = useState('');
    var org = localStorage.getItem("organization");
    var instructor_id = localStorage.getItem("instructor_id");
    var admin_id = localStorage.getItem("admin_id");

    const getClasses = async () => {
        setLoading(true);
        const resp = await axiosInstance.get(`${process.env.REACT_APP_ENDPOINT}/organization/${org}/classes/`);
        setClasses(resp.data.classes);
        setLoading(false);
    };
    useEffect(() => {
        getClasses();
    }, [])
    const StudentsColors = ['#ACCCE5', '#BCE2BB', '#DDE6C0', '#FFF3C6', '#FEE7C3', '#FCDCBD', '#F6C1C1', '#DFBFDD'];
    // formation of today's date with time
    const today = new Date();
    const dd = today.getDate().toString().padStart(2, '0');
    const mm = (today.getMonth() + 1).toString().padStart(2, '0');
    const yyyy = today.getFullYear().toString();
    const hh = today.getHours().toString().padStart(2, '0');
    const min = today.getMinutes().toString().padStart(2, '0');
    const ss = today.getSeconds().toString().padStart(2, '0');
    const formattedToday = dd + '/' + mm + '/' + yyyy + "  " + hh + ":" + min + ":" + ss;
    // Add New students
    const handleAddItem = async () => {
        setProgress(true);
        const updatedItem = {
            ...newItem, email:Email ? Email : "",
            organization_id: org, st_class: newItem.grade, class_id: classes[0].class_id, avatar_id: 1, dateCreated: formattedToday
        };

        let endpoint = `${process.env.REACT_APP_ENDPOINT}/instructors/${instructor_id}/students/`;
        try {
            const response = await axiosInstance.post(endpoint, updatedItem)
            setNewRecord(response.data);
            setRespMessage('Created successfully');
            setNetworkResp(true);
            setProgress(false);
        } catch (error) {
            console.error('Error adding item:', error);
            setRespMessage('An error occurred while Creating');
            setNetworkResp(true);
            setProgress(false);
        }
    };
    // Validation on adding student
    const AddStudentValidation = (action) => {
        let mainError = false;
        if (!FirstName) {
            setFirstNameError('Please fill the First Name.');
            mainError = true;
        }
        if (!LastName) {
            setLastNameError('Please fill the Last Name.')
            mainError = true;

        }
        // if (!Email) {
        //     setEmailError('Please fill the Email.')
        //     mainError = true;

        // }
        // if (Email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(Email)) {
        //     setEmailError('Please fill the Email in correct format like')
        //     mainError = true;

        // }
        if (!Grade) {
            setGradeError('Please fill the Grade.')
            mainError = true;

        }
        if (!Paswoord) {
            setPaswoordError('Please enter the Password.')
            mainError = true;
        }else if (Paswoord.length < 8) {
            setPaswoordError('Password must be at least 8 characters long.');
            mainError = true;
        } else if (!/[a-zA-Z]/.test(Paswoord)) {
            setPaswoordError('Password must contain at least one alphabetic character.(a-zA-Z)');
            mainError = true;
        }

        if (!mainError) {
            if (action === 'add') {
                handleAddItem();
            }else{
                updateHandle();
            }
        }
    }
    const passwordHandler = (value) =>{
        setPaswoord(value);        
        if (!value) {
            setPaswoordError('Please enter the Password.')
        }else if (value.length < 8) {
            setPaswoordError('Password must be at least 8 characters long.');
        } else if (!/[a-zA-Z]/.test(value)) {
            setPaswoordError('Password must contain at least one alphabetic character.(a-zA-Z)');
        }else if (value.length >= 8 && /[a-zA-Z]/.test(value)){
            setPaswoordError('Perfect ✔');
        }
    }
    // Update student
    const updateHandle = async () => {
        setProgress(true);
        let endpoint;
        endpoint = `${process.env.REACT_APP_ENDPOINT}/organizations/${org}/classes/${classes[0].class_id}/students/`;

        try {
            const response = await axiosInstance.put(endpoint, newItem);
            setRespMessage('Updated successfully');
            setNetworkResp(true);
            setProgress(false);
        } catch (error) {
            console.error("Error updating data:", error);
            setRespMessage('An error occurred while Updating');
            setNetworkResp(true);
            setProgress(false);
        }
    };
    // Delete student
    const DeleteStudent = async () => {
        setProgress(true);
        let endpoint = `${process.env.REACT_APP_ENDPOINT}/students/${student_id}`;
        try {
            const response = await axiosInstance.delete(endpoint);
            if (response.status === 200) {
                console.log('Item successfully deleted');
                setRespMessage('Deleted successfully');
                setNetworkResp(true);
                setProgress(false);
                setActionType('');
            } else {
                console.error(`Failed to delete item. Status: ${response.status}`);
            }
        } catch (error) {
            console.error('Error deleting item:', error);
            setActionType('');
            setRespMessage('An error occurred while Deleting');
            setNetworkResp(true);
            setProgress(false);
        }
    };
    // add students in bulk/ upload CSV 
    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const csvData = event.target.result;
                addColumnsToCSV(csvData, file);
            };
            reader.readAsText(file);
        }
    };
    const addColumnsToCSV = (csvData, originalFile) => {
        // Parse the CSV data
        const parsedData = Papa.parse(csvData, { header: true });

        // Add new columns with fixed values
        const modifiedData = parsedData.data.map(row => ({
            ...row,
            avatar_id: 1,
            organization_id: localStorage.getItem("organization"),
            class_id: classes[0].class_id,
            admin_id: localStorage.getItem("admin_id"),
        }));
        // Convert back to CSV
        const updatedCSVString = Papa.unparse(modifiedData);

        // Create a Blob from the updated CSV string
        const blob = new Blob([updatedCSVString], { type: 'text/csv;charset=utf-8;' });

        // Create a new File object with the same properties as the original file
        const updatedFile = new File([blob], originalFile.name, {
            type: originalFile.type,
            lastModified: originalFile.lastModified
        });

        setGrade(updatedFile);
    };
    // Adding students by uploading CSV file
    const handleUploadCsv = async () => {
        setProgress(true);
        let endpoint = `${process.env.REACT_APP_ENDPOINT}/organizations/${org}/classes/${classes[0].class_id}/students/csv/?admin_id=${admin_id}`;

        try {
            let formData = new FormData();
            formData.append('file', Grade);

            const response = await axiosInstance.post(endpoint, formData);

            if (response.status === 200) {
                setNewRecord(response.data);
                setRespMessage('Created successfully');
                setNetworkResp(true);
                setProgress(false);
            } else {
                console.log('Request status is', response.status);
            }
        } catch (error) {
            console.error('Error adding item:', error);
            setRespMessage('An error occurred while Creating');
            setNetworkResp(true);
            setProgress(false);
        }
    };
    const limit = classes?.length > 0 ? classes[0].students.length : 0;
    const LicenceType = parseInt(localStorage.getItem("plan"));
    const handleAddDialog = (actionType) => {
        let limiterror = false;
        if (actionType === 'add') {
            if (LicenceType === 0  && limit >= 20) {
                limiterror = true;
                setRespMessage(`Thank you for using Gamizign the AI ultimate gamification platform! Your current plan doesn't have the ability to add more students upgrade to add more.`);
            }else if (LicenceType === 1 && limit >= 50) {
                limiterror = true;
                setRespMessage(`Thank you for using Gamizign the AI ultimate gamification platform! Your current plan doesn't have the ability to add more students upgrade to add more.`);
            }else if (LicenceType === 2 && limit >= 100) {
                limiterror = true;
                setRespMessage(`Thank you for using Gamizign the AI ultimate gamification platform! Your current plan doesn't have the ability to add more students upgrade to add more.`);
            }else if (LicenceType === 3 && limit >= 200) {
                limiterror = true;
                setRespMessage(`Thank you for using Gamizign the AI ultimate gamification platform! Your current plan doesn't have the ability to add more students upgrade to add more.`);
            };
        }else if (actionType === 'addCSV') {
            if (LicenceType === 0) {
                limiterror = true;
                setRespMessage(`Thank you for using Gamizign the AI ultimate gamification platform! Your current plan doesn't have the ability to use this feature upgrade to use.`);
            }else if (LicenceType === 1) {
                limiterror = true;
                setRespMessage(`Thank you for using Gamizign the AI ultimate gamification platform! Your current plan doesn't have the ability to use this feature upgrade to use.`);
            }else if (LicenceType === 2 && limit >= 100) {
                limiterror = true;
                setRespMessage(`Thank you for using Gamizign the AI ultimate gamification platform! Your current plan doesn't have the ability to add more students upgrade to add more.`);
            }else if (LicenceType === 3 && limit >= 200) {
                limiterror = true;
                setRespMessage(`Thank you for using Gamizign the AI ultimate gamification platform! Your current plan doesn't have the ability to add more students upgrade to add more.`);
            };
        }

        if (!limiterror) {
            setOpen(true);
        }else{
            setActionType('limit')
            setNetworkResp(true); 
        }
    };
    const handleClose = () => {
        setOpen(false);
        setNewItem({});
        setFirstNameError('');
        setLastNameError('');
        setEmailError('');
        setGradeError('');
        setClassError('');
        setPaswoordError('');
        setFirstName('');
        setLastName('');
        setEmail('');
        setPaswoord('');
        setGrade('');
        setClass('');
        setShowPassword(false);
    };
    const handleChange = (event) => {
        const { name, value } = event.target;
        setNewItem((prevState) => ({ ...prevState, [name]: value }));
    };
    const handleResp = (student) => {
        setStudent_id(student.student_id);
        setRespMessage(`${student.firstName}  ${student.lastName}`)
        setNetworkResp(true);
    }
    const CloseResp = () => {
        setNetworkResp(false);
    }
    const handlEdit = (student) =>{
        setActionType('edit');
         setNewItem(student);
          handleAddDialog('edit');
          setFirstName(student.firstName);
          setLastName(student.lastName);
          setGrade(student.grade);
    }
    // show hide password
    const [showPassword, setShowPassword] = useState(false);
    
    return (
        <div className='w-full'>
            <MetaTags title='Learners list' description='Lerners List--' />
            <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'>
                <h3 className="text-xl">Learner List</h3>
                <LoginHeading />
            </div>
            <div className="m-7 bg-white">
                {/* Dialog of response of adding, delete student and limit error */}
                <Dialog className="md:ms-56" open={NetworkResp} onClose={CloseResp} disabled={progress}>
                    <DialogContent>
                        {progress && (
                            <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75 z-50">
                                <CircularProgress className="text-blue-800" />
                            </div>
                        )}
                        <Alert
                            severity={
                                RespMessage === 'Created successfully' ||
                                    RespMessage === 'Deleted successfully' ||
                                    RespMessage === 'Updated successfully'
                                    ? 'success'
                                    : actionType === 'delete' ? 'warning' : 'error'
                            }>
                            {actionType === 'delete' && <>Are you sure to want delete the <b>{RespMessage}</b> from students?</>}
                            {(RespMessage === 'Created successfully' || RespMessage === 'Updated successfully' || RespMessage === 'Deleted successfully') && <>Student has been {RespMessage}.</>}
                            {(RespMessage === 'An error occurred while Deleting' || RespMessage === 'An error occurred while Creating' || RespMessage === 'An error occurred while Updating') && <>{RespMessage} Student please try again later.</>}
                            {actionType === 'limit' && RespMessage}
                        </Alert>
                    </DialogContent>
                    {actionType === 'delete' ? <div className='flex justify-center'>
                        <Button onClick={DeleteStudent} color="primary">
                            Yes
                        </Button>
                        <Button onClick={CloseResp} color="primary">
                            No
                        </Button>
                    </div> : (RespMessage === 'Created successfully' || RespMessage === 'Updated successfully' || RespMessage === 'Deleted successfully') ?
                        <div className='flex justify-center'>
                            <Button onClick={() => { handleClose(); CloseResp(); getClasses() }} color="primary">
                                Okay
                            </Button>
                        </div> : <div className='flex justify-center'>
                            <Button onClick={() => { handleClose(); CloseResp() }} color="primary">
                                Upgrade
                            </Button>
                            <Button onClick={() => { handleClose(); CloseResp() }} color="primary">
                                Okay
                            </Button>
                        </div>}
                </Dialog>
                <Dialog open={open} onClose={handleClose} disabled={progress}>
                    <DialogTitle>{actionType === 'edit' ? 'Update' : 'Add New'} Student</DialogTitle>
                    <DialogContent>
                        {progress && (
                            <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75 z-50">
                                <CircularProgress className="text-blue-800" />
                            </div>
                        )}
                        {actionType === 'add' &&
                            <DialogContentText>
                                Please enter the details for the new student.
                            </DialogContentText>
                        }

                        <>
                            {actionType === 'edit' &&
                                <Tooltip title='Student_Id' TransitionComponent={Zoom} arrow followCursor><TextField
                                    autoFocus
                                    margin="dense"
                                    label="Student Id"
                                    name="student_id"
                                    type="text"
                                    title='Student Id'
                                    fullWidth
                                    value={newItem.student_id}
                                    disabled
                                /></Tooltip>}
                            {actionType !== 'addCSV' && <Tooltip title='Student First Name' TransitionComponent={Zoom} arrow followCursor>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    label="First Name"
                                    name="firstName"
                                    type="text"
                                    autoComplete='off'
                                    fullWidth
                                    value={newItem.firstName}
                                    onChange={(e) => { handleChange(e); setFirstName(e.target.value); setFirstNameError('') }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                          '& fieldset': {
                                            border: mode === 'light' ? 'none' : '',
                                          },
                                          '&.Mui-focused fieldset': {
                                            border: '2px solid #4183d9',
                                          },
                                        },
                                      }}
                                /></Tooltip>}
                            {FirstNameError && <p className="text-red-500">{FirstNameError}</p>}
                            {actionType !== 'addCSV' && <Tooltip title='Student Last Name' TransitionComponent={Zoom} arrow followCursor>
                                <TextField
                                    margin="dense"
                                    label="Last Name"
                                    name="lastName"
                                    type="text"
                                    autoComplete='off'
                                    fullWidth
                                    value={newItem.lastName}
                                    onChange={(e) => { handleChange(e); setLastName(e.target.value); setLastNameError('') }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                          '& fieldset': {
                                            border: mode === 'light' ? 'none' : '',
                                          },
                                          '&.Mui-focused fieldset': {
                                            border: '2px solid #4183d9',
                                          },
                                        },
                                      }}
                                /></Tooltip>}
                            {LastNameError && <p className="text-red-500">{LastNameError}</p>}
                            {actionType !== 'addCSV' && <Tooltip title='Student Email' TransitionComponent={Zoom} arrow followCursor>
                                <TextField
                                    margin="dense"
                                    label="Email"
                                    name="email"
                                    type="email"
                                    autoComplete='off'
                                    fullWidth
                                    value={newItem.email}
                                    onChange={(e) => { handleChange(e); setEmail(e.target.value); setEmailError('') }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                          '& fieldset': {
                                            border: mode === 'light' ? 'none' : '',
                                          },
                                          '&.Mui-focused fieldset': {
                                            border: '2px solid #4183d9',
                                          },
                                        },
                                      }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <EmailIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                /></Tooltip>}
                            {EmailError && <p ><span className="text-red-500">{EmailError}</span>{EmailError === 'Please fill the Email in correct format like' && ' example@gamizign.com'}</p>}
                            {actionType !== 'addCSV' && <Tooltip title='Student Grade' TransitionComponent={Zoom} arrow followCursor>
                                <TextField
                                    margin="dense"
                                    label="Grade"
                                    name="grade"
                                    type="number"
                                    fullWidth
                                    value={newItem.grade}
                                    onChange={(e) => { handleChange(e); setGrade(e.target.value); setGradeError('') }}
                                    inputProps={{ min: 1 }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                          '& fieldset': {
                                            border: mode === 'light' ? 'none' : '',
                                          },
                                          '&.Mui-focused fieldset': {
                                            border: '2px solid #4183d9',
                                          },
                                        },
                                      }}
                                /></Tooltip>}
                            {GradeError && <p className="text-red-500">{GradeError}</p>}
                            <Tooltip title='Student Class' TransitionComponent={Zoom} arrow followCursor>
                                <FormControl margin="dense" fullWidth>
                                    <InputLabel>Class</InputLabel>
                                    <Select
                                        margin="dense"
                                        labelId="class_id"
                                        name="class_id"
                                        // value={newItem.class_id}
                                        value={classes && classes[0].class_id}
                                        disabled
                                        label="Class"
                                        onChange={(e) => { handleChange(e); setClass(e.target.value); setClassError('') }}
                                    >
                                        {classes?.map((classItem) => (
                                            <MenuItem key={classItem.class_id} value={classItem.class_id}>
                                                {classItem.class_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl></Tooltip>
                            {actionType === 'addCSV' && <Tooltip title='Upload CSV' TransitionComponent={Zoom} arrow followCursor>
                                <TextField
                                    margin="dense"
                                    label="Upload CSV"
                                    name="Csv_File"
                                    type="file"
                                    accept=".csv"
                                    fullWidth
                                    // value={newItem.grade}
                                    onChange={(e) => { handleChange(e); setGrade(e.target.files[0]); setGradeError(''); handleFileUpload(e) }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                          '& fieldset': {
                                            border: mode === 'light' ? 'none' : '',
                                          },
                                          '&.Mui-focused fieldset': {
                                            border: '2px solid #4183d9',
                                          },
                                        },
                                      }}
                                /></Tooltip>}
                            {ClassError && <p className="text-red-500">{ClassError}</p>}
                            {actionType !== 'addCSV' && <Tooltip title='Password' TransitionComponent={Zoom} arrow followCursor>
                                <TextField
                                    margin="dense"
                                    label="Password"
                                    name="hashed_password"
                                    type={showPassword ? 'text' : 'password'}
                                    fullWidth
                                    onChange={(e) => { handleChange(e);passwordHandler(e.target.value) }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                          '& fieldset': {
                                            border: mode === 'light' ? 'none' : '',
                                          },
                                          '&.Mui-focused fieldset': {
                                            border: '2px solid #4183d9',
                                          },
                                        },
                                      }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <KeyIcon />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment sx={{cursor:'pointer'}} position="end">
                                               <Tooltip title={showPassword ? 'Hide Password' : 'Show Password'} TransitionComponent={Zoom} arrow followCursor> 
                                               <span onClick={()=>setShowPassword(prev => !prev)} className="cursor-pointer">{showPassword ? <FaEye size={24}/> : <FaEyeSlash size={24}/>}</span>
                                               </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }}

                                /></Tooltip>}
                            {PaswoordError && <p className={`${PaswoordError === 'Perfect ✔' ? 'text-green-500' : 'text-red-500' }`}>{PaswoordError}</p>}

                        </>
                    </DialogContent>
                    <DialogActions>
                        {actionType === 'addCSV' && <Tooltip title='View CSV sample file' TransitionComponent={Zoom} arrow followCursor>
                            <Link to={`${process.env.REACT_APP_ENDPOINT}/uploads/Student_import_sample.csv`}>
                                <Button onClick={handleClose} color="primary">
                                    View Sample
                                </Button>
                            </Link></Tooltip>}
                        <Tooltip title='Cancel Adding' TransitionComponent={Zoom} arrow followCursor>
                            <Button onClick={handleClose} color="primary">
                                Cancel
                            </Button></Tooltip>
                        {actionType === 'edit' ?
                            <Tooltip title='Update Student record' TransitionComponent={Zoom} arrow followCursor>
                                <Button onClick={()=>{AddStudentValidation('update')}} color="primary">
                                    Update
                                </Button></Tooltip> : actionType === 'add' ?
                                <Tooltip title='Add Student in Class' TransitionComponent={Zoom} arrow followCursor>
                                    <Button onClick={()=>{AddStudentValidation('add')}} color="primary">
                                        Add
                                    </Button></Tooltip> :
                                <Tooltip title='Add Studen in class' TransitionComponent={Zoom} arrow followCursor>
                                    <Button onClick={handleUploadCsv} color="primary">
                                        Upload
                                    </Button></Tooltip>
                        }
                    </DialogActions>
                </Dialog>
                {loading ? <LoadingComponent />
                    : <div className="p-5">
                        {classes.map((Class, ClassIndex) => (
                            <div key={ClassIndex}>
                                <div className="sm:mx-20 mx-3 my-5 flex">
                                    <Accordion className="grow" defaultExpanded>
                                        <div
                                        //   style={{ backgroundColor:'#DFBFDD' }}
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel3-content"
                                                id="panel3-header"
                                                sx={{ justifyItems: 'center', display: 'flex' }}
                                            >
                                                <Typography sx={{ color: 'text.secondary', width: '20%' }}>{Class.class_name}</Typography>
                                            </AccordionSummary>
                                        </div>
                                        {/* Students Of Class */}
                                        <div>
                                            <AccordionDetails>
                                                <p className={`${Class.students.length === 0 ? "text-xl flex justify-center text-red-500 font-bold" : "hidden"}`}>NO any student added in this Class.</p>
                                                {Class.students && Array.isArray(Class.students) ? (<div>
                                                    {Class.students.map((student, studentIndex) => (
                                                        <div className=" -mt-4 py-2 w-full sm:ms-9 flex" key={studentIndex}>
                                                            <Accordion className="grow">
                                                                <div className="mb-0.5"
                                                                //   style={{ backgroundColor: StudentsColors[studentIndex % StudentsColors.length] }}
                                                                >

                                                                    <AccordionSummary
                                                                        expandIcon={<ExpandMoreIcon />}
                                                                        aria-controls="panel1-content"
                                                                        id="panel1-header"
                                                                    >
                                                                        <div className="flex relative w-full gap-5">
                                                                            <p>{studentIndex + 1}. {student.firstName} {student.lastName}</p>
                                                                        </div>
                                                                    </AccordionSummary>
                                                                </div>
                                                                <AccordionDetails>
                                                                    <div>

                                                                        <div>
                                                                            <TextField
                                                                                margin="dense"
                                                                                label="Student_id"
                                                                                name="Student_id"
                                                                                type="text"
                                                                                autoComplete='off'
                                                                                fullWidth
                                                                                value={student.student_id}
                                                                                disabled
                                                                                sx={{
                                                                                    '& .MuiOutlinedInput-root': {
                                                                                        '& fieldset': {
                                                                                            border: '2px solid #F3F6F6',
                                                                                        },
                                                                                        '&.Mui-focused fieldset': {
                                                                                            border: '2px solid #DBE6F5',
                                                                                        },
                                                                                        '&:hover fieldset': {
                                                                                            border: '2px solid #DBE6F5',
                                                                                        },
                                                                                    },
                                                                                }}
                                                                            />
                                                                            <TextField
                                                                                margin="dense"
                                                                                label="First Name"
                                                                                name="First Name"
                                                                                type="text"
                                                                                autoComplete='off'
                                                                                fullWidth
                                                                                value={student.firstName}
                                                                                disabled
                                                                                sx={{
                                                                                    '& .MuiOutlinedInput-root': {
                                                                                        '& fieldset': {
                                                                                            border: '2px solid #F3F6F6',
                                                                                        },
                                                                                        '&.Mui-focused fieldset': {
                                                                                            border: '2px solid #DBE6F5',
                                                                                        },
                                                                                        '&:hover fieldset': {
                                                                                            border: '2px solid #DBE6F5',
                                                                                        },
                                                                                    },
                                                                                }}
                                                                            />
                                                                            <TextField
                                                                                margin="dense"
                                                                                label="Last Name"
                                                                                name="Last Name"
                                                                                type="text"
                                                                                autoComplete='off'
                                                                                fullWidth
                                                                                value={student.lastName}
                                                                                disabled
                                                                                sx={{
                                                                                    '& .MuiOutlinedInput-root': {
                                                                                        '& fieldset': {
                                                                                            border: '2px solid #F3F6F6',
                                                                                        },
                                                                                        '&.Mui-focused fieldset': {
                                                                                            border: '2px solid #DBE6F5',
                                                                                        },
                                                                                        '&:hover fieldset': {
                                                                                            border: '2px solid #DBE6F5',
                                                                                        },
                                                                                    },
                                                                                }}
                                                                            />
                                                                            <TextField
                                                                                margin="dense"
                                                                                label="Email"
                                                                                name="Email"
                                                                                type="email"
                                                                                autoComplete='off'
                                                                                fullWidth
                                                                                value={student.email}
                                                                                disabled
                                                                                sx={{
                                                                                    '& .MuiOutlinedInput-root': {
                                                                                        '& fieldset': {
                                                                                            border: '2px solid #F3F6F6',
                                                                                        },
                                                                                        '&.Mui-focused fieldset': {
                                                                                            border: '2px solid #DBE6F5',
                                                                                        },
                                                                                        '&:hover fieldset': {
                                                                                            border: '2px solid #DBE6F5',
                                                                                        },
                                                                                    },
                                                                                }}
                                                                            />
                                                                            <TextField
                                                                                margin="dense"
                                                                                label="Grade"
                                                                                name="Grade"
                                                                                type="number"
                                                                                autoComplete='off'
                                                                                fullWidth
                                                                                value={student.grade}
                                                                                disabled
                                                                                sx={{
                                                                                    '& .MuiOutlinedInput-root': {
                                                                                        '& fieldset': {
                                                                                            border: '2px solid #F3F6F6',
                                                                                        },
                                                                                        '&.Mui-focused fieldset': {
                                                                                            border: '2px solid #DBE6F5',
                                                                                        },
                                                                                        '&:hover fieldset': {
                                                                                            border: '2px solid #DBE6F5',
                                                                                        },
                                                                                    },
                                                                                }}
                                                                            />
                                                                            <FormControl fullWidth
                                                                                sx={{
                                                                                    '& .MuiOutlinedInput-root': {
                                                                                        '& fieldset': {
                                                                                            border: '2px solid #F3F6F6',
                                                                                        },
                                                                                        '&.Mui-focused fieldset': {
                                                                                            border: '2px solid #DBE6F5',
                                                                                        },
                                                                                        '&:hover fieldset': {
                                                                                            border: '2px solid #DBE6F5',
                                                                                        },
                                                                                    },
                                                                                }}>
                                                                                <InputLabel>Student Class</InputLabel>
                                                                                <Select
                                                                                    labelId="Student Class"
                                                                                    name="Student Class"
                                                                                    label="Student Class"
                                                                                    disabled
                                                                                    value={student.class_id}
                                                                                >
                                                                                    {classes.map((item, index) => (
                                                                                        <MenuItem key={index} value={item.class_id}>
                                                                                            {item.class_name}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </Select>
                                                                            </FormControl>
                                                                            <TextField
                                                                                margin="dense"
                                                                                label="Password"
                                                                                name="Password"
                                                                                type="text"
                                                                                autoComplete='off'
                                                                                fullWidth
                                                                                value={student.password}
                                                                                disabled
                                                                                sx={{
                                                                                    '& .MuiOutlinedInput-root': {
                                                                                        '& fieldset': {
                                                                                            border: '2px solid #F3F6F6',
                                                                                        },
                                                                                        '&.Mui-focused fieldset': {
                                                                                            border: '2px solid #DBE6F5',
                                                                                        },
                                                                                        '&:hover fieldset': {
                                                                                            border: '2px solid #DBE6F5',
                                                                                        },
                                                                                    },
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                            <Tooltip TransitionComponent={Zoom} arrow followCursor title='Edit Student'><img onClick={() => {handlEdit(student)}} className="w-6 h-6 flex-none ms-3 sm:me-2 mt-5 cursor-pointer" src={EditIcon} alt="Deletestudent" /></Tooltip>
                                                            <Tooltip TransitionComponent={Zoom} arrow followCursor title='Delete Student'><img onClick={() => { handleResp(student); setActionType('delete') }} className="w-6 h-6 flex-none ms-3 sm:me-5 mt-5 cursor-pointer" src={DeleteIcon} alt="Deletestudent" /></Tooltip>

                                                        </div>
                                                    ))}
                                                </div>) : (<div>NO Class added so far.</div>)}


                                            </AccordionDetails>
                                        </div>
                                        <div className="sm:flex justify-end m-5">
                                        <Tooltip title='Add a new student' TransitionComponent={Zoom} arrow followCursor>
                                                <Button id='AddNewCsv' variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => { setActionType('add'); handleAddDialog('add') }} sx={{ marginTop: 2, marginLeft: 2 }}>
                                                    Add student</Button>
                                            </Tooltip>
                                            <Tooltip title='Add new students (in bulk qty) by uploading CSV file' TransitionComponent={Zoom} arrow followCursor>
                                                <Button id='AddNewCsv' variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => { setActionType('addCSV'); handleAddDialog('addCSV') }} sx={{ marginTop: 2, marginLeft: 2 }}>
                                                    Add student by CSV</Button>
                                            </Tooltip>
                                        </div>
                                    </Accordion>
                                    {/* <Tooltip title='Delete Class'><img className="w-6 h-6 flrx-none ms-3 mt-7 cursor-pointer" src={DeleteIcon} alt="DeleteStage"/></Tooltip> */}
                                </div>

                            </div>
                        ))}
                    </div>}
            </div>
        </div>
    )
};

export default LearnerList;