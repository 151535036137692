import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, TextField, Tooltip, Grid, Box } from '@mui/material';
import katex from 'katex';
import 'katex/dist/katex.min.css'; // Import KaTeX styles

const Equation = ({ Equation, EquationHover, saveHandle, questionIndex, text, SetEquationValue, type }) => {
  const [equationValue, setEquationValue] = useState('');
  const [renderedHtml, setRenderedHtml] = useState('');

  // List of symbols to directly insert into the equation field with tooltips
  const symbols = [
    { label: '+', symbol: '+', tooltip: 'Plus' },
    { label: '–', symbol: '–', tooltip: 'Minus' },
    { label: '×', symbol: '×', tooltip: 'Multiplication' },
    { label: '÷', symbol: '÷', tooltip: 'Division' },
    { label: '/', symbol: '/', tooltip: 'Slash' },
    { label: '=', symbol: '=', tooltip: 'Equals' },
    { label: '<', symbol: '<', tooltip: 'Less than' },
    { label: '>', symbol: '>', tooltip: 'Greater than' },
    { label: '.', symbol: '.', tooltip: 'Dot' },
    { label: '⅟', symbol: '⅟', tooltip: 'Fraction' },
    { label: '/', symbol: '/', tooltip: 'Fraction Slash' },
    { label: 'mod', symbol: 'mod', tooltip: 'Modulo' },
    { label: '√', symbol: '√', tooltip: 'Square root' },
    { label: '∛', symbol: '∛', tooltip: 'Cube root' },
    { label: 'n√', symbol: 'n√', tooltip: 'Nth root' },
    { label: '%', symbol: '%', tooltip: 'Percent' },
    { label: '≠', symbol: '≠', tooltip: 'Not equal to' },
    { label: '≤', symbol: '≤', tooltip: 'Less than or equal to' },
    { label: '≥', symbol: '≥', tooltip: 'Greater than or equal to' },
    { label: 'x²', symbol: '²', tooltip: 'Square' },
    { label: 'x³', symbol: '³', tooltip: 'Cube' },
    { label: '(', symbol: '(', tooltip: 'Left parenthesis' },
    { label: ')', symbol: ')', tooltip: 'Right parenthesis' },
    { label: 'f(x)', symbol: 'f(x)', tooltip: 'Function of x' },
    { label: '⇒', symbol: '⇒', tooltip: 'Implies' },
    { label: 'π', symbol: 'π', tooltip: 'Pi' },
    { label: '{', symbol: '{', tooltip: 'Left curly bracket' },
    { label: '}', symbol: '}', tooltip: 'Right curly bracket' },
    { label: '∈', symbol: '∈', tooltip: 'Element of' },
    { label: '∉', symbol: '∉', tooltip: 'Not an element of' },
    { label: '⊆', symbol: '⊆', tooltip: 'Subset' },
    { label: '⊂', symbol: '⊂', tooltip: 'Proper subset' },
    { label: '⊄', symbol: '⊄', tooltip: 'Not a subset' },
    { label: '⊇', symbol: '⊇', tooltip: 'Superset' },
    { label: 'Ø', symbol: 'Ø', tooltip: 'Empty set' },
    { label: 'U', symbol: 'U', tooltip: 'Union' },
    { label: '∩', symbol: '∩', tooltip: 'Intersection' },
    { label: '∪', symbol: '∪', tooltip: 'Union' },
    { label: 'ε', symbol: 'ε', tooltip: 'Epsilon' },
    { label: 'e', symbol: 'e', tooltip: 'Euler\'s number' },
    { label: 'lim', symbol: 'lim', tooltip: 'Limit' },
    { label: 'y‘', symbol: 'y‘', tooltip: 'First derivative' },
    { label: 'y(n)', symbol: 'y(n)', tooltip: 'Nth derivative' },
    { label: '∫', symbol: '∫', tooltip: 'Integral' },
    { label: '∞', symbol: '∞', tooltip: 'Infinity' },
    { label: 'n!', symbol: 'n!', tooltip: 'Factorial' },
    { label: 'nPk', symbol: 'nPk', tooltip: 'Permutation' },
    { label: '∑', symbol: '∑', tooltip: 'Summation' },
    { label: '∏', symbol: '∏', tooltip: 'Product' },
    { label: 'sin', symbol: 'sin', tooltip: 'Sine' },
    { label: 'cos', symbol: 'cos', tooltip: 'Cosine' },
    { label: 'tan', symbol: 'tan', tooltip: 'Tangent' },
    { label: '1⁄2', symbol: '⁄', tooltip: 'Fraction' },

    // Add number buttons
    ...Array.from({ length: 10 }, (_, i) => ({ label: `${i}`, symbol: `${i}`, tooltip: `Number ${i}` })),
  ];

  // Add symbol to equation (directly add the character)
  const addSymbol = (symbol) => {
    const newEquation = `${equationValue}${symbol}`;
    setEquationValue(newEquation);
    renderLatexToHtml(newEquation); // This is still useful if you want to show a rendered preview
  };

  // Render LaTeX to HTML using KaTeX for the preview
  const renderLatexToHtml = (latexString) => {
    try {
      const htmlString = katex.renderToString(latexString, {
        throwOnError: false,
      });
      setRenderedHtml(htmlString);
    } catch (error) {
      console.error('LaTeX rendering error:', error);
    }
  };

  // Handle Save
  const handleSave = () => {
    SetEquationValue(equationValue, type);
    saveHandle();
  };

  // Clear equation
  const clear = () => {
    setEquationValue('');
    setRenderedHtml('');
  };

  // Initialize the dialog with the previously saved input
  useEffect(() => {
    if (Equation) {
      setEquationValue(Equation);
      renderLatexToHtml(Equation);
    }
  }, [Equation, EquationHover]);

  return (
    <div className="App">
      <Dialog open={EquationHover} onClose={saveHandle} maxWidth="md">
        <DialogTitle>{text} {questionIndex + 1}</DialogTitle>
        <Box p={3} minWidth="500px">
          {/* Display the equation in a text field */}
          <TextField
            fullWidth
            variant="outlined"
            autoComplete='off'
            value={equationValue}
            onChange={(e) => {
              setEquationValue(e.target.value);
              renderLatexToHtml(e.target.value);
            }}
            placeholder="Enter equation"
            multiline
            rows={2}
            sx={{ mb: 2 }}
          />

          {/* Buttons to add symbols with tooltips */}
          <Grid container spacing={1} justifyContent="center">
            {symbols.map((symbol, index) => (
              <Grid item key={index}>
                <Tooltip title={symbol.tooltip} arrow>
                  <Button 
                    variant="outlined" 
                    onClick={() => addSymbol(symbol.symbol)}
                    sx={{ minWidth: '40px', margin: '2px' }}
                  >
                    {symbol.label}
                  </Button>
                </Tooltip>
              </Grid>
            ))}
          </Grid>


          {/* Control buttons */}
          <div className="flex justify-center" style={{ textAlign: 'center', marginTop: '20px' }}>
            <Button variant="contained" color="secondary" onClick={clear} sx={{ marginRight: '10px' }}>Clear</Button>
            <Button variant="contained" color="primary" onClick={handleSave}>Save</Button>
          </div>
        </Box>
      </Dialog>
    </div>
  );
};

export default Equation;
